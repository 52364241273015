import moment from "moment";
import { useContext } from "react";
import { Context } from "../Context";

export const getNextMonth = () => {
  const date = new Date();
  const currentMonth = date.getMonth();
  if (currentMonth === 11) {
    return "January";
  } else if (currentMonth === 0) {
    return "February";
  } else if (currentMonth === 1) {
    return "March";
  } else if (currentMonth === 2) {
    return "April";
  } else if (currentMonth === 3) {
    return "May";
  } else if (currentMonth === 4) {
    return "June";
  } else if (currentMonth === 5) {
    return "July";
  } else if (currentMonth === 6) {
    return "August";
  } else if (currentMonth === 7) {
    return "September";
  } else if (currentMonth === 8) {
    return "October";
  } else if (currentMonth === 9) {
    return "November";
  } else {
    return "December";
  }
};

export const getLastMonth = () => {
  const date = new Date();
  const currentMonth = date.getMonth();
  if (currentMonth === 11) {
    return "November";
  } else if (currentMonth === 0) {
    return "December";
  } else if (currentMonth === 1) {
    return "January";
  } else if (currentMonth === 2) {
    return "February";
  } else if (currentMonth === 3) {
    return "March";
  } else if (currentMonth === 4) {
    return "April";
  } else if (currentMonth === 5) {
    return "May";
  } else if (currentMonth === 6) {
    return "June";
  } else if (currentMonth === 7) {
    return "July";
  } else if (currentMonth === 8) {
    return "August";
  } else if (currentMonth === 9) {
    return "September";
  } else {
    return "October";
  }
};

export const getCorrectYear = () => {
  const date = new Date();
  const currentMonth = date.getMonth();
  if (currentMonth !== 0) {
    return new Date().getFullYear();
  } else {
    return new Date().getFullYear() - 1;
  }
};

export const getCurrentWeek = () => {
  const week = moment(Date.now()).format("W");
  return week;
};

export const useWeek = () => {
  const { state } = useContext(Context);
  const { jackpotPoolInfo } = state;
  const poolWeek = moment(new Date(jackpotPoolInfo?.pool_start_date)).format(
    "W"
  );
  const prizeWeek = moment(Date.now()).format("W");
  return { poolWeek, prizeWeek };
};
