import NavLayout from "../NavLayout";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useOrder } from "../../helpers/Hooks/Api";
import { useContext } from "react";
import { Context } from "../../helpers/Context";
import moment from "moment";
import { Oval } from "react-loader-spinner";
import Moment from "react-moment";

const TrackReward = () => {
  const navigate = useNavigate();
  const { orderLoading } = useOrder();
  const { state } = useContext(Context);
  const { order } = state;

  const TrackCard = ({ img, title, time, isLast }) => {
    return (
      <div className="flex gap-x-[19px] ">
        <div
          className={`${
            isLast === false &&
            "after:content-trackLine after:flex after:justify-center after:pt-[5px]"
          } `}
        >
          <img src={img} className="w-[29px] h-[29px] " />
        </div>
        <div>
          <p className="text-brandPurp text-[0.625rem] font-AR font-[700]">
            {title}
          </p>
          <p className="text-brandPurp text-[0.5625rem] font-AR mt-[9px]">
            {time}
          </p>
        </div>
      </div>
    );
  };

  return (
    <NavLayout
      title="Rewards-Menu"
      content={
        <div className=" max-w-[428px] md:max-w-[500px] m-[0_auto] ">
          <div className="flex w-full pb-[20px] border-b-[rgba(255,255,255,0.50)] border-b-[0.2px] relative justify-center items-center pt-[20px] ">
            <ArrowBackIos
              sx={{ fontSize: "16px" }}
              className="cursor-pointer absolute left-[18px] text-white"
              onClick={() => {
                navigate(-1);
              }}
            />

            <span className="text-center text-[1rem] text-[rgba(255,255,255,0.87)] font-AR">
              Track Reward
            </span>
          </div>

          <div className=" bg-white max-w-[392px] min-h-[90vh] md:max-w-[500px] p-[29px_16px] m-[0_auto] mt-[13px] rounded-[10px_10px_0px_0px] border-[0.5px] border-[#FF1CF7] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] pb-[5rem]">
            <h2 className="text-center text-[0.875rem] text-brandPurp font-AR">
              Track Reward
            </h2>

            <div className="w-[139px] h-[126px] bg-brandPurp flex justify-center items-center m-[0_auto] mt-[24px] rounded-[10px]">
              <img src="/assets/images/trackTimer.png" alt="timer" />
            </div>
            {orderLoading ? (
              <div className="mt-[50px] w-full flex items-center justify-center">
                <Oval
                  height={80}
                  width={80}
                  color="#290349"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#290349"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              <>
                <div className=" mt-[19px] text-brandPurp text-[0.75rem] text-center font-AR font-[700]">
                  Estimated Delivery Time:{" "}
                  {
                    <Moment fromNow>
                      {order?.data?.estimated_delivery_date}
                    </Moment>
                  }
                </div>
                <div className=" mt-[5px] text-brandPurp text-[0.75rem] text-center font-AR font-[700]">
                  Rewards:{" "}
                  {order?.purchased_items
                    ?.map((item) => item?.items?.item?.item_brand_name)
                    .join(", ")}
                </div>

                <div className=" w-full px-[17px] mt-[24px]">
                  <div className=" border-[1px] border-[#FF1CF7] rounded-[10px] w-full ">
                    <div className=" py-[23px] border-b-[0.8px] border-b-[#FF1CF7] text-center text-brandPurp text-[0.8125rem] font-AR font-[700]">
                      Reward number: {order?.data?.reward_number}
                    </div>

                    {order?.status === "processing" && (
                      <div className="w-full py-[30px] px-[22px]">
                        <TrackCard
                          img="/assets/images/confirmed.png"
                          title=" OneCap is processing your rewards"
                          time={moment(order?.data?.processing_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={true}
                        />
                      </div>
                    )}

                    {order?.status === "confirmed" && (
                      <div className="w-full py-[30px] px-[22px]">
                        <TrackCard
                          img="/assets/images/confirmed.png"
                          title=" OneCap is processing your rewards"
                          time={moment(order?.data?.processing_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={false}
                        />
                        <TrackCard
                          img="/assets/images/trackCheck.png"
                          title=" Your reward has been confirmed"
                          time={moment(order?.data?.confirmed_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={true}
                        />
                      </div>
                    )}

                    {order?.status === "prepared" && (
                      <div className="w-full py-[30px] px-[22px]">
                        <TrackCard
                          img="/assets/images/confirmed.png"
                          title=" OneCap is processing your rewards"
                          time={moment(order?.data?.processing_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={false}
                        />
                        <TrackCard
                          img="/assets/images/trackCheck.png"
                          title=" Your reward has been confirmed"
                          time={moment(order?.data?.confirmed_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={false}
                        />

                        <TrackCard
                          img="/assets/images/prepared.png"
                          title=" OneCap is preparing your rewards"
                          time={moment(order?.data?.preparing_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={true}
                        />
                      </div>
                    )}

                    {order?.data?.status === "shipped" && (
                      <div className="w-full py-[30px] px-[22px]">
                        <TrackCard
                          img="/assets/images/confirmed.png"
                          title=" OneCap is processing your rewards"
                          time={moment(order?.data?.processing_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={false}
                        />
                        <TrackCard
                          img="/assets/images/trackCheck.png"
                          title=" Your reward has been confirmed"
                          time={moment(order?.data?.confirmed_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={false}
                        />

                        <TrackCard
                          img="/assets/images/prepared.png"
                          title=" OneCap is preparing your rewards"
                          time={moment(order?.data?.preparing_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={false}
                        />
                        <TrackCard
                          img="/assets/images/onTheWay.png"
                          title="Your reward has been dispatched to your address"
                          time={moment(order?.data?.shipping_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={true}
                        />
                      </div>
                    )}

                    {order?.status === "delivered" && (
                      <div className="w-full py-[30px] px-[22px]">
                        <TrackCard
                          img="/assets/images/confirmed.png"
                          title=" OneCap is processing your rewards"
                          time={moment(order?.data?.processing_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={false}
                        />
                        <TrackCard
                          img="/assets/images/trackCheck.png"
                          title=" Your reward has been confirmed"
                          time={moment(order?.data?.confirmed_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={false}
                        />

                        <TrackCard
                          img="/assets/images/prepared.png"
                          title=" OneCap is preparing your rewards"
                          time={moment(order?.data?.preparing_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={false}
                        />
                        <TrackCard
                          img="/assets/images/onTheWay.png"
                          title="Your reward has been dispatched to your address"
                          time={moment(order?.data?.shipping_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={false}
                        />
                        <TrackCard
                          img="/assets/images/trackCheck.png"
                          title="Your reward has been delivered"
                          time={moment(order?.data?.delivered_time).format(
                            "hh:mm a - MMM DD, YYYY"
                          )}
                          isLast={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      }
    />
  );
};

export default TrackReward;
