import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// import { getFirestore } from "@firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "../helpers/axios";
const firebaseConfig = {
  apiKey: "AIzaSyBXH2-rKOjPyHagYHpXLxlBl9M31KeocSA",
  authDomain: "civil-lambda-387612.firebaseapp.com",
  projectId: "civil-lambda-387612",
  storageBucket: "civil-lambda-387612.appspot.com",
  messagingSenderId: "940199083503",
  appId: "1:940199083503:web:87610ba6f6d16605f48a1a",
  measurementId: "G-3MM9HPG1QS",
};
const token = localStorage.getItem("token");

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

initializeApp(firebaseConfig);
// const analytics = getAnalytics(initializeApp(firebaseConfig));
const messaging = getMessaging();
// export const getOrRegisterServiceWorker = () => {
//   if ("serviceWorker" in navigator) {
//     return window.navigator.serviceWorker
//       .getRegistration("/firebase-push-notification-scope")
//       .then((serviceWorker) => {
//         if (serviceWorker) return serviceWorker;
//         return window.navigator.serviceWorker.register(
//           "/firebase-messaging-sw.js",
//           {
//             scope: "/firebase-push-notification-scope",
//           }
//         );
//       });
//   }
//   throw new Error("The browser doesn`t support service worker.");
// };

export const requestForToken = () => {
  let data = { device_id: "web" };
  return getToken(messaging, {
    vapidKey:
      "BMc_-yG27RD34s75KKUktzsGs42o2YhCIlcaXBsGLJCL5WAgFM_Yq2mP8bkb5rsZ9LjnDOrm-cDy9r5XrShguGw",
  })
    .then(async (currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        data.registration_id = currentToken;
        await axios
          .post("/devices", data, config)
          .then(function (response) {
            localStorage.setItem("pushAllowed", true);
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);

            // else (error?.response?.data?.details) {
            //   setServerError("*Invalid account details");
            // }

            // navigate("/signin");
          });
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
// export const getFirebaseToken = () =>
//   getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
//     getToken(messaging, {
//       vapidKey:
//         "BMc_-yG27RD34s75KKUktzsGs42o2YhCIlcaXBsGLJCL5WAgFM_Yq2mP8bkb5rsZ9LjnDOrm-cDy9r5XrShguGw",
//       serviceWorkerRegistration,
//     })
//   );
