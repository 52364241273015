import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import NavLayout from "../NavLayout";
import { useUserInfo } from "../../helpers/Hooks/Api";

const QuizResult = () => {
  useUserInfo();
  const navigate = useNavigate();

  const quizDone = () => {
    localStorage.removeItem("quizResult");
    navigate("/dashboard");
  };

  const quizResult = JSON.parse(localStorage.getItem("quizResult"));

  return (
    <NavLayout
      title="Quiz"
      content={
        <section className=" max-w-[500px] sm:m-[0_auto] pb-[5rem]">
          <div className="p-[24px_20px] border-b-[0.1px_solid_#ffffff80]">
            <div className=" w-[52%] flex items-center justify-between text-[rgba(255,255,255,0.87)] text-[1rem] font-AR">
              <ArrowBackIos
                sx={{ fontSize: "16px", cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              />
              Test Results
            </div>
          </div>

          <div className=" rounded-[10px] p-[21px_19px] w-full bg-white border-[0.5px] border-[#ff1cf7] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] flex flex-col items-center min-h-[692px]">
            <img
              src="/assets/images/congratsGroup.png"
              alt=""
              className="w-[160px] h-[160px] mt-[20px]"
            />
            <div className="flex items-center  gap-x-[11px] mt-[5.51px] text-black text-[1rem] font-AR font-[700]">
              <span>Congratulations</span>{" "}
              <img src="/assets/images/congrats.png" alt="" />
            </div>
            <div className="flex flex-col items-center">
              <div className="flex items-center gap-x-[8px]">
                <span className=" text-[1.625rem] text-center font-AR font-[700]">
                  {quizResult?.number_question_passed === undefined
                    ? "0"
                    : quizResult?.number_question_passed}
                </span>{" "}
                <span className=" text-[0.875rem] text-center font-AR font-[700]">
                  points
                </span>
              </div>
              <p className=" text-[0.875rem] text-center font-AR">Marks</p>
            </div>
            <div className=" text-[0.875rem] text-center font-AR mt-[29px]">
              Your time : {quizResult?.time_used?.split(":")[1]}m:{" "}
              {quizResult?.time_used?.split(":")[2]?.substring(0, 2)}sec
            </div>
            <div className=" text-[1rem] text-center font-AR mt-[25px] font-[700]">
              You completed{" "}
              {quizResult?.number_question_passed === undefined
                ? "0"
                : quizResult?.number_question_passed}{" "}
              questions correctly
            </div>

            <div className="flex flex-col gap-y-[29px] mt-[49px]">
              <button
                className="w-[17.875rem] p-[11px_0] rounded-[10px] bg-brandPurp text-white text-[0.875rem] font-[700] font-AR"
                onClick={quizDone}
              >
                Done
              </button>
              <button
                className="w-[17.875rem] p-[11px_0] rounded-[10px] bg-white border-[1px] border-brandPurp text-brandPurp text-[0.875rem] font-[700] font-AR"
                onClick={() => navigate("/quiz-board")}
              >
                View board
              </button>
            </div>
          </div>
        </section>
      }
    />
  );
};

export default QuizResult;
