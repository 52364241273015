import React, { useState } from "react";
import { Box, Typography, Button, Snackbar } from "@mui/material";
import axios from "../helpers/axios";
// import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./otp.css";
import { Link } from "react-router-dom";

function Otp() {
  const navigate = useNavigate();
  const [serverMsg, setServerMsg] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const ref = localStorage.getItem("ref");
  // const { register, handleSubmit } = useForm();
  const closeSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    console.log(otp);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const onSubmit = async (data) => {
    data = {
      otp: otp.join(""),
    };
    console.log(data);
    await axios
      .post(`/otp/verify/${ref}`, data)
      .then(function (response) {
        console.log(response);
        setServerMsg("Account has been verified. ... Redirecting to login");
        setTimeout(() => navigate("/signin"), "5000");
      })
      .catch(function (error) {
        console.log(error);
        setServerMsg("Wrong OTP Code");
      });
  };
  const resend = async () => {
    await axios
      .get(`/otp/resend/${ref}`)
      .then(function (response) {
        console.log(response);
        setSnackMessage("OTP sent, please check your email");
        setSnackOpen(true);
      })
      .catch(function (error) {
        console.log(error);
        setServerMsg("An error occured, please try again");
      });
  };
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #290349 0%, #592E6D 100%)",
        color: "#fff",
        margin: "0px",
        boxSizing: "border-box",
        height: "100vh",
        paddingTop: "1px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={closeSnack}
        message={snackMessage}
      />
      <Box
        sx={{
          maxWidth: "600px",
          margin: "2em auto",
          background: "transparent",
          textAlign: "center",
        }}
      >
        <img
          src={"/assets/images/Vectorverify.png"}
          style={{ width: "70px" }}
          alt="email verification"
        />
        <Typography variant="h6" mt="20px">
          Email verification
        </Typography>

        <Typography
          mt="40px"
          sx={{
            fontSize: "12px",
            width: "300px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          Enter the 4-digit One-Time Password (OTP) sent to your email address
        </Typography>
        <form>
          <Box mt="60px">
            <div className="otpInput">
              {otp.map((data, index) => {
                return (
                  <input
                    className="otpField"
                    name="otp"
                    placeholder="_"
                    maxLength={1}
                    key={index}
                    value={data}
                    pattern="[0-9]"
                    required
                    onChange={(e) => handleChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
            </div>
          </Box>
          <Box
            mt="40px"
            sx={{ display: "flex", gap: "6px", justifyContent: "center" }}
          >
            <Typography color={"#fff"} sx={{ fontSize: "12px" }}>
              Didn't get the code?
            </Typography>
            <Link to="" style={{ color: "#1976d2" }} onClick={() => resend()}>
              Resend code
            </Link>
          </Box>
          <Box mt={"40px"} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" mb={"10px"}>
              {serverMsg}
            </Typography>

            <Button
              onClick={() => {
                onSubmit();
              }}
              sx={{
                background: "#592E6D",
                ":hover": {
                  bgcolor: "#a852dd",
                },
                color: "#fff",
                height: "45px",
                textTransform: "none",
              }}
            >
              <Typography variant="body2">Continue</Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default Otp;
