import { googleLogout } from "@react-oauth/google";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context";

export const useLogout = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("current_user_id");
    dispatch({ type: "REFRESH_STATE" });
    googleLogout();
    navigate("/signin");
  };
  return { logout };
};
