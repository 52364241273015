import React from "react";

const MoreFun = () => {
  return (
    <div className=" w-full pt-[50px] pb-[100px] bg-bg6 bg-brandPurp bg-no-repeat bg-cover bg-center px-[7%] md:px-[13.8%] flex md:block flex-col items-center ">
      <div className=" text-[2.25rem] md:text-[2.5rem]  text-white text-center md:text-left font-SF font-[400] mb-[36px] md:mb-[99px] max-w-[85%] md:max-w-[100%]">
        There’s more wholesome fun for your delight...
      </div>

      <div className="flex gap-x-[50px] flex-col xl:flex-row">
        <div className="w-full xl:w-[40%] text-white border-r-[rgba(255,255,255,0.50)] border-r-none xl:border-r-[1px] flex flex-col items-center pr-0 md:pr-[2rem] ">
          <p className=" text-[2rem] font-SF font-[300] text-center">
            Refer your friends and win prizes
          </p>
          <img src="/assets/images/friend.svg" alt="" className=" my-[48px]" />
        </div>

        <div className="w-full xl:w-[60%] mt-[96px] md:mt-0 text-white">
          <div className=" text-[2rem] text-center md:text-left font-SF font-[300]">
            Collect tokens as you save, you’re off to the top the leaderboard
          </div>
          <div className="flex flex-col-reverse xl:flex-row items-center my-[30px] gap-y-[12px] md:gap-y-0">
            <img
              src="/assets/images/junior.svg"
              alt=""
              className="h-[12.375rem] w-[7.5rem] mt-[-12px] md:mt-0"
            />
            <img
              src="/assets/images/steps.svg"
              alt=""
              className="w-[25.3125rem] hidden xl:block"
            />
            <img
              src="/assets/images/stepsV.svg"
              alt=""
              className=" xl:hidden"
            />
            <img
              src="/assets/images/marshal.svg"
              alt=""
              className="h-[12.375rem] w-[7.5rem]"
            />
          </div>

          <div className="flex items-center justify-center xl:justify-start text-[1.125rem] md:text-[0.8rem] text-center xl:text-left font-SF font-[300]">
            Note: You earn 2 OneCap tokens for every N1,000 saved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreFun;
