import { useNavigate } from "react-router-dom";

const Gamified = ({ gamifiedRef }) => {
  const navigate = useNavigate();
  return (
    <div
      id="gamfi"
      className="w-full pt-[50px] pb-[100px] bg-bg3 px-[7%] md:px-[13.8%] bg-no-repeat bg-cover bg-center"
      ref={gamifiedRef}
    >
      <p className="text-[2.25rem] text-black font-SF font-[300] text-center hidden md:block">
        Save money, play quiz, win prizes
      </p>
      <p className="text-[2.25rem] text-black font-SF font-[300] text-center md:hidden">
        Save money, <br /> play quiz, <br /> win prizes
      </p>

      <div className="flex flex-col lg:flex-row items-center pt-[36px] md:pt-[128px] md:gap-x-[2rem]">
        <img src="/assets/images/save.png" alt="" />
        <div className=" flex md:block flex-col items-center">
          <p className="text-[1rem] text-black font-SF font-[300] mb-[24px]">
            NAIRA GAMIFIED SAVINGS POOL
          </p>
          <p className="text-black max-w-[27.25rem] text-[2.5rem] text-center md:text-left font-SF font-[400] mb-[24px]">
            Win cash prizes as you save{" "}
          </p>
          <p className="max-w-[39.1875rem] text-black text-[0.8rem] text-center md:text-left font-SF font-[300] leading-[36px] mb-[24px]">
            Grab a chance to win juicy savings in OneCap’s Naira Gamified
            Savings pool. Starting with NGN 2,000, this pool offers big and
            small cash prizes at the end of each monthly cycle.
          </p>

          <button
            className="w-[9.375rem] h-[3.125rem] rounded-[30px] text-white text-[1.125rem] font-SF font-[700] bg-brandPurp"
            onClick={() => navigate("/signup")}
          >
            Get started
          </button>
        </div>
      </div>
    </div>
  );
};

export default Gamified;
