import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FilterList } from "@mui/icons-material";
import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Context } from "../../helpers/Context";
import {
  useCheckOut,
  useRewardItems,
  useUserInfo,
} from "../../helpers/Hooks/Api";
import { Oval } from "react-loader-spinner";

const PointCard = ({ name, point, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "0.5px solid #FF1CF7",
        color: "#290349",
        padding: "15px 0",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="body2"
          sx={{ fontSize: "12px", fontWeight: "700" }}
        >
          {name}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ fontWeight: "400", fontSize: "12px" }}>
        {point} {point <= 1 ? "Token" : "Tokens"}
      </Typography>
    </Box>
  );
};

const MyRewards = () => {
  useUserInfo();
  const { isRewardAvailable } = useRewardItems();
  const {
    checkOut,
    checkOutLoading,
    feedback,
    isError,
    isCompleted,
    setIsCompleted,
  } = useCheckOut();
  const { state, dispatch } = useContext(Context);
  const {
    rhPage,
    rewards,
    cart,
    totalCost,
    updateCart,
    totalAvailableToken,
    userInfo,
  } = state;
  const navigate = useNavigate();
  const [openRewards, setOpenRewards] = useState(false);

  const closeRewards = () => {
    setOpenRewards(false);
  };

  useEffect(() => {
    dispatch({ type: "SUM_COST" });
  }, [updateCart, userInfo?.available_token_point]);

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px", my: "20px" }}>
        <Box
          display={"flex"}
          sx={{
            py: "10px",
            display: "flex",
            color: "#592E6D",
            flexDirection: "column",
            alignItems: "center",
            border: "0.5px solid #FF1CF7",
            borderRadius: "5px",
            flex: "1",
          }}
        >
          <Typography variant={"body2"}>Earned Rewards</Typography>
          <img src="/assets/images/cashinhand.svg" alt="Cash in Hand" />
          <Box sx={{ display: "flex" }}>
            <Typography fontSize={"28px"}>
              {userInfo?.total_token_point}
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "3px" }}
            >
              <Typography style={{ fontSize: "12px" }}>OC Tokens</Typography>
              <img src="/assets/images/iInfo.png" alt="" />
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          sx={{
            py: "10px",
            display: "flex",
            color: "#592E6D",
            flexDirection: "column",
            alignItems: "center",
            border: "0.5px solid #FF1CF7",
            borderRadius: "5px",
            flex: "1",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "3px",
            }}
          >
            <Typography variant={"body2"}>Claimed Rewards</Typography>
            <img src="/assets/images/iInfo.png" alt="" />
          </Box>
          <img src="/assets/images/cash.svg" alt="Cash" />
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <Typography fontSize={"28px"}>
              {userInfo?.spent_token_point}
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "3px" }}
            >
              <Typography style={{ fontSize: "12px" }}>OC Tokens</Typography>
              <img src="/assets/images/iInfo.png" alt="" />
            </Box>
          </Box>

          <button
            className=" bg-brandPurp text-white w-[120px] h-[30px] rounded-[5px] border-none font-SF text-[0.75rem] font-[600] mt-[18px]"
            onClick={() => setOpenRewards(true)}
          >
            CLAIM
          </button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "0.5px solid #FF1CF7",
          color: "#290349",
          padding: "15px 0",
        }}
      >
        <Typography variant="body2">Rewards Details</Typography> <FilterList />
      </Box>
      {userInfo?.total_token_point < 1 && (
        <Box
          sx={{ padding: "15px 10px", textAlign: "center", color: "#290349" }}
        >
          <Typography variant="caption">You have no reward yet</Typography>
        </Box>
      )}

      <PointCard
        name={"Services"}
        point={
          userInfo?.bank_detail_point +
          userInfo?.sign_up_point +
          userInfo?.update_phone_point
        }
        onClick={() => {
          localStorage.setItem("reward_category", "Services");
          navigate(
            `/point-details/true/${rhPage}/${localStorage.getItem(
              "current_user"
            )}`
          );
        }}
      />
      <PointCard
        name={"Referral"}
        point={userInfo?.referral_point}
        onClick={() => {
          localStorage.setItem("reward_category", "Referral system");
          navigate(
            `/point-details/false/${rhPage}/${localStorage.getItem(
              "current_user"
            )}`
          );
        }}
      />
      <PointCard
        name={"Gamified savings"}
        point={userInfo?.gamifisaving_point}
        onClick={() => {
          localStorage.setItem("reward_category", "Gamifi savings");
          navigate(
            `/point-details/false/${rhPage}/${localStorage.getItem(
              "current_user"
            )}`
          );
        }}
      />

      <Modal open={openRewards} onClose={closeRewards}>
        <div className="max-w-[380px] h-[638px] bg-white rounded-[10px] m-[auto] p-[24px_18px] relative">
          <h2 className=" text-[0.875rem] text-brandPurp font-SF font-[700] text-center">
            Claim Rewards
          </h2>
          <div
            className=" absolute top-[24px] right-[18px] cursor-pointer"
            onClick={() => setOpenRewards(false)}
          >
            <img src="/assets/images/closePop.png" alt="close" />
          </div>

          <p className=" text-[0.75rem] text-brandPurp font-SF font-[300] mt-[24px]">
            You can use your OneCap token to claim available rewards
          </p>
          <div className=" border-[0.5px] border-[#290349] rounded-[2px] w-full h-[48px] mt-[25px] flex items-center justify-between px-[6px]">
            <div className=" text-[0.875rem] text-brandPurp font-SF font-[700] ">
              Your Points
            </div>
            <div className="flex items-baseline">
              <span className=" text-[1.74rem] text-brandPurp font-SH">
                {totalAvailableToken}
              </span>
              <span className=" text-[0.75rem] text-brandPurp font-SF flex items-center gap-x-[2px]">
                OC Tokens <img src="/assets/images/iInfo.png" alt="info" />
              </span>
            </div>
          </div>

          {isRewardAvailable ? (
            <>
              <div className=" flex items-center gap-x-[122px] mt-[28px] pb-[12px] border-b-[0.2px] border-b-[#FF1CF7] text-[0.875rem] text-brandPurp font-SF">
                <span>Reward items</span>
                <div className="flex items-center gap-x-[40px] ">
                  <span>Tokens</span>
                  <span>Qty</span>
                </div>
              </div>

              <section className="max-h-[18rem] overflow-auto scrollbar-none md:scrollbar scrollbar-w-[8px] scrollbar-track-[rgba(227,196,253,0.50)] scrollbar-thumb-[#484747] scrollbar-track-rounded-[10px] scrollbar-thumb-rounded-[10px] ">
                {rewards?.map((reward) => (
                  <div
                    key={reward?.id}
                    className="flex items-center gap-x-[26px] mt-[10px] pb-[5px] border-b-[0.2px] border-b-[#FF1CF7]"
                  >
                    <div className=" flex items-center justify-between w-[211px]">
                      <span className="text-[0.75rem] text-brandPurp font-SF font-[700]">
                        {reward?.item_brand_name}
                      </span>
                      <img
                        src={` https://onecap-africa.s3.amazonaws.com/${reward?.image}`}
                        alt={`reward${reward?.id}`}
                        className="w-[72px] h-[72px] max-w-[72px] max-h-[72px] min-w-[72px] min-h-[72px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] rounded-[50%]"
                      />
                    </div>
                    <div className="flex items-center gap-x-[14px]">
                      <span className="text-[0.75rem] text-brandPurp font-SF">
                        {reward?.token_cost} Tokens
                      </span>{" "}
                      <div className="flex items-center gap-x-[6px]">
                        <span
                          className=" cursor-pointer text-[0.875rem] text-brandPurp font-SF"
                          onClick={() =>
                            dispatch({
                              type: "DECREASE_PRODUCT",
                              payload: reward?.id,
                            })
                          }
                        >
                          -
                        </span>
                        <div className="h-[36px] w-[36px] border-[0.5px] border-[#290349] text-[0.875rem] text-brandPurp font-SF flex items-center justify-center">
                          {cart[reward?.id] === undefined
                            ? 0
                            : cart[reward?.id]}
                        </div>
                        <span
                          onClick={() =>
                            totalAvailableToken > reward?.token_cost &&
                            dispatch({
                              type: "INCREASE_PRODUCT",
                              payload: reward?.id,
                            })
                          }
                          className=" cursor-pointer text-[0.875rem] text-brandPurp font-SF"
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </section>

              <div className="flex items-center justify-between mt-[20px]">
                <span className=" text-[0.875rem] text-brandPurp font-SF font-[700] ">
                  Your claim is:
                </span>{" "}
                <div className="flex items-baseline">
                  <span className=" text-[1.74rem] text-brandPurp font-SH">
                    {totalCost}
                  </span>
                  <span className=" text-[0.75rem] text-brandPurp font-SF flex items-center gap-x-[2px]">
                    OC Tokens
                  </span>
                </div>
              </div>

              <div
                className="w-[150px] h-[30px] rounded-[5px] bg-brandPurp border-none text-white text-[0.75rem] font-SF font-[600] mt-[18px] m-[0_auto] cursor-pointer flex items-center justify-center "
                // onClick={() => checkOut(cart)}
                onClick={() => {}}
              >
                DONE
                {checkOutLoading && (
                  <span className="ml-[2px]">
                    {" "}
                    <Oval
                      height={15}
                      width={15}
                      color="#290349"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#fff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </span>
                )}
              </div>

              <Modal
                open={isCompleted}
                onClose={() => setIsCompleted(false)}
                className={`flex items-center `}
              >
                <>
                  {isError ? (
                    <section
                      className={`w-[24.2574rem] h-[230px] m-[0_auto] p-[10px] mt-[2rem] transition-transform duration-700 ease-in-out bg-[#fff] shadow-[0px_11px_15px_0px_rgba(0,0,0,0.20),0px_9px_46px_0px_rgba(0,0,0,0.12),0px_24px_38px_0px_rgba(0, 0, 0, 0.14)] rounded-[4px] flex flex-col items-center`}
                    >
                      <div
                        className=" w-full flex justify-end self-start cursor-pointer"
                        onClick={() => setIsCompleted(false)}
                      >
                        <img
                          src="/assets/images/closeBold.png"
                          alt=""
                          className=" h-[24px] w-[24px]"
                        />
                      </div>
                      <div className="text-[1rem] text-[#592E6D] font-SF font-[600] flex items-center justify-center mt-[37px]">
                        Oops! That didn’t work
                      </div>
                      <div className=" max-w-[20.375rem] text-center m-[0_auto] text-[0.75rem] text-[#592E6D] font-SF font-[400]">
                        {isError
                          ? feedback
                          : "Something went wrong! Please try again."}
                      </div>

                      <div className="flex items-center gap-x-[18px] mt-[23px]">
                        <button
                          onClick={() => setIsCompleted(false)}
                          className=" underline text-[0.875rem] text-[#290349] font-EN font-[300]"
                        >
                          Back
                        </button>{" "}
                      </div>
                    </section>
                  ) : (
                    <section
                      className={`w-[24.2574rem] h-[230px] m-[0_auto] p-[10px] transition-transform duration-700 ease-in-out bg-[#fff] shadow-[0px_11px_15px_0px_rgba(0,0,0,0.20),0px_9px_46px_0px_rgba(0,0,0,0.12),0px_24px_38px_0px_rgba(0, 0, 0, 0.14)] rounded-[4px] flex flex-col`}
                    >
                      <div
                        className=" w-full flex justify-end self-start cursor-pointer"
                        onClick={() => setIsCompleted(false)}
                      >
                        <img
                          src="/assets/images/closeBold.png"
                          alt=""
                          className=" h-[24px] w-[24px]"
                        />
                      </div>
                      <div className="text-[1rem] text-[#592E6D] font-SF font-[600] flex items-center justify-center mt-[37px]">
                        <span className="pt-[5px]"> Success!</span>
                        <img
                          src="/assets/images/success.gif"
                          alt=""
                          className="w-[50px] h-[50px]"
                        />
                      </div>
                      <div className=" max-w-[20.375rem] text-center m-[0_auto] text-[0.75rem] text-[#592E6D] font-SF font-[400]">
                        Your order has been received
                      </div>
                    </section>
                  )}
                </>
              </Modal>
            </>
          ) : (
            <>
              <img
                src="/assets/images/noReward.png"
                alt="no reward"
                className="mt-[98px] m-[0_auto]"
              />

              <p className="text-[0.75rem] text-brandPurp text-center font-AR font-[700] mt-[70px]">
                Oops! There are no rewards available
              </p>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default MyRewards;
