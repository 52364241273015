import React, { useState } from "react";
import Navigation from "./Navigation";
import Accord from "./Accord";

const AboutUs = () => {
  const [arr, setArr] = useState([]);
  const handleClick = (id) => {
    if (arr?.includes(id)) {
      const filteredArray = arr?.filter((ar) => ar !== id);
      setArr(filteredArray);
    } else {
      setArr((ar) => [...ar, id]);
    }
  };
  return (
    <>
      <Navigation />
      <div className=" w-full px-[7%] md:px-[13.8%] pb-[50px] mt-[20px]">
        <h2 className=" text-[2.5rem] text-black font-SF font-[500]">
          About Us
        </h2>

        <div className="w-full mt-[28px] flex flex-col gap-y-[24px]">
          <Accord
            id="1"
            idArray={arr}
            heading="Introduction"
            text={
              <p>
                Welcome to OneCap, where finance meets fun, and saving is a
                thrilling and entertaining adventure. We're not just a fintech
                platform; we're your partner in a unique financial journey.
              </p>
            }
            click={() => handleClick("1")}
          />
          <Accord
            id="2"
            idArray={arr}
            heading="Our Story"
            text={
              <div className="flex flex-col gap-y-[20px]">
                <p>
                  OneCap was born from a vision to transform the way young
                  individuals engage with their finances. Founded by a team of
                  passionate professionals with diverse backgrounds in finance,
                  technology, and blockchain, our journey began with a simple
                  question: How can we make saving and financial learning
                  exciting and rewarding?
                </p>

                <p>
                  Our story is one of relentless dedication and unwavering
                  commitment to bridging the gap between financial disengagement
                  and empowerment.
                </p>
              </div>
            }
            click={() => handleClick("2")}
          />
          <Accord
            id="3"
            idArray={arr}
            heading="Our Vision"
            text={
              <p>
                Our vision is clear: to redefine the financial landscape for the
                younger generation. We see a future where financial literacy is
                accessible, savings are effortless, and opportunities for growth
                are abundant. OneCap strives to be the beacon that guides young
                adults towards financial wisdom and prosperity in a fun and
                entertaining manner.
              </p>
            }
            click={() => handleClick("3")}
          />
          <Accord
            id="4"
            idArray={arr}
            heading="Our Mission"
            text={
              <p>
                At OneCap, our mission is to make financial engagement a
                thrilling experience. We're committed to providing a gamified
                platform that not only encourages savings but also educates and
                rewards our users. Through innovation and user-centric
                solutions, we aim to transform the way you perceive and interact
                with your finances.
              </p>
            }
            click={() => handleClick("4")}
          />

          <Accord
            id="5"
            idArray={arr}
            heading="Core Values"
            text={
              <div className="flex flex-col gap-y-[20px]">
                <p>
                  Innovation: We embrace creativity and leverage cutting-edge
                  technology to continuously enhance your financial experience.
                </p>
                <p>
                  User-Centricity: You are at the heart of everything we do.
                  Your needs and aspirations drive our solutions.
                </p>
                <p>
                  Transparency: We believe in openness and honesty. Trust is the
                  foundation of strong financial partnerships.
                </p>
                <p>
                  Empowerment: We empower you with knowledge, tools, and
                  opportunities to take control of your financial future.
                </p>
                <p>
                  Community: We're not just a platform; we're a community.
                  Together, we learn, grow, and celebrate financial
                  achievements.
                </p>
              </div>
            }
            click={() => handleClick("5")}
          />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
