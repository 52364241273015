import { Box, Typography } from "@mui/material";

import { Helmet, HelmetProvider } from "react-helmet-async";
import Navigation from "./Navigation";
import Footer from "./Footer";

function Privacy() {
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Onecap | Privacy Policy</title>
        </Helmet>
      </HelmetProvider>
      <div className="homeContainer">
        <Box
          fontFamily={"Asap Condensed !important"}
          sx={{
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Navigation />

          <Box
            sx={{
              background: "#fff",
              py: "20px",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <section className="px-[24px] md:px-[24px] lg:px-[13.8%]">
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <Typography variant="h3">PRIVACY POLICY </Typography>
                <Typography variant={"body1"}>
                  Eko-Gainz Limited (“Eko-Gainz“, “we“, “our“, or “us“) owners
                  of Onecap.africa (Platform) is committed to protecting your
                  privacy. This privacy policy sets out how we collect, store,
                  process, transfer, share and use data that identities or is
                  associated with you (“personal information “) and information
                  regarding our use of cookies and similar technologies.
                </Typography>
                <Typography variant={"body1"}>
                  OneCap (“Digital Brand Name”) is an fintech aggregator
                  platform that connects users with credible investment
                  institutions, searches for financial opportunities and gamify
                  financial interactions in an exciting and unique way for its
                  users. Users of OneCap are automatic members of Gainz
                  Cooperative Multipurpose Society Limited (the “Cooperative”)
                  is a cooperative society registered under the Cooperative
                  Societies Law of Lagos State with the main purpose to achieve
                  economic advancement and breakthrough of its members by
                  developing a savings and investing culture amongst its
                  members. In achieving the economic breakthrough and
                  advancement of the members of the Cooperative, the Cooperative
                  sources for and identify investment opportunities which it
                  offers to its members for their joint participation. Further
                  to the foregoing and in an attempt of the Cooperative to
                  leverage technology and fully digitalise its processes, the
                  Cooperative executed a Service Level Agreement with Eko-Gainz
                  for the deployment of this Platform to digitalise the
                  processes of the Cooperative, including on-boarding members,
                  registration of members, virtual communication amongst
                  members, receiving of contributions and dues from members
                  through the Platform, listing several economic opportunities
                  sourced and requested by the Cooperative to be made available
                  for the benefit of the members. The essence of the deployment
                  of the Platform by Eko-Gainz for the Cooperative is to ensure
                  that the traditional process and relationship between members
                  of a cooperative and the cooperative is fully digitalised for
                  the benefit of the Cooperative and its members.
                </Typography>

                <Typography variant={"body1"}>
                  a. This privacy policy applies to your use of the Platform (as
                  defined in the Terms and Conditions).
                </Typography>

                <Typography variant={"body1"}>
                  b.
                  AsauserofthePlatform,youacceptthisPolicywhenyousignupfor,access,
                  or use our products, services, content, features,
                  technologies, or functions offered on our Platform and all
                  related sites, applications, and services.
                </Typography>
                <Typography variant={"body1"}>
                  c. This Policy is intended to govern the use of the Platform
                  by users. Users reserve the right to exercise their data
                  protection rights as protected under the applicable data
                  protection laws.
                </Typography>
                <Typography variant={"body1"}>
                  d. This Policy regulates how personal information will be used
                  by Eko-Gainz. To preserve the confidentiality of your data, we
                  maintain the controls in place for privacy.
                </Typography>
                <Typography variant={"body1"}>
                  e. Before accessing or using the Platform, please ensure that
                  you have read and understood our collection, storage, use and
                  disclosure of your personal information as described in this
                  privacy policy. By accessing or using the Platform, you are
                  accepting and consenting to the practices described in this
                  privacy policy.
                </Typography>
                <Typography variant={"body1"}>
                  f. We collect personal information about you when you
                  voluntarily submit information directly to us by filling in
                  forms on our Platform or by corresponding with us by phone,
                  email, or other means. This includes information you provide
                  when you register to use our Platform or participate in any
                  discussion boards or fora.
                </Typography>
                <Typography variant={"body1"}>
                  g. We may receive personal information about you from
                  individuals or corporate entities which are subscribers to or
                  members of, where you are to be designated a user of the
                  Platform. We may receive personal information about you if you
                  use any of the other platforms we operate or the other
                  services we provide from time to time.
                </Typography>
                <Typography variant="h5">1. USE OF THIS INFORMATION</Typography>
                <Typography variant={"body1"}>
                  The Platform may include or make available certain content
                  (the “Content”), which includes, without limitation: account
                  positions, balances, transactions, confirmations, and order
                  history; company names, logos, product and service names,
                  trade names, trademarks and services marks (collectively,
                  “Marks”) owned by Company, and Marks owned by Third-Party
                  Providers (defined below), and any other information, content,
                  services, or software. Certain Content is supplied,
                  distributed, provided or delivered by a Third-Party Provider
                  (the “Third-Party Content”) such as information, services or
                  software, made available by, through or on the Platform.
                </Typography>
                <Typography variant={"body1"}>
                  a. We will use this information as set out below:
                </Typography>
                <Typography variant={"body2"}>
                  i. Eko-Gainz only stores data for the purpose which it was
                  collected for. The retention period for storage of data is
                  also largely determined by Legal and Regulatory bodies.
                </Typography>
                <Typography variant={"body2"}>
                  ii. to respond to your inquiries and fulfil your requests;{" "}
                </Typography>
                <Typography variant={"body2"}>
                  iii. administer your accounts with us;
                </Typography>
                <Typography variant={"body2"}>
                  iv. process orders or applications submitted by you;
                </Typography>
                <Typography variant={"body2"}>
                  v. to inform you about important information regarding the
                  Platform, products or services for which you apply or may be
                  interested in applying for, or in which you are already
                  enrolled, changes to terms, conditions, and policies and/or
                  other administrative information;
                </Typography>
                <Typography variant={"body2"}>
                  vi. send you information about our products and services;
                </Typography>
                <Typography variant={"body2"}>
                  vii. carry out marketing analysis and make general
                  improvements to our Platform;
                </Typography>
                <Typography variant={"body2"}>
                  viii. obtain your views or comments on the services we
                  provide;
                </Typography>
                <Typography variant={"body2"}>
                  ix. to deliver marketing communications that we believe may be
                  of interest to you, including, ads or offers tailored to you;
                </Typography>
                <Typography variant={"body2"}>
                  x. to allow you to apply for products or services;
                </Typography>
                <Typography variant={"body2"}>
                  xi. to verify your identity and/or location, in order to allow
                  access to your accounts, conduct online transactions and to
                  maintain measures aimed at preventing fraud and protecting the
                  security of account and personal information;
                </Typography>
                <Typography variant={"body2"}>
                  xii. for risk control, for fraud detection and prevention, to
                  comply with laws and regulations, and to comply with other
                  legal process and law enforcement requirements;
                </Typography>
                <Typography variant={"body2"}>
                  xiii.Based on the request of Governmental compliance bodies,
                  our regulators, and judicial bodies Eko-Gainz may disclose
                  personal information;
                </Typography>
                <Typography variant={"body2"}>
                  xiv.Eko-Gainz will also use your information to detect
                  abnormalities or fraudulent activities on our system; and
                </Typography>
                <Typography variant={"body2"}>
                  xv. We may contact you as necessary to enforce our policies,
                  applicable law, or any agreement we may have with you.
                </Typography>
                <Typography variant="h5">
                  2. MARKETING AND ADVERTISING
                </Typography>
                <Typography variant={"body1"}>
                  We may contact you with relevant information about the
                  Platform and our other products and services and as may be
                  directed by the Cooperative. Often the messages will be sent
                  electronically. For some messages, we may use personal
                  information we collect about you to help us determine the most
                  relevant information to share with you.
                </Typography>
                <Typography variant={"body1"}>
                  Should you be against us reaching you via this means, you will
                  be able to tell us by selecting certain boxes on forms we use
                  when we collect your contact details. It is also possible to
                  change your marketing preferences at any time by simply
                  following the unsubscribe link at the bottom of our emails.
                </Typography>
                <Typography variant="h5">3. SECURITY OF DATA</Typography>
                <Typography variant={"body1"}>
                  We protect your information using physical, technical, and
                  administrative security measures to reduce risks of misuse,
                  loss, unauthorized access.
                </Typography>
                <Typography variant={"body1"}>
                  Although no transmission of information via the internet is
                  completely secure, we take reasonable measures to protect your
                  personal information. We cannot absolutely guarantee the
                  security of your personal information transmitted to our
                  Platform; any transmission is at your own risk. Upon receipt
                  of your information, we will implement strict procedures and
                  our security features to try to prevent unauthorized access.
                </Typography>
                <Typography variant="h5">
                  4. INTERNATIONAL TRANSFER/RECEIPT OF PERSONAL INFORMATION
                </Typography>
                <Typography variant={"body1"}>
                  The information we collect may be received or transferred out
                  of and stored outside jurisdiction. If you are located in the
                  European Union (“EU”), United States or anywhere outside
                  Nigeria, your personal information will be safeguarded by the
                  General Data Protection Regulation (“GDPR”). Appropriate
                  measures such as having Data Processing Agreements with
                  applicable sub-processors and ensuring that such
                  sub-processors have adequate security and data protection
                  procedures in place. If you wish to inquire further about
                  these safeguards used, please contact us using the details set
                  out at the end of this policy.
                </Typography>
                <Typography variant="h5">
                  5. SHARING THIS INFORMATION
                </Typography>
                <Typography variant={"body1"}>
                  The major services available on this Platform are offered by
                  the Cooperative and some other are offered in conjunction with
                  one or more of our business partners In order for those
                  services to be provided we provide some necessary details
                  about you to the Cooperative or our other business partners.
                  Save for communication to the Cooperative which is the primary
                  reason for the collection of information, we will tell you
                  about this at the point we collect that information on the
                  Platform that we will share the said information. Please note
                  that certain services may be unavailable if you do not want to
                  disclose the personal information you are asked for.
                </Typography>
                <Typography variant={"body1"}>
                  We may transfer your personal information to a third party as
                  part of a sale of some or all of our business and assets to
                  any third party or as part of any business restructuring or
                  reorganization, but we will take steps with the aim of
                  ensuring that your privacy rights continue to be protected. In
                  addition, we may pass your information onto one of our
                  carefully selected business partners or to other carefully
                  selected third parties to enable them to send you information
                  which may be of interest to you but only if you have given us
                  permission to do so. You can tell us to stop this at any time
                  by sending an email. Other than as set out above, we will not
                  disclose any of your personal information without your
                  permission unless we are required by law to do so (for
                  example, if required to do so by a court order or for the
                  purposes of prevention of fraud or other crime).
                </Typography>
                <Typography variant="h5">
                  6. YOUR RIGHTS IN RESPECT OF YOUR PERSONAL INFORMATION
                </Typography>
                <Typography variant={"body1"}>
                  In accordance with applicable privacy law, you have the
                  following rights in respect of your personal information that
                  we hold:
                </Typography>
                <ul>
                  <li>
                    Right to erasure: The right to obtain the erasure of your
                    personal information without undue delay in certain
                    circumstances, such as where the personal information is no
                    longer necessary in relation to the purposes for which it
                    was collected or processed.{" "}
                  </li>

                  <li>
                    Right to rectification: The right to obtain rectification of
                    your personal information without undue delay where that
                    personal information is inaccurate or incomplete.
                  </li>
                  <li>
                    Right to restriction. The right to obtain the restriction of
                    the processing undertaken by us on your personal information
                    in certain circumstances, such as where the accuracy of the
                    personal information is contested by you or the sale of your
                    personal information for a period enabling us to verify the
                    accuracy of that personal information.
                  </li>

                  <li>
                    Right to object: The right to object, on grounds relating to
                    your situation, to the processing of your personal
                    information, and to object to the processing of your
                    personal information for direct marketing purposes, to the
                    extent it is related to such direct marketing.
                  </li>
                  <li>
                    Right to non-discrimination: The right to non-discrimination
                    for exercising your rights as outlined in this policy. This
                    includes, but is not limited to, denying you services,
                    charging you different prices for similar services, or
                    providing a different level or quality of service.
                  </li>

                  <li>
                    Right of access and portability: The right to obtain access
                    to your personal information along with certain information,
                    and to receive that personal information in a commonly used
                    format and to have it ported to another data controller.
                  </li>
                </ul>
                <Typography variant="h5">7. CHANGES TO YOUR DETAILS</Typography>
                <Typography variant={"body1"}>
                  Keeping your account information accurate and up to date is
                  very important. If your account information is incomplete,
                  inaccurate, or not current, please use the Contact Us option
                  on our Platform, or call or write to us at the telephone
                  numbers or appropriate address for changes listed on your
                  account statements, records, online or other account
                  materials. You can also speak to a customer representative
                  from the Company.
                </Typography>
                <Typography variant="h5">
                  8. INFORMATION AUTOMATICALLY COLLECTED FROM YOUR COMPUTER
                </Typography>
                <Typography variant={"body1"}>
                  Eko-Gainz and our third-party service providers may collect
                  and use other information in a variety of ways, including:
                </Typography>
                <ul>
                  <li>
                    Aggregated and De-Identified Data: Aggregated and
                    De-Identified Data is data that we may create or compile
                    from various sources, including but not limited to accounts
                    and transactions. This information, which does not identify
                    individual account holders, may be used for our business
                    purposes, which may include offering products or services,
                    research, marketing or analysing market trends, and other
                    purposes consistent with applicable laws.
                  </li>

                  <li>
                    Log files/IP addresses: When you visit the Platform our web
                    server automatically records your IP address. This IP
                    address is not linked to any of your personal information.
                    We use IP addresses to help us administer the Platform and
                    to collect demographic information for aggregation purposes.
                  </li>
                  <li>
                    Other technologies including pixel tags, web beacons, and
                    clear GIFs: These may be used in connection with some
                    Platform pages, downloadable mobile applications and
                    HTML-formatted email messages to measure the effectiveness
                    of our communications, the success of our marketing
                    campaigns, to compile statistics about usage and response
                    rates, to personalize/tailor your experience while engaging
                    with us online and offline, for fraud detection and
                    prevention, for security purposes, for advertising, and to
                    assist us in resolving account holders’ questions regarding
                    use of our Platform.
                  </li>

                  <li>
                    Through your browser or device: Certain information is
                    collected by most browsers and/or through your device, such
                    as your Media Access Control (MAC) address, device type,
                    screen resolution, operating system version and internet
                    browser type and version. We use this information to ensure
                    Platform function properly, for fraud detection and
                    prevention, and security purposes.
                  </li>
                  <li>
                    We may also gather other non-personal information (from
                    which we cannot identify you) such as the type of your
                    internet browser which we use to provide you with a more
                    effective service.
                  </li>
                </ul>
                <Typography variant="h5">9. COOKIES</Typography>
                <Typography variant={"body1"}>
                  When you visit the Platform, we may store some information
                  (commonly known as a “cookie”) on your computer. Cookies are
                  small files of information which use a unique identification
                  tag and are stored on your device as a result of using the
                  Platform or other services we provide to you. Our Service uses
                  cookies and similar technologies (collectively referred to as
                  cookies) to distinguish you from other users of our Platform.
                  We use cookies:
                </Typography>
                <ul>
                  <li>
                    collect statistical information about how you use the
                    Platform so that we can improve the Platform;
                  </li>
                  <li>
                    to track how our Platform is used and to improve and update
                    our content;
                  </li>
                  <li>
                    so that you will not have to re-enter your details each time
                    you visit the Platform;
                  </li>
                  <li>
                    customise elements of the layout and/or content of the
                    Platform for you;
                  </li>
                  <li>store your preferences.</li>
                </ul>
                <Typography variant={"body1"}>
                  You block cookies by activating the setting on your browser
                  that allows you to refuse the setting of all or some cookies.
                  However, if you use your browser settings to block all cookies
                  (including strictly necessary cookies) you may not be able to
                  access all or parts of the Platform. You will need to manage
                  your cookie settings for each device and browser you use.
                  However, if you do not accept these cookies, you may
                  experience some inconvenience in your use of the Platform and
                  some online products and services. For example, we will not be
                  able to recognize your device and you will need to answer a
                  challenge question each time you log on. You also may not
                  receive tailored advertising or other offers from us that may
                  be relevant to your interests and needs.
                </Typography>
                <Typography variant="h5">10. SECURITY</Typography>
                <Typography variant={"body1"}>
                  To protect personal information from unauthorized access and
                  use, we use security measures that comply with applicable
                  federal laws. These measures may include device safeguards and
                  secured files and buildings as well as oversight of our
                  third-party service providers to ensure information remains
                  confidential and secure. We have implemented technology and
                  policies to safeguard your privacy from unauthorised access
                  and improper use.
                </Typography>
                <Typography variant="h5">
                  11. LINKS TO THIRD PARTY SITES
                </Typography>
                <Typography variant={"body1"}>
                  The Platform may, from time to time, contain links to and from
                  third party websites. If you follow a link to any of these
                  websites, please note that these websites have their own
                  privacy policies and that we do not accept any responsibility
                  or liability for their policies. Please check the individual
                  policies before you submit any information to those websites.
                </Typography>
                <Typography variant={"body1"}>
                  Some of the pages on our Platform may utilize framing
                  techniques to serve content to/from our partners while
                  preserving the look and feel of our Platform. Please be aware
                  that you are providing your personal information to these
                  third parties and not to Eko-Gainz.
                </Typography>
              </Box>
            </section>
          </Box>

          <Footer />
        </Box>
      </div>
    </>
  );
}

export default Privacy;
