import { useState, useEffect } from "react";
import { ArrowBackIos, FilterList } from "@mui/icons-material";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../../helpers/axios";
import NavLayout from "../NavLayout";

const ReferralDetails = () => {
  const [referralList, setReferralList] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchReferralList = async () => {
    await axios
      .get("/user/referral/list", config)
      .then(function (response) {
        setReferralList(response?.data);
      })
      .catch(function (error) {
        console.log(error);
        navigate("/signin");
      });
  };
  useEffect(() => {
    fetchReferralList();
  }, []);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(referralList.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e, id) => {
    const { checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const remindMany = async () => {
    const users = isCheck;
    await axios
      .post("/user/notify/multiple-user", { users }, config)
      .then(function (response) {
        if (response.data.status === true) {
          setFeedback(response.data.message);
          setShowFeedback(true);
          setTimeout(() => {
            setShowFeedback(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          setFeedback(error.response.data);
          setShowFeedback(true);
          setTimeout(() => {
            setShowFeedback(false);
          }, 5000);
        }
      });
  };

  return (
    <NavLayout
      content={
        <Box
          maxWidth={"500px"}
          sx={{ margin: "0 auto", paddingBottom: "3rem" }}
        >
          <Box>
            <Box
              sx={{
                padding: "24px 20px",
                borderBottom: "0.1px solid #FFF",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "16px",
                    cursor: "pointer",
                    position: "absolute",
                    left: "0",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <Typography variant="body1">Referrals</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ padding: "0 20px", color: "#fff", position: "relative" }}>
            <Box sx={{ margin: "10px 0" }}>
              <Typography variant="caption">Referral Details</Typography>
            </Box>

            <Box
              sx={{
                background: "#fff",
                padding: "20px",
                color: "#fff",
                marginTop: "15px",
                border: "0.5px solid #FF1CF7",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <Box
                sx={{
                  color: "#592E6D",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "0.1px solid #FF1CF7",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="caption"
                    sx={{ marginRight: "5px", fontWeight: "600" }}
                  >
                    Filter
                  </Typography>
                  <FilterList />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      paddingTop: "5px",
                      marginRight: "-8px",
                      fontWeight: 600,
                    }}
                  >
                    Check All
                  </Typography>
                  <Checkbox
                    checked={isCheckAll && true}
                    onChange={handleSelectAll}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      size: "small",
                      marginRight: "-8px",
                      color: "#592E6D",
                      "&.Mui-checked": {
                        color: "#592E6D",
                      },
                    }}
                  />
                </Box>
              </Box>
              {referralList?.map((referral) => {
                return (
                  <Box
                    key={referral?.id}
                    sx={{
                      color: "#592E6D",
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "0.1px solid #FF1CF7",
                      padding: "8px 0",
                    }}
                  >
                    <Box>
                      <Typography variant="body2" sx={{ fontWeight: "600" }}>
                        {referral?.first_name} {referral?.last_name}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <Typography variant="caption">
                          Referral Status:
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: "600", marginLeft: "2px" }}
                        >
                          {referral?.has_invested === true
                            ? "You've got a reward"
                            : "No Investment"}
                        </Typography>
                      </Box>
                    </Box>

                    <Checkbox
                      onClick={(event) => handleClick(event, referral.id)}
                      sx={{
                        size: "small",
                        marginRight: "-8px",
                        color: "#592E6D",
                        "&.Mui-checked": {
                          color: "#592E6D",
                        },
                      }}
                      checked={isCheck.includes(referral.id)}
                    />
                  </Box>
                );
              })}

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Button
                  sx={{
                    height: "50px",
                    width: "320px",
                    backgroundColor: "#592E6D",
                    textTransform: "none",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#290349b7",
                    },
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "12px", fontWeight: "600" }}
                    onClick={remindMany}
                  >
                    SEND REMINDER
                  </Typography>
                </Button>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                {showFeedback && (
                  <Typography variant="caption" color={"red"}>
                    {feedback}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default ReferralDetails;
