import { useState, useEffect, useContext } from "react";

import { Box, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material/";
import { useNavigate, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

import axios from "../../helpers/axios";
import NavLayout from "../NavLayout";
import { Context } from "../../helpers/Context";
import Moment from "react-moment";

const RewardsHistory = () => {
  const [isLoading, setIsloading] = useState(true);
  const { is_service, current_user_id } = useParams();
  const token = localStorage.getItem("token");
  const [pointDetails, setPointDetails] = useState({});
  const { state, dispatch } = useContext(Context);
  const { rhPage } = state;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const point_category = localStorage.getItem("reward_category");
  const url =
    point_category === "Services"
      ? `/user/points/history/?is_service=${is_service}&page=${rhPage}&user_id=${current_user_id}`
      : `/user/points/history/?category=${point_category}&is_service=${is_service}&page=${rhPage}&user_id=${current_user_id}`;

  const fetchRewardsHistory = async () => {
    setIsloading(true);
    await axios
      .get(url, config)
      .then(function (response) {
        setIsloading(false);
        setPointDetails(response?.data);
        navigate(`/point-details/${is_service}/${rhPage}/${current_user_id}`, {
          rhPage,
        });
      })
      .catch(function (error) {
        if (error?.response?.status === 401) {
          navigate("/signin");
        } else if (error?.response?.status === 404) {
          setPointDetails({});
          navigate(
            `/point-details/${is_service}/${rhPage}/${current_user_id}`,
            { rhPage }
          );
        }
        console.log(error);
        setIsloading(false);
      });
  };
  useEffect(() => {
    fetchRewardsHistory();
  }, [rhPage]);

  const navigate = useNavigate();
  return (
    <NavLayout
      title="Savings Details"
      content={
        <Box maxWidth={"500px"} sx={{ margin: "0 auto" }}>
          <Box
            sx={{
              paddingBottom: "20px",
            }}
          >
            <Box
              sx={{
                padding: "24px 20px",
                borderBottom: "0.1px solid #FFFFFF80",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#fff",
                  marginBottom: "10px",
                }}
              >
                <ArrowBackIos
                  sx={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/rewards");
                    dispatch({ type: "RESET" });
                  }}
                />
                <Box textAlign={"center"} width="100%">
                  <Typography variant="body1">Point Details</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ padding: "24px 20px" }}>
            <Box
              sx={{
                background: "#fff",
                height: "600px",
                overflow: "scroll",
                border: "0.01px solid rgba(255,28,247,0.4)",
                borderRadius: "10px",
                px: "10px",
                color: "#592E6D",
                mb: "50px",
                position: "relative",
              }}
            >
              {isLoading ? (
                <Box
                  h={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Oval
                    height={80}
                    width={80}
                    color="#290349"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#592e6d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </Box>
              ) : (
                <Box sx={{ marginTop: "20px" }}>
                  <Typography sx={{ textAlign: "center" }}>
                    {localStorage.getItem("reward_category")}
                  </Typography>

                  {pointDetails?.results?.map((details) => {
                    return (
                      <Box
                        key={details?.id}
                        sx={{
                          padding: "10px 0",
                          borderBottom: "0.2px solid grey",
                        }}
                      >
                        <HistoryCard
                          title="Description"
                          value={details?.description}
                        />
                        <HistoryCard
                          title="Date"
                          value={
                            <Moment format="MMMM DD, YYYY">
                              {details?.time_stamp}
                            </Moment>
                          }
                        />
                      </Box>
                    );
                  })}
                  <Box
                    sx={{
                      backgroundColor: "transparent",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      my: "20px",
                    }}
                  >
                    {pointDetails?.previous !== null && (
                      <button
                        style={{
                          backgroundColor: "transparent",
                          color: "#592E6D",
                          border: "none",
                          cursor: "pointer",
                          fontWeight: "600",
                        }}
                        onClick={() => {
                          dispatch({ type: "PREVIOUS" });
                        }}
                      >
                        Previous
                      </button>
                    )}
                    {pointDetails?.next !== null && (
                      <button
                        style={{
                          backgroundColor: "transparent",
                          color: "#592E6D",
                          border: "none",
                          cursor: "pointer",
                          fontWeight: "600",
                        }}
                        onClick={() => {
                          dispatch({ type: "NEXT" });
                        }}
                      >
                        Next
                      </button>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      }
    />
  );
};
const HistoryCard = ({ title, value }) => {
  return (
    <Box display={"flex"} alignItems="baseline" columnGap={"10px"}>
      <Typography fontWeight={700} variant="caption">
        {title}:
      </Typography>
      <Typography fontWeight={700} variant="caption">
        {value}
      </Typography>
    </Box>
  );
};

export default RewardsHistory;
