import { Box, Typography } from "@mui/material";
import NavLayout from "../NavLayout";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Payout = () => {
  const navigate = useNavigate();

  const MenuCard = ({ name, link }) => {
    return (
      <div
        onClick={() => navigate(link)}
        className="flex justify-between items-center mb-[25px] text-black text-[0.875rem] font-AR cursor-pointer"
      >
        {name} <img src="/assets/images/forward.png" />
      </div>
    );
  };
  return (
    <NavLayout
      title="Rewards-Menu"
      content={
        <Box
          maxWidth={"500px"}
          sx={{ margin: "0 auto", paddingBottom: "5rem" }}
        >
          <Box>
            <Box
              sx={{
                padding: "24px 20px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "16px",
                    cursor: "pointer",
                    position: "absolute",
                    left: "0",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <Typography variant="body1" className=" text-center">
                  Wallet Payout
                </Typography>
              </Box>
              <div className=" bg-white max-w-[500px] h-[707px] rounded-[10px] mt-[21px] p-[79px_20px]">
                <MenuCard name="Banks" link="/settings/banks" />
                <MenuCard name="BVN" link="/settings/bvn" />
              </div>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default Payout;
