import * as Yup from "yup";
const formstyles = {
  "& .MuiInputLabel-root": {
    color: "#fff",
    fontSize: "0.75rem",
    paddingLeft: "5px",
  },
  "& .MuiInputBase-root": {
    color: "#fff",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#fff",
    paddingTop: "5px",
  },
  "&.MuiFormLabel-asterisk": {
    color: "red !important",
  },
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(3, "First name must be at least 3 characters"),
  last_name: Yup.string()
    .required("Last name is required")
    .min(3, "Last name must be at least 3 characters"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(9, "Password must be at least 9 characters")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .max(40, "Password must not exceed 40 characters"),
  confirmPassword: Yup.string()
    .required("Passwords is required")
    .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  t_and_c: Yup.bool().oneOf([true], "Accept terms to register"),
});

const loginValidationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .required("Password is required")
    .min(9, "Password must be at least 9 characters")
    .max(40, "Password must not exceed 40 characters"),
});

const resetValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(9, "Password must be at least 9 characters")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .max(40, "Password must not exceed 40 characters"),
  confirmPassword: Yup.string()
    .required("Passwords is required")
    .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
});
export {
  formstyles,
  validationSchema,
  loginValidationSchema,
  resetValidationSchema,
};
