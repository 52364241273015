import { useNavigate } from "react-router-dom";
import Divider from "../../../helpers/Divider";
import NavLayout from "../../NavLayout";
import { useContext, useState } from "react";
import CountDown from "./CountDown";
import JoinPool from "./JoinPool";
import { Modal } from "@mui/material";
import HCard from "../../../helpers/HCard";
import { useGamefiSavings } from "../../../helpers/Hooks/Api";
import { Context } from "../../../helpers/Context";

const GamfiPool = () => {
  useGamefiSavings();
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const [openHistory, setOPenHistory] = useState(false);

  const { state } = useContext(Context);
  const { gamefiSavings } = state;
  const onCloseHistory = () => {
    setOPenHistory(false);
  };
  return (
    <NavLayout
      title="Gamfi Pool"
      content={
        <div className="max-w-[500px] m-[0_auto]">
          <section className="flex items-center pt-[35px] px-[18px]">
            <img
              src="/assets/images/slideBack.png"
              alt=""
              className=" cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <span className="w-full text-center text-[1rem] text-[rgba(255,255,255,0.87)] font-SF font-[400] space-x-[0.15px]">
              Naira Gamified Savings Pool
            </span>
          </section>

          <Divider />

          <section className="w-full px-[18px]">
            <div className="w-full flex items-center justify-between bg-[rgba(255,255,255,0.20)] rounded-[10px] px-[18px]">
              <img
                src="/assets/images/relax.png"
                alt=""
                className="w-[10.5494rem] h-[8.125rem]"
              />{" "}
              <button
                to={""}
                className=" text-[0.875rem] text-white font-ENL font-[600] underline"
                onClick={() => setOPenHistory(true)}
              >
                View Pool History
              </button>
            </div>
          </section>

          <section className="mt-[65px] w-full min-h-[607px] bg-white pb-[112px] px-[18px] relative">
            <div className=" shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] bg-white rounded-[10px] absolute top-[-35px] left-[18px] right-[18px]  px-[12px] pt-[23px] pb-[59px]">
              <div className="w-full flex items-center">
                <button
                  className={`w-[50%] flex justify-center pb-[18px] transition-colors duration-500 ease-in-out text-[#290349] text-[0.875rem] font-SF font-[700] ${
                    tab === 1 ? "border-b-[#FF1CF7]" : "border-b-[transparent]"
                  } border-b-[2px]`}
                  onClick={() => setTab(1)}
                >
                  Draw Countdown
                </button>
                <button
                  className={`w-[50%] flex justify-center pb-[18px] transition-colors duration-500 ease-in-out text-[#290349] text-[0.875rem] font-SF font-[700] ${
                    tab === 2 ? "border-b-[#FF1CF7]" : "border-b-[transparent]"
                  } border-b-[2px]`}
                  onClick={() => setTab(2)}
                >
                  Join Pool
                </button>
              </div>

              <section className="relative w-full min-h-[420px] pb-[59px] ">
                <div
                  className={`absolute left-0 right-0 transition-opacity duration-500 ease-in-out ${
                    tab === 1 ? "opacity-100" : "opacity-0 invisible"
                  }`}
                >
                  <CountDown />
                </div>
                <div
                  className={`absolute left-0 right-0 transition-opacity duration-500 ease-in-out ${
                    tab === 2 ? "opacity-100" : "opacity-0 invisible"
                  }`}
                >
                  <JoinPool />
                </div>
              </section>
            </div>
          </section>

          <Modal open={openHistory} onClose={onCloseHistory}>
            <div className="w-[22rem] md:w-[24.5rem] h-[calc(100%-4px)] md:h-[569px] rounded-[10px] bg-[#fff] p-[18px] m-[0_auto] mt-[4px] overflow-auto scrollbar-none md:scrollbar scrollbar-w-[8px] scrollbar-track-[rgba(227,196,253,0.50)] hover:scrollbar-thumb-[#484747] scrollbar-thumb-[transparent] scrollbar-track-rounded-[10px] scrollbar-thumb-rounded-[10px] relative">
              <div className="flex items-center justify-between fixed w-[20.5rem] md:w-[23rem] bg-white h-[4rem] top-[4px] rounded-tr-[10px] rounded-tl-[10px]">
                <span className=" text-[0.875rem] text-[#592E6D] font-SF font-[700]">
                  Naira Gamified Savings Pool History
                </span>{" "}
                <img
                  src="/assets/images/closeBold.png"
                  alt=""
                  className=" cursor-pointer"
                  onClick={() => setOPenHistory(false)}
                />
              </div>

              <HCard
                child1="Pool ID"
                child2="Amount"
                child3="Draw Date"
                child4="Status"
                className=" flex items-center justify-between pb-[22px] border-b-[0.2px] border-b-[#FF1CF7] text-[0.75rem] text-[#592E6D] font-SF font-[700] mt-[4rem]"
              />

              {gamefiSavings?.length === 0 ? (
                <div className="text-[0.75rem] text-[#592E6D] font-SF font-[700] mt-[15px] text-center">
                  No active history
                </div>
              ) : (
                gamefiSavings?.map((savings) => (
                  <HCard
                    child1="0441"
                    child2={savings?.amount_saved}
                    child3={savings?.end_date}
                    child4={savings?.status}
                    click={() => navigate(`/savings-details/${savings?.uuid}`)}
                  />
                ))
              )}
            </div>
          </Modal>
        </div>
      }
    />
  );
};

export default GamfiPool;
