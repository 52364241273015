import "./NavLayout.css";
import { Box, Typography } from "@mui/material";
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  HomeOutlined,
  SavingsOutlined,
  CardGiftcard,
  EmojiEventsOutlined,
} from "@mui/icons-material";

import { Link, useLocation } from "react-router-dom";

const boxStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "rgba(89, 46, 109, 0.5)",
  cursor: "pointer",
  ":hover": {
    color: "#592e6d",
  },
};

function NavLayout(props) {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{props.title ? `Onecap | ${props.title}` : "Onecap"}</title>
        </Helmet>
      </HelmetProvider>
      <Box
        sx={{
          minHeight: "100vh",
          backgroundImage: `url(/assets/images/bg.svg),linear-gradient(180deg, #290349 0%, #2e084d 100%),linear-gradient(180deg, #290349 0%, #2e084d 100%)`,
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: "50px",
        }}
      >
        {props.content}
        <Box
          mt={"5px"}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            position: "fixed",
            background: "#fff",
            borderTop: ".5px solid rgba(89, 46, 109, 0.5)",

            bottom: 0,
            width: "100%",
            padding: "6px 0",
            zIndex: "2",
          }}
        >
          <Box sx={boxStyle}>
            <Link to="/dashboard">
              <div
                style={{ textAlign: "center" }}
                className={
                  splitLocation[1] === "dashboard" ? "active" : "inactive"
                }
              >
                <HomeOutlined />
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  Home
                </Typography>
              </div>
            </Link>
          </Box>

          <Box sx={boxStyle}>
            <Link to="/jackpot-pool">
              <div
                style={{ textAlign: "center" }}
                className={
                  splitLocation[1] === "jackpot-pool" ? "active" : "inactive"
                }
              >
                <SavingsOutlined />
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  Savings Pool
                </Typography>
              </div>
            </Link>
          </Box>
          <Box sx={boxStyle}>
            <Link to="/referrals">
              <div
                style={{ textAlign: "center" }}
                className={
                  splitLocation[1] === "settings" ? "active" : "inactive"
                }
              >
                <CardGiftcard />
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  Referrals
                </Typography>
              </div>
            </Link>
          </Box>
          <Box sx={boxStyle}>
            <Link to="/rewards">
              <div
                style={{ textAlign: "center" }}
                className={splitLocation[1] === "pools" ? "active" : "inactive"}
              >
                <EmojiEventsOutlined />
                <Typography variant="body2" sx={{ fontSize: "12px" }}>
                  Rewards
                </Typography>
              </div>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default NavLayout;
