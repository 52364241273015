export const formstyles = {
  "& .MuiInputLabel-root": {
    color: "#592E6D",
    fontSize: "14px",
    paddingLeft: "5px",
  },
  "& .MuiInputBase-root": {
    color: "#592E6D",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#592E6D",
    paddingTop: "5px",
  },
  "& .MuiFormLabel-asterisk": {
    color: "red !important",
  },
  "& .MuiInputBase-root-MuiInput-root:before": {
    borderBottom: "0px",
  },
  "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:after": {
    borderBottom: "0px",
  },
  "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before": {
    borderBottom: "0px",
    content: "none",
  },
  "& .MuiInputBase-root-MuiInput-root:after": {
    borderBottom: "0px",
  },
};
