import { Box, Typography } from "@mui/material";
import NavLayout from "../NavLayout";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useBVNUpdate } from "../../helpers/Hooks/Api";
import { useEffect, useState } from "react";

const BVN = () => {
  const navigate = useNavigate();

  const [bvn, setBvn] = useState("");
  const [fieldError, setFieldError] = useState("");
  const {
    BVNStatus,
    checkBVN,
    serverError,
    setServerError,
    updateBVN,
    shouldUpdate,
  } = useBVNUpdate();

  const updateBvn = () => {
    setFieldError("");
    if (bvn?.length === 11) {
      shouldUpdate
        ? updateBVN({ bvn })
        : setServerError("The details you entered are invalid!");

      return;
    } else {
      setFieldError("BVN should not be less than 10 digits");
    }
  };

  const handleChange = (e) => {
    setBvn(e.target.value);
    if (e.target.value.length === 11) {
      checkBVN({ bvn: e.target.value });
    } else {
      setFieldError("");
      setServerError("");
    }
  };

  useEffect(() => {
    if (bvn.length === 11) {
      setFieldError(BVNStatus);
    }
  }, [BVNStatus, bvn]);

  return (
    <NavLayout
      title="Update-BVN"
      content={
        <Box
          maxWidth={"500px"}
          sx={{ margin: "0 auto", paddingBottom: "5rem" }}
        >
          <Box>
            <Box
              sx={{
                padding: "24px 20px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "16px",
                    cursor: "pointer",
                    position: "absolute",
                    left: "0",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <Typography variant="body1">Wallet Payout</Typography>
              </Box>
              <div className=" bg-white max-w-[500px] h-[707px] rounded-[10px] mt-[21px] p-[16px_10px]">
                <h2 className=" text-center text-[0.875rem] text-[#2E084D] font-AR ">
                  BVN
                </h2>
                <p className=" text-center text-[0.875rem] text-[#2E084D] font-AR mt-[34px] ">
                  Your BVN is only for the sole purpose of verification.
                </p>

                <div className="mt-[63px]">
                  <label className=" text-[0.75rem] text-brandPurp font-AR font-[700]">
                    BVN
                  </label>
                  <input
                    type="text"
                    className=" w-full p-[12px_8px] rounded-[4px] border-[1px] border-brandPurp  outline-none mt-[5px] text-[rgba(0,0,0,0.50)] text-[0.75rem]"
                    onChange={handleChange}
                    style={{ border: "1px solid #592E6D" }}
                  />
                  <small
                    className={`${
                      serverError === "BVN updated successfully"
                        ? "text-[#25CF43]"
                        : "text-[#F00]"
                    } text-[0.625rem] font-AR`}
                  >
                    {fieldError ? fieldError : serverError}
                  </small>

                  <button
                    className=" bg-brandPurp text-white py-[12px] rounded-[10px] w-full mt-[113px] text-[0.75rem] font-AR font-[700] "
                    onClick={updateBvn}
                  >
                    Update
                  </button>
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default BVN;
