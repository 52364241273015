import React from "react";

const Save = () => {
  return (
    <div className="w-full py-[50px] flex flex-col justify-center items-center md:items-start bg-white px-[7%] md:px-[13.8%]">
      <div className="flex items-center gap-x-[12px] text-[3rem] text-black font-[700] font-AC">
        Save /seɪv/ <img src="/assets/images/speaker.png" alt="" />
      </div>{" "}
      <div className="text-[2rem] text-black text-center md:text-left font-AC font-[300] max-w-[75%] md:max-w-[100%]">
        "Hide your money from yourself for cool future use."
      </div>
      <div className="flex items-center gap-x-[25.5px] md:self-end mt-[26.3px]">
        <img
          src="/assets/images/lineStroke.png"
          alt=""
          className="h-[1.996px] w-[60px]"
        />
        <img src="/assets/images/Onecap.png" alt="" />
        <img
          src="/assets/images/lineStroke.png"
          alt=""
          className="h-[1.996px] w-[60px]"
        />
      </div>
    </div>
  );
};

export default Save;
