import { useRef } from "react";
import { Box, Typography } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Navigation from "./Navigation";
import Footer from "./Footer";
function Tnc() {
  const billPayment = useRef(null);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Onecap | Home</title>
        </Helmet>
      </HelmetProvider>
      <div className="homeContainer">
        <Box
          fontFamily={"Asap Condensed !important"}
          sx={{
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Navigation />

          <Box
            ref={billPayment}
            sx={{
              background: "#fff",
              py: "40px",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <section className="px-[24px] md:px-[24px] lg:px-[13.8%]">
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <Typography variant="h3">
                  INTRODUCTION TO THE TERMS AND CONDITIONS
                </Typography>
                <Typography variant={"body1"}>
                  This Platform is operated by Eko-Gainz Limited (“Eko-Gainz”),
                  an information and communication technology company that
                  deploys technology solutions for the benefits of several
                  organisations across Nigeria.
                </Typography>
                <Typography variant={"body1"}>
                  OneCap (“Digital Brand Name”) is an fintech aggregator
                  platform that connects users with credible investment
                  institutions, searches for financial opportunities and gamify
                  financial interactions in an exciting and unique way for its
                  users. Users of OneCap are automatic members of Gainz
                  Cooperative Multipurpose Society Limited (the “Cooperative”)
                  is a cooperative society registered under the Cooperative
                  Societies Law of Lagos State with the main purpose to achieve
                  economic advancement and breakthrough of its members by
                  developing a savings and investing culture amongst its
                  members. In achieving the economic breakthrough and
                  advancement of the members of the Cooperative, the Cooperative
                  sources for and identify investment opportunities which it
                  offers to its members for their joint participation. Further
                  to the foregoing and in an attempt of the Cooperative to
                  leverage technology and fully digitalise its processes, the
                  Cooperative executed a Service Level Agreement with Eko-Gainz
                  for the deployment of this Platform to digitalise the
                  processes of the Cooperative, including on-boarding members,
                  registration of members, virtual communication amongst
                  members, receiving of contributions and dues from members
                  through the Platform, listing several economic opportunities
                  sourced and requested by the Cooperative to be made available
                  for the benefit of the members. The essence of the deployment
                  of the Platform by Eko-Gainz for the Cooperative is to ensure
                  that the traditional process and relationship between members
                  of a cooperative and the cooperative is fully digitalised for
                  the benefit of the Cooperative and its members.
                </Typography>
                <Typography variant={"body1"}>
                  Eko-Gainz is strictly an ICT firm and does not give investment
                  recommendations or financial advice to the Cooperative or to
                  the members of the Cooperative. Eko-Gainz will generally
                  advise that the Please consider among other important factors,
                  investment objectives and pricing before investing. Eko-Gainz
                  is not an issuer of investment products, and the Platform is
                  just an avenue through which the processes of the Cooperative
                  and its members are digitalised
                </Typography>
                <Typography variant={"body1"}>
                  The Platform, Eko-Gainz Logo, branding and other works in
                  respect of which intellectual property rights may subsist
                  shall remain the intellectual property of Eko-Gain and the
                  rights in those works shall remain vested in Eko-Gainz
                </Typography>
                <Typography variant="h3">TERMS AND CONDITIONS</Typography>

                <Typography variant="h5">
                  1. ACCEPTANCE OF TERMS AND CONDITIONS.
                </Typography>
                <Typography variant={"body1"}>
                  a. Introduction: These terms and conditions, together with any
                  documents they expressly incorporate by reference
                  (collectively, these “Terms”) govern your access, download and
                  use of the Platform, including any content, component,
                  functionality and/or service offered on or through the
                  Platform.
                </Typography>
                <Typography variant={"body1"}>
                  “Platform” shall refer to all the information and
                  telecommunication technology owned, controlled and deployed by
                  Eko-Gainz Limited (“Company” or “Eko-Gainz” or “we” or “our”
                  or “us”), including but not limited to the website located at
                  [https://onecap.africa], mobile or web application, WhatsApp
                  or other instant messaging application, etcetera for the
                  benefit of the Cooperative and the purpose of its members. All
                  reference to “you” or “your”, as applicable, mean a user
                  and/or the Person who accesses, uses and/or participates in
                  Platform in any manner, and such Person’s heirs, assigns, and
                  successors. If you use the Platform on behalf of an entity,
                  “you” or “your,” as applicable, means that entity and its
                  affiliates and their respective directors, officers,
                  employees, and agents.
                </Typography>
                <Typography variant={"body1"}>
                  b. Acceptance of these Terms. BY USING OR DOWNLOADING OR
                  ACCESSING OR USING THE PLATFORM YOU INDICATE YOUR AGREEMENT TO
                  BECOME A MEMBER OF THE COOPERATIVE AND ACCEPTANCE OF THE
                  FOLLOWING TERMS ON YOUR OWN BEHALF AND ON BEHALF OF ANY
                  ORGANIZATION YOU REPRESENT. IN AGREEING TO BE A MEMBER OF THE
                  COOPERATIVE, YOU AGREE THAT EKO-GAINZ OBTAINS AND PROCESSES
                  YOUR PERSONAL DATA FOR THE PURPOSE OF TRANSMITTING SAME TO THE
                  COOPERATIVE. THE CONSTITUTION AND BYE-LAWS ARE ACCESSIBLE AT
                  [https://onecap.africa/bye-laws], AND YOU AGREE TO BE BOUND BY
                  THE TERMS CONTAINED IN THE CONSTITUTION AND BYE-LAWS, WHICH
                  ARE INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO
                  THESE TERMS (OR ANY UPDATES TO OR MODIFIED VERSIONS THEREOF),
                  YOU SHOULD DISCONTINUE USE OF PLATFORM IMMEDIATELY
                </Typography>
                <Typography variant={"body1"}>
                  c. Your Duty to Regularly Review these Terms. Your use of
                  Platform is governed by the version of the Terms in effect on
                  the date Platform is accessed by you. Company may modify these
                  Terms at any time and without notice. You should review the
                  most current version of these Terms by regularly visiting
                  Platform and clicking on the Terms and Conditions hyperlink.
                </Typography>
                <Typography variant={"body1"}>
                  d. Third-Party Provider Services. The Platform may offer you
                  the option to enter into relationships and agreements with
                  Third-Party Providers for access to additional services, as a
                  condition to having access to those Third-Party Provider
                  services, you may be required to enter into various agreements
                  with a Third-Party Provider such as, for example, any service
                  or account agreements. Provided that Eko-Gainz does not make
                  any representation as to the expertise or qualification of any
                  Third-Party Provider and shall not have any liability that may
                  arise pursuant to your engagement, arrangement or agreement
                  with the Third-Party Provider
                </Typography>
                <Typography variant="h5">2. GENERAL</Typography>
                <Typography variant={"body1"}>
                  The Platform may include or make available certain content
                  (the “Content”), which includes, without limitation: account
                  positions, balances, transactions, confirmations, and order
                  history; company names, logos, product and service names,
                  trade names, trademarks and services marks (collectively,
                  “Marks”) owned by Company, and Marks owned by Third-Party
                  Providers (defined below), and any other information, content,
                  services, or software. Certain Content is supplied,
                  distributed, provided or delivered by a Third-Party Provider
                  (the “Third-Party Content”) such as information, services or
                  software, made available by, through or on the Platform.
                </Typography>
                <Typography variant="h5">3. LEGAL AGE</Typography>
                <Typography variant={"body1"}>
                  You must be at least 18 years of age to become a user of the
                  Platform. You represent and warrant that you are 18 years of
                  age or older and are fully able and competent to enter into,
                  and abide by, the contract created by these Terms of Use.
                </Typography>
                <Typography variant="h5">4. Account</Typography>
                <Typography variant={"body1"}>
                  To gain access to the advantages and the benefits offered by
                  the Cooperative, you are sign-up on the Platform, and which
                  sign up means you agree to be a member of the Cooperative,
                  which would require the provision of certain personally
                  identifiable information, including but not limited to your
                  name, your phone number, your address, your email address,
                  Bank Verification Number (BVN) (“Client information”). You
                  will select your own password at the time of registration, and
                  you agree that:
                </Typography>
                <Typography variant={"body1"}>
                  1. You are eighteen years of age and otherwise comply with
                  applicable law
                </Typography>
                <Typography variant={"body1"}>
                  2. You will not use a username (or e-mail address) that is
                  already being used by someone else, or that infringes the
                  intellectual property or other right of any person or entity,
                  or is offensive;
                </Typography>
                <Typography variant={"body1"}>
                  3. You will provide true, accurate, current, and complete
                  registration information about yourself in connection with the
                  registration process;
                </Typography>
                <Typography variant={"body1"}>
                  4. You are solely responsible for all activities that occur
                  under your Account, password, and username;
                </Typography>
                <Typography>
                  5. You are solely responsible for maintaining the
                  confidentiality of your password and for restricting access to
                  your mobile device so that others may not access any password
                  protected portion of Platform using your name, username, or
                  password;
                </Typography>
                <Typography variant={"body1"}>
                  6. You will immediately notify us of any unauthorized use of
                  your Account, password, or username, or any other breach of
                  security; and
                </Typography>
                <Typography variant={"body1"}>
                  7. You will not sell, transfer, or assign your account or any
                  Account rights.
                </Typography>
                <Typography variant={"body1"}>
                  OPTING TO CREATE AN ACCOUNT ON THE PLATFORM, SATISFIES THAT
                  YOU HAVE ACCEPTED ALL OUR TERMS AND CONDITIONS AND AGREED TO
                  BE A MEMBER OF THE COOPERATIVE.
                </Typography>
                <Typography variant={"body1"}>
                  If any information that you provide, or if we have reasonable
                  grounds to suspect that any information that you provide, is
                  false, inaccurate, outdated, incomplete, or violates these
                  Terms or any applicable law, then we may suspend or terminate
                  your account without recourse to you. You agree that you will
                  make available upon request, up to date identification and any
                  other additional information, in order to keep it true,
                  accurate and current. We also reserve the more general and
                  broad right to terminate your account or suspend or otherwise
                  deny you access to it or its benefits – all in our sole
                  discretion, for any reason, with or without notice to you and
                  without liability
                </Typography>
                <Typography variant="h5">5. MEANS OF ACCESS</Typography>
                <Typography variant={"body1"}>
                  The Platform can also be viewed on a device with internet
                  access or a mobile device with an Android or iOS operating
                  system. Although you may use other means to access the
                  Platform, be aware that the Platform may not appear accurately
                  through other access methods. You also agree that you use the
                  devices or access the Platform only at your own risk.
                </Typography>
                <Typography variant="h5">6. ACCOUNT SECURITY</Typography>
                <Typography variant={"body1"}>
                  You are responsible for ensuring the safety of your Account.
                  You must install the latest version of any mobile applications
                  that we publish from time to time. These updates often include
                  improved security or fixes for recently discovered
                  vulnerabilities. We will take reasonable steps consistent with
                  our legal duties to protect your Company Account and secure
                  it, however we are not liable for security breaches that may
                  occur where we have complied with those obligations.
                </Typography>
                <Typography variant="h5">7. PLATFORM AVAILABILITY</Typography>
                <Typography variant={"body1"}>
                  While we have put resources into building and testing our
                  application, glitches, slowdowns, and crashes may occur. We
                  will also need to restrict access to some parts of our
                  Platform to perform routine maintenance. We will try to
                  schedule our maintenance during the middle of the night. While
                  it is our intention that our website will be available seven
                  days a week except when maintenance is scheduled (usually for
                  weekends or any other day in the case of an emergency), you
                  understand that we do not guarantee that you will always be
                  able to access the Platform.
                </Typography>
                <Typography variant="h5">8. PRODUCT AVAILABILITY</Typography>
                <Typography variant={"body1"}>
                  You understand that there will be various investment products
                  on the Platform, which you understand are as furnished by the
                  Cooperative. You understand that the products are only
                  available to individuals who create an account and provide the
                  necessary registration requirements for the Platform and
                  membership of the Cooperative. You understand that we reserve
                  the right to modify or change our Platform or, where prompted
                  by the Company, change any of the offerings or process by the
                  Cooperative, temporarily or permanently with or without prior
                  notice to you.
                </Typography>
                <Typography variant="h5">
                  9. NO RECOMMENDATIONS OR INVESTMENT ADVICE
                </Typography>
                <Typography variant={"body1"}>
                  You are solely responsible for evaluating the merits and risks
                  associated with the use of any Content provided through the
                  Platform before making any decisions based on such Content.
                  You agree not to hold the Company or any Third-Party Provider
                  liable for any possible claim for damages arising from any
                  decision you make based on the Content or other information
                  made available to you through the Platform or any Third-Party
                  Provider. Past performance data should not be construed as
                  indicative of future results. You understand that the Company
                  is a mere agent only deploying the Platform for the use of the
                  Cooperative, and your claims under any case whatsoever shall
                  only lie against the Cooperative and not the Company.
                </Typography>
                <Typography variant="h5">10. EXECUTION OF ORDERS</Typography>
                <Typography variant={"body1"}>
                  All transactions on the Platform are communicated by the
                  Company to the Cooperative and the decision to accept to
                  reject your order is at the sole discretion of the Cooperative
                  and any of its financial partners.
                </Typography>
                <Typography variant="h5">
                  11. ORDER CANCELLATION/MODIFICATION
                </Typography>
                <Typography variant={"body1"}>
                  You acknowledge that it may not be possible to cancel/ modify
                  an order and that you are responsible for executions
                  notwithstanding a cancel/modify request.
                </Typography>
                <Typography variant="h5">12. PROTECTION CLAUSE</Typography>
                <Typography variant={"body1"}>
                  EKO-Gainz undertakes, to the extent required under law, to
                  protect the privacy of all information provided by members
                  except in instances of disclosure to the Cooperative and to
                  law enforcement agencies and or statutory agencies to assist
                  in the prevention or detection of fraud or other criminal
                  activities.
                </Typography>
                <Typography variant="h5">13. CONTACT</Typography>
                <Typography variant={"body1"}>
                  If you require help or have questions or complaint about the
                  Platform, please call our customer care centre on +234
                  8091119845 or email hello@onecap.africa
                </Typography>
                <Typography variant="h5">
                  14. WARRANTIES, DISCLAIMERS AND LIMITATIONS OF LIABILITY
                </Typography>
                <Typography variant={"body1"}>
                  If you require help or have questions or complaint about the
                  Platform, please call our customer care centre on +234
                  8091119845 or email hello@onecap.africa
                </Typography>
                <Typography variant="h5">YOUR WARRANTIES</Typography>
                <Typography variant={"body1"}>
                  You represent and warrant to Eko-Gainz that:
                </Typography>
                <ol style={{ listStyleType: "lower-latin" }}>
                  <li>
                    all information, including, without limitation, personal
                    information, that you provide to us is accurate and
                    truthful,
                  </li>
                  <li>
                    you have the authority to share the information with us and
                    to grant us the right to use the information as provided in
                    these Terms of Use and Privacy Policy,
                  </li>
                  <li>
                    your acceptance and use of the Platform pursuant to these
                    Terms of Use does not violate any applicable law or other
                    contract or obligation to which you are a party or are
                    otherwise bound, and
                  </li>
                  <li>
                    the Materials do not infringe the intellectual property
                    rights, including any copyrights, trademarks, trade secrets,
                    right of privacy, or right of publicity, of any person.
                  </li>
                </ol>
                <Typography variant="h5">DISCLAIMER OF WARRANTIES</Typography>
                <Typography variant={"body1"}>
                  THE COMPANY MAKES NO WARRANTIES. THE COMPANY RESERVES THE
                  RIGHT TO RESTRICT OR TERMINATE YOUR ACCESS TO THE PLATFORM AND
                  THE RESPECTIVE CONTENT OR ANY FEATURE OR PART THEREOF AT ANY
                  TIME. IF YOU DOWNLOAD THE PLATFORM OR ANY CONTENT FROM THIS
                  PLATFORM, YOU DO SO AT YOUR OWN DISCRETION AND RISK. YOU WILL
                  BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM
                  OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM THE
                  DOWNLOAD OF ANY SUCH CONTENT.
                </Typography>
                <Typography variant="h5">LIMITATION OF LIABILITY.</Typography>
                <Typography variant={"body1"}>
                  COMPANY WILL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
                  CONSEQUENTIAL, INCIDENTAL, SPECIAL, DIRECT, OR INDIRECT
                  DAMAGES (INCLUDING BUT NOT LIMITED TO LOST PROFITS, TRADING
                  LOSSES OR DAMAGES THAT RESULT FROM USE OR LOSS OF USE OF
                  PLATFORM AND THIRD-PARTY CONTENT, INCONVENIENCE OR DELAY).
                  THIS IS TRUE EVEN IF COMPANY HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES OR LOSSES. COMPANY WILL NOT BE
                  LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS RESULTING FROM A
                  CAUSE OVER WHICH SUCH COMPANY DOES NOT HAVE DIRECT CONTROL.
                  THIS INCLUDES FAILURE OF ELECTRONIC OR MECHANICAL EQUIPMENT OR
                  COMMUNICATIONS LINES (INCLUDING TELEPHONE, CABLE AND
                  INTERNET), UNAUTHORIZED ACCESS, VIRUSES, THEFT, OPERATOR
                  ERRORS, SEVERE OR EXTRAORDINARY WEATHER (INCLUDING FLOOD,
                  EARTHQUAKE, OR OTHER ACT OF GOD), FIRE, WAR, INSURRECTION,
                  TERRORIST ACT, RIOT, LABOR DISPUTE AND OTHER LABOR PROBLEMS,
                  ACCIDENT, EMERGENCY OR ACTION OF GOVERNMENT. IF YOU LIVE IN A
                  JURISDICTION THAT DOES NOT ALLOW THE LIMITATION OR EXCLUSION
                  OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SOME OR
                  ALL OF THESE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
                </Typography>
                <Typography variant="h5">Miscellaneous</Typography>
                <ul>
                  <li>
                    This Terms is governed by the laws of the Federal Republic
                    of Nigeria
                  </li>
                  <li>
                    You agree to the provision of this Terms in English and
                    represents that you understand its terms and conditions.
                    This Terms contains the entire agreement between the
                    parties, who have made no other representations or
                    warranties. If any provision of this Terms is unenforceable,
                    it shall not invalidate other provisions. Failure of the
                    Company to enforce any term or condition of this Terms is
                    not a waiver of the term/condition.
                  </li>
                  <li>
                    You consent to recording of all telephone conversations with
                    the Company. You acknowledge the Company’s Privacy Policy
                    and consents to collection/use of your information as
                    described therein.
                  </li>
                  <li>
                    You authorize the Company, directly or through third
                    parties, to make any inquiries that the Company considers
                    necessary to discharge its functions to the Cooperative,
                    including the registration and on-boarding of members of the
                    Cooperative.
                  </li>
                </ul>
              </Box>
            </section>
          </Box>
          <Footer />
        </Box>
      </div>
    </>
  );
}

export default Tnc;
