import NavLayout from "../NavLayout";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useOrders } from "../../helpers/Hooks/Api";
import { useContext } from "react";
import { Context } from "../../helpers/Context";
import Moment from "react-moment";
import { Oval } from "react-loader-spinner";

const TrackRewards = () => {
  const { ordersLoading } = useOrders();
  const navigate = useNavigate();
  const { state } = useContext(Context);
  const { orders } = state;

  const TrackCard = ({ key, rewardNum, date, onClick }) => {
    return (
      <div
        key={key}
        className="w-full h-[56px] rounded-[5px] border-[1px] border-[#592E6D] relative pt-[8px] pl-[10px] cursor-pointer"
        onClick={onClick}
      >
        <div>
          <p className=" text-[0.625rem] font-AR text-[#592E6D]">
            REWARD NUMBER
          </p>
          <p className=" text-[0.75rem] font-AR text-[#592E6D] font-[700] mt-[3px]">
            {rewardNum}
          </p>
        </div>
        <p className=" absolute bottom-[9px] right-[5px] text-[0.5625rem] text-black ">
          {date}
        </p>
      </div>
    );
  };

  return (
    <NavLayout
      title="Rewards-Menu"
      content={
        <div className=" max-w-[428px] md:max-w-[500px] m-[0_auto] ">
          <div className="flex w-full pb-[20px] border-b-[rgba(255,255,255,0.50)] border-b-[0.2px] relative justify-center items-center pt-[20px] ">
            <ArrowBackIos
              sx={{ fontSize: "16px" }}
              className="cursor-pointer absolute left-[18px] text-white"
              onClick={() => {
                navigate(-1);
              }}
            />

            <span className="text-center text-[1rem] text-[rgba(255,255,255,0.87)] font-AR">
              Track Rewards
            </span>
          </div>

          <div className=" bg-white max-w-[392px] md:max-w-[500px] min-h-[707px] p-[29px_16px] m-[0_auto] mt-[13px] rounded-[10px] border-[0.5px] border-[#FF1CF7] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] pb-[5rem]">
            <h2 className="text-[0.875rem] text-black font-AR">Track Reward</h2>

            <div className="rounded-[10px] w-full flex flex-col gap-y-[24px] mt-[18px]">
              {ordersLoading ? (
                <div className="mt-[50px] w-full flex items-center justify-center">
                  <Oval
                    height={80}
                    width={80}
                    color="#290349"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#290349"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              ) : (
                orders?.map((order) => (
                  <TrackCard
                    key={order?.id}
                    rewardNum={order?.reward_number}
                    date={
                      <Moment format="DD/MM/YY">
                        {order?.processing_time}
                      </Moment>
                    }
                    onClick={() => {
                      localStorage.setItem("orderId", order?.id);
                      navigate("/track-reward");
                    }}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default TrackRewards;
