import { Box, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";

const How = () => {
  const navigate = useNavigate();

  return (
    <NavLayout
      title="How"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#F5F5F5",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              background: "#FFFFFF",
              px: "20px",
              pt: "20px",
              pb: "100px",
              boxSizing: "border-box",
            }}
          >
            <ArrowBackIos
              onClick={() => navigate(-1)}
              sx={{
                fontSize: "16px",
                color: "#592E6D",
                cursor: "pointer",
                pl: "20px",
                mt: "20px",
              }}
            />
            <Typography
              sx={{
                color: "#592E6D",
                fontSize: "24px",
                fontWeight: "600",
                marginTop: "30px",
                marginLeft: "20px",
              }}
            >
              ONECAP’s FAQ
            </Typography>
            <Typography
              sx={{
                color: "#592E6D",
                fontSize: "18px",
                fontWeight: "600",
                marginTop: "5px",
                marginLeft: "20px",
              }}
            >
              Frequently Asked Questions (FAQ) – OneCap
            </Typography>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                What is OneCap?
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                OneCap is an innovative gamified savings platform that empowers
                you to take control of your financial future with an engaging
                and rewarding experience.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                How do I join the Gamified Savings Pool?
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                To join, simply sign up at
                <Link to={"/"}>https://onecap.africa/</Link> and make a deposit
                of at least 2000 naira into the gamified savings pool.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                How long is a Gamified Savings cycle?
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                The pool opens on the 18th of every month and closes on the
                28th. In February, the dates are the 16th to the 26th. The
                savings cycle begins on the 1st of the following month and ends
                on the last day of that month.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                When does the Gamified Savings Draw happen?
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                The draw takes place on the last day of the month, around 11:59
                p.m., where winners are randomly selected from the savings
                tickets.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                What happens to my savings if I'm not selected in a raffle draw?
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                If you're not selected, your savings remain intact, and you
                still earn rewards.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                How can I increase my chances of winning?
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                By saving more, you increase your chances of winning. For every
                N2,000 saved, you receive a ticket. So, saving N4,000 earns you
                2 tickets, and saving N10,000 earns you 5 tickets.
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Can I withdraw my savings before the Gamified savings cycle
                ends?
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                No, withdrawals can only be made at the end of the month.
                However, if you didn't join the pool, you can request your money
                at any time.
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                What is the minimum withdrawal limit?
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                The minimum withdrawal limit is N1,000.
              </Typography>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default How;
