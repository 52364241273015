import React from "react";

const HCard = ({ child1, child2, child3, child4, ticket, click, ...props }) => {
  return (
    <div
      className=" flex items-center justify-between pb-[22px] border-b-[0.2px] border-b-[#FF1CF7] text-[0.75rem] text-[#592E6D] font-SF font-[700] mt-[27px] cursor-pointer"
      {...props}
      onClick={click}
    >
      <span
        className={`${
          ticket === "true" ? "w-[30px] flex items-center justify-center" : ""
        }`}
      >
        {child1}
      </span>
      <span
        className={`${
          ticket === "true" ? "w-[70px] flex items-center justify-center" : ""
        }`}
      >
        {child2}
      </span>
      <span
        className={`font-[400] ${
          ticket === "true" ? "w-[70px] flex items-center justify-center" : ""
        }`}
      >
        {child3}
      </span>
      <span
        className={`font-[400] ${
          ticket === "true" ? "w-[85px] flex items-center justify-center" : ""
        }`}
      >
        {child4}
      </span>
    </div>
  );
};

export default HCard;
