import { createContext, useReducer } from "react";

export const Context = createContext();
export const initialState = {
  referral_code: "",
  referral_details: {},
  refetch_referral_details: false,
  userWalletData: {},
  userProfile: {},
  userPreference: {},
  rewards_details: [],
  quizBoardData: [],
  userInfo: {},
  winningPrize: 0,
  jackpotWinningPrize: 0,
  cycle: "",
  tickets: [],
  jackpotTickets: [],
  referral_rewards_details: [],
  poolInfo: {},
  gamefiSavings: [],
  jackpotSavings: [],
  jackpotPoints: 0,
  gamefiCurrentPrize: {},
  jackpotCurrentPrize: {},
  joinGamefiPool: false,
  joinJackpotPool: false,
  jackpotPoolInfo: {},
  poolDataLoading: false,
  poolInfoLoading: false,
  jackpotPoolInfoLoading: false,
  page: 1,
  rhPage: 1,
  quizPage: 0,
  states: [],
  banks: [],
  LGAs: [],
  userBanks: [],
  userState: "",
  nodeRef: "",
  nodeRefTime: "",
  cart: {},
  cartCost: {},
  totalCost: 0,
  totalAvailableToken: 0,
  updateCart: false,
  rewards: [],
  orders: [],
  order: {},
  accountDetails: {},
  noBankDetails: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case "SET_REFERRAL_CODE":
      return { ...state, referral_code: action.payload };
    case "SET_REFERRAL_DETAILS":
      return { ...state, referral_details: action.payload };

    case "SET_NODE_REF":
      const { nodeName, time } = action.payload;
      return { ...state, nodeRef: nodeName, nodeRefTime: time };

    case "REFETCH_REFERRAL_DETAILS":
      return {
        ...state,
        refetch_referral_details: !state.refetch_referral_details,
      };

    case "SET_STATES":
      return { ...state, states: action.payload };
    case "SET_LGAS":
      return { ...state, LGAs: action.payload };

    case "SET_BANKS":
      return { ...state, banks: action.payload };

    case "SET_USER_BANKS":
      return { ...state, userBanks: action.payload };

    case "SET_NO_BANK_DETAILS":
      return { ...state, noBankDetails: action.payload };

    case "SET_USER_STATE":
      const stateName = action.payload;
      const selectedState = state.states?.find(
        (state) => state.name === stateName
      );
      const selectedStateCode = selectedState?.state_code;
      return { ...state, userState: selectedStateCode };

    case "SET_REWARDS_DETAILS":
      return { ...state, rewards_details: action.payload };

    case "SET_USER_WALLET_DATA":
      return { ...state, userWalletData: action.payload };
    case "SET_ACCOUNT_DETAILS":
      return { ...state, accountDetails: action.payload };

    case "SET_QUIZ_BOARD_DATA":
      return { ...state, quizBoardData: action.payload };

    case "SET_USER_PROFILE":
      return { ...state, userProfile: action.payload };

    case "SET_USER_PREFERENCE":
      return { ...state, userPreference: action.payload };

    case "UPDATE_USER_PREFERENCE":
      const updateParam = action.payload.updateParam;
      const update = action.payload.update;

      if (updateParam === "email") {
        state.userPreference.email_subscribed = update;
      }

      if (updateParam === "notification") {
        state.userPreference.sms_notification = update;
      }

      return { ...state };
    case "SET_USER_INFO":
      return { ...state, userInfo: action.payload };

    case "SET_WINNING_PRIZE":
      return { ...state, winningPrize: action.payload };
    case "SET_JACKPOT_WINNING_PRIZE":
      return { ...state, jackpotWinningPrize: action.payload };
    case "SET_TICKETS":
      return { ...state, tickets: action.payload };
    case "SET_JACKPOT_TICKETS":
      return { ...state, jackpotTickets: action.payload };
    case "SET_CYCLE":
      return { ...state, cycle: action.payload };

    case "SET_REFERRAL_REWARDS_DETAILS":
      return { ...state, referral_rewards_details: action.payload };

    case "SET_POOL_INFO":
      return {
        ...state,
        poolInfo: action.payload,
      };

    case "SET_GAMEFI_SAVINGS":
      return {
        ...state,
        gamefiSavings: action.payload,
      };

    case "SET_GAMEFI_CURRENT_PRIZE":
      return {
        ...state,
        gamefiCurrentPrize: action.payload,
      };
    case "SET_JACKPOT_CURRENT_PRIZE":
      return {
        ...state,
        jackpotCurrentPrize: action.payload,
      };
    case "SET_JACKPOT_SAVINGS":
      return {
        ...state,
        jackpotSavings: action.payload,
      };
    case "SET_JACKPOT_POOL_INFO":
      return {
        ...state,
        jackpotPoolInfo: action.payload,
      };

    case "INCREASE_PRODUCT":
      const id = action.payload;
      const target = state.rewards?.find((rw) => rw?.id === id);
      const cost = target?.token_cost;
      if (state.cart[id] === undefined) {
        state.cart[id] = 1;
        state.cartCost[id] = 1 * cost;
        return { ...state, updateCart: !state.updateCart };
      } else {
        state.cart[id] += 1;
        state.cartCost[id] += cost;
        return { ...state, updateCart: !state.updateCart };
      }

    case "DECREASE_PRODUCT":
      const rId = action.payload;
      const rtarget = state.rewards?.find((rw) => rw?.id === rId);
      const rcost = rtarget?.token_cost;
      if (state.cart[rId] === undefined) {
        state.cart[rId] = 0;

        return { ...state, updateCart: !state.updateCart };
      } else {
        if (state.cart[rId] > 0) {
          state.cart[rId] -= 1;
          state.cartCost[rId] -= 1 * rcost;
          return { ...state, updateCart: !state.updateCart };
        }
      }

    case "SUM_COST":
      const costArray = Object.values(state.cartCost);
      const sum = costArray?.reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);

      return {
        ...state,
        totalCost: sum,
        totalAvailableToken: state.userInfo.available_token_point - sum,
      };

    case "CLEAR_CART":
      return {
        ...state,
        cart: {},
        totalCost: 0,
      };

    case "SET_ORDERS":
      return {
        ...state,
        orders: action.payload,
      };

    case "SET_ORDER":
      return {
        ...state,
        order: action.payload,
      };

    case "SET_JACKPOT_POINTS":
      return {
        ...state,
        jackpotPoints: action.payload,
      };

    case "SET_REWARDS":
      return {
        ...state,
        rewards: action.payload,
      };

    case "POOL_DATA_LOADING":
      return {
        ...state,
        poolDataLoading: !state.poolDataLoading,
      };
    case "JOIN_GAMEFI_POOL":
      return {
        ...state,
        joinGamefiPool: !state.joinGamefiPool,
      };
    case "JOIN_GAMEFI_POOL":
      return {
        ...state,
        joinJackpotPool: !state.joinJackpotPool,
      };
    case "START_POOL_INFO_LOADING":
      return {
        ...state,
        poolInfoLoading: true,
      };
    case "STOP_POOL_INFO_LOADING":
      return {
        ...state,
        poolInfoLoading: false,
      };

    case "START_JACKPOT_POOL_INFO_LOADING":
      return {
        ...state,
        jackpotPoolInfoLoading: true,
      };
    case "STOP_JACKPOT_POOL_INFO_LOADING":
      return {
        ...state,
        jackpotPoolInfoLoading: false,
      };
    case "PREVIOUS":
      return { ...state, rhPage: state.rhPage - 1 };

    case "NEXT":
      return {
        ...state,
        rhPage: state.rhPage + 1,
      };
    case "RESET":
      return {
        ...state,
        rhPage: 1,
      };

    case "SET_QUIZ_PAGE":
      return {
        ...state,
        quizPage: action.payload,
      };

    case "REFRESH_STATE":
      return {
        ...state,
        userProfile: {},
        userWalletData: {},
        userInfo: {},
        poolInfo: {},
        accountDetails: {},
        cart: {},
        totalCost: 0,
      };

    default:
      return state;
  }
}

export function ContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}
