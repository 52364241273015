import { useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography, Switch } from "@mui/material";
import NavLayout from "../NavLayout";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "../../helpers/axios";
import { Context } from "../../helpers/Context";

const Notification = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(Context);
  const { userPreference } = state;

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "#592E6D",
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: "#592E6D",
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            backgroundColor: "#592E6D",
            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              backgroundColor: "#592E6D",
            },
          },
        },
      },
    },
  });
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const checkEmailSubscribe = async (e) => {
    const email_subscribed = e.target.checked;
    dispatch({
      type: "UPDATE_USER_PREFERENCE",
      payload: { updateParam: "email", update: e.target.checked },
    });
    try {
      await axios.put(
        "/user/preference/settings",
        {
          email_subscribed,
        },
        config
      );
    } catch (error) {
      console.log(error);
    }
  };

  // const checkSmsNotification = async (e) => {
  //   const sms_notification = e.target.checked;
  //   dispatch({
  //     type: "UPDATE_USER_PREFERENCE",
  //     payload: { updateParam: "notification", update: e.target.checked },
  //   });
  //   try {
  //     await axios.put(
  //       "/user/preference/settings",
  //       {
  //         sms_notification,
  //       },
  //       config
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <ThemeProvider theme={theme}>
      <NavLayout
        title="Notifications"
        content={
          <Box
            sx={{
              backgroundColor: "#F5F5F5",
              minHeight: "100vh",
              maxWidth: "500px",
              margin: "0 auto",
              boxSizing: "border-box",
              padding: "0 20px",
            }}
          >
            <ArrowBackIos
              onClick={() => navigate(-1)}
              sx={{
                fontSize: "16px",
                cursor: "pointer",
                marginTop: "40px",
              }}
            />
            <Typography
              sx={{
                color: "#592E6D",
                fontSize: "24px",
                fontWeight: "600",
                marginTop: "30px",
              }}
            >
              Notifications
            </Typography>

            <Box
              sx={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                }}
              >
                Email
              </Typography>
              <Box>
                <Switch
                  checked={userPreference?.email_subscribed}
                  onChange={checkEmailSubscribe}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                }}
              >
                SMS
              </Typography>
              <Box>
                <Switch checked={false} />
              </Box>
            </Box>
          </Box>
        }
      />
    </ThemeProvider>
  );
};

export default Notification;
