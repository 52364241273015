import { useContext } from "react";
import {
  Box,
  Typography,
  TextField,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";
import { Context } from "../../helpers/Context";
import { useLGAs, useStates, useUpdateDetails } from "../../helpers/Hooks/Api";
import { formstyles } from "../../helpers/Styles";

const ChangeAddress = () => {
  useStates();
  useLGAs();
  const { state, dispatch } = useContext(Context);
  const { states, LGAs } = state;

  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});

  const { update, serverError } = useUpdateDetails();

  return (
    <NavLayout
      title="Update Address"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#fff",
            minHeight: "100vh",
            margin: "0 auto",
            paddingTop: "1px",
            paddingBottom: "4rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              src={"/assets/images/close.png"}
              alt=""
              onClick={() => navigate(-1)}
              className=" absolute left-[14px] cursor-pointer"
            />

            <Typography className=" text-brandPurp text-[1.125rem] font-AR">
              Update Address
            </Typography>
          </Box>

          <Box width={"100%"} margin={"0px auto"} px={"20px"}>
            <Box
              sx={{
                marginTop: "40px",
              }}
            ></Box>

            <div className=" text-center text-brandPurp text-[0.875rem] max-w-[338px] mt-[40px] mb-[50px] m-[0_auto]">
              Please input an address where you can receive
              claimed reward items.
            </div>
            <form
              onSubmit={handleSubmit((data) =>
                update(
                  {
                    phone: "",
                    user_image: "",
                    bvn: "",
                    ...data,
                  },
                  "Address updated successfully"
                )
              )}
            >
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "0px 0 0px 3px",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  label="ADDRESS"
                  type={"text"}
                  name="address"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={formstyles}
                  {...register("address", {
                    minLength: {
                      value: "2",
                      message: "first name must be minimum of 2 characters",
                    },
                  })}
                />
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.address?.message}
                </Typography>
              </Box>

              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "0px 0 0px 3px",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  label="BUS-STOP"
                  type={"text"}
                  name="bus_stop"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={formstyles}
                  {...register("bus_stop", {
                    minLength: {
                      value: "2",
                      message: "middle name must be minimum of 2 characters",
                    },
                  })}
                />
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.bus_stop?.message}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "0px 0 0px 3px",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  label="NEAREST LANDMARK"
                  type={"text"}
                  name="nearest_landmark"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={formstyles}
                  {...register("land_mark_area", {
                    minLength: {
                      value: "2",
                      message: "last name must be minimum of 2 characters",
                    },
                  })}
                />
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.nearest_landmark?.message}
                </Typography>
              </Box>

              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "0px 0 0px 3px",
                  marginBottom: "20px",
                }}
              >
                <Autocomplete
                  variant="standard"
                  disablePortal
                  id="combo-box-demo"
                  options={states}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <MenuItem
                      key={option?.state_code}
                      value={option?.name}
                      {...props}
                    >
                      <Typography className=" text-brandPurp text-[0.875rem]">
                        {option?.name}
                      </Typography>
                    </MenuItem>
                  )}
                  onInputChange={(e, newState) =>
                    dispatch({
                      type: "SET_USER_STATE",
                      payload: newState,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      label="STATE"
                      variant="standard"
                      sx={formstyles}
                      {...params}
                      {...register("state", {
                        required: "State name is required",
                      })}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.state?.message}
                </Typography>
              </Box>

              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "3px 0 3px 3px",
                }}
              >
                <Autocomplete
                  variant="standard"
                  disablePortal
                  id="combo-box-demo"
                  options={LGAs}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <MenuItem
                      key={option?.name}
                      value={option?.name}
                      {...props}
                    >
                      <Typography className=" text-brandPurp text-[0.875rem]">
                        {option?.name}
                      </Typography>
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      label="LOCAL GOVERNMENT AREA"
                      variant="standard"
                      sx={formstyles}
                      {...params}
                      {...register("local_government_area", {
                        required: "Local government area is required",
                      })}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.lga?.message}
                </Typography>
              </Box>

              <Typography variant="caption">{serverError}</Typography>

              <Box margin="50px auto" width="100%">
                <button
                  type="submit"
                  className=" rounded-[5px] bg-brandPurp w-full h-[56px] text-white text-[0.75rem] font-SF font-[600]"
                >
                  Save
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      }
    />
  );
};

export default ChangeAddress;
