import { Box, Modal, Typography } from "@mui/material";
import NavLayout from "../NavLayout";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useCheckOrders } from "../../helpers/Hooks/Api";
import { Oval } from "react-loader-spinner";

const RewardsMenu = () => {
  const navigate = useNavigate();
  const { ordersLoading, ordersAvailable, setOrdersAvailable, fetchOrders } =
    useCheckOrders();

  const MenuCard = ({ name, onClick }) => {
    return (
      <div
        onClick={onClick}
        className="flex justify-between items-center mb-[25px] text-black text-[0.875rem] font-AR cursor-pointer"
      >
        {name} <img src="/assets/images/forward.png" />
      </div>
    );
  };
  return (
    <NavLayout
      title="Rewards-Menu"
      content={
        <Box
          maxWidth={"500px"}
          sx={{ margin: "0 auto", paddingBottom: "5rem" }}
        >
          <Box
            sx={{
              padding: "24px 20px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                marginBottom: "10px",
                position: "relative",
              }}
            >
              <ArrowBackIos
                sx={{
                  fontSize: "16px",
                  cursor: "pointer",
                  position: "absolute",
                  left: "20px",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              />
              <Typography variant="body1" className=" text-center">
                Rewards
              </Typography>
            </Box>
            <div className=" bg-white h-[707px] rounded-[10px] mt-[21px] p-[79px_20px]">
              <MenuCard
                name="Rewards and League"
                onClick={() => navigate("/rewards")}
              />
              <MenuCard name="Track Reward" onClick={fetchOrders} />
              {ordersLoading && (
                <div className="mt-[50px] w-full flex items-center justify-center">
                  <Oval
                    height={80}
                    width={80}
                    color="#290349"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#290349"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              )}

              <Modal
                open={!ordersAvailable}
                onClose={() => setOrdersAvailable(false)}
                className="flex items-center"
              >
                <section
                  className={`w-[24.2574rem] m-[0_auto] px-[10px] py-[50px] transition-transform duration-700 ease-in-out bg-[#fff] shadow-[0px_11px_15px_0px_rgba(0,0,0,0.20),0px_9px_46px_0px_rgba(0,0,0,0.12),0px_24px_38px_0px_rgba(0, 0, 0, 0.14)] rounded-[4px] flex flex-col items-center`}
                >
                  <img
                    src="/assets/images/noReward.png"
                    alt="no reward"
                    className="mt-[20px] m-[0_auto]"
                  />

                  <p className="text-[0.75rem] text-brandPurp text-center font-AR font-[700] mt-[20px]">
                    Oops! You don't have any order at the moment
                  </p>
                </section>
              </Modal>
            </div>
          </Box>
        </Box>
      }
    />
  );
};

export default RewardsMenu;
