import { useState, useContext } from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { Box, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MyRewards from "./MyRewards";
import { Context } from "../../helpers/Context";
import Leaderboard from "./Leaderboard";
import { theme } from "../../helpers/MuiThemes";

const Rewards = () => {
  const navigate = useNavigate();
  const [pageNum, setPageNum] = useState(1);
  const { state } = useContext(Context);
  const { userInfo } = state;

  return (
    <Box maxWidth={"500px"} sx={{ margin: "0 auto", paddingBottom: "5rem" }}>
      <Box>
        <Box
          sx={{
            padding: "24px 20px",
            borderBottom: "0.1px solid #FFFFFF80",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <ArrowBackIos
              sx={{
                fontSize: "16px",
                cursor: "pointer",
                position: "absolute",
                left: "0",
              }}
              onClick={() => {
                navigate(-1);
              }}
            />
            <Typography variant="body1">Rewards</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: "0 20px", color: "#fff", position: "relative" }}>
        <Box sx={{ margin: "10px 0" }}>
          <Typography variant="caption">Summary</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 18px",
            width: "100%",
            height: "120px",
            borderRadius: "10px",
            border: "0.2px solid #FF1CF7",
            background: "rgba(255, 255, 255, 0.20)",
            backgroundBlendMode: "overlay",
            backdropFilter: "blur(20px)",
          }}
        >
          <Box
            sx={{ paddingRight: "18px", borderRight: "0.2px solid #FF1CF7" }}
          >
            <img src="/assets/images/cash.png" alt="" />
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "200",
                lineHeight: "18px",
                letterSpacing: "-0.078px",
                marginBottom: "16px",
              }}
            >
              Token Balance
            </Typography>
            <Box
              sx={{ display: "flex", columnGap: "6px", alignItems: "center" }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: "28px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                  letterSpacing: "-0.24px",
                }}
              >
                {userInfo?.available_token_point}
              </Typography>{" "}
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "200",
                  lineHeight: "18px",
                  letterSpacing: "-0.078px",
                }}
              >
                OC Tokens
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              rowGap: "9px",
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "200",
                lineHeight: "18px",
                letterSpacing: "-0.078px",
              }}
            >
              Rank
            </Typography>

            <ThemeProvider theme={theme}>
              <Tooltip title={userInfo?.name_rank}>
                <img
                  src={`/assets/images/avatars/${userInfo?.name_rank}.png`}
                  alt=""
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                />
              </Tooltip>
            </ThemeProvider>
          </Box>
        </Box>
        <Box
          sx={{
            background: "#fff",
            padding: "20px",
            color: "#fff",
            marginTop: "15px",
            border: "0.5px solid #FF1CF7",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "10px 10px 0px 0px",
            overflow: "auto",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              className="Tab"
              style={{
                flex: 1,
                padding: " 8px 12px",
                color: "#290349",
                borderBottom: pageNum === 1 ? "2px solid #FF1CF7" : "none",
                fontWeight: "700",
              }}
            >
              <Typography
                variant="body2"
                textAlign={"center"}
                sx={{ fontWeight: "600", cursor: "pointer" }}
                onClick={() => setPageNum(1)}
              >
                My Rewards
              </Typography>
            </Box>
            <Box
              className="Tab"
              style={{
                padding: "8px 12px",
                color: "#290349",
                flex: 1,
                borderBottom: pageNum === 2 ? "2px solid #FF1CF7" : "none",
              }}
            >
              <Typography
                variant="body2"
                textAlign={"center"}
                onClick={() => setPageNum(2)}
                sx={{ fontWeight: "600", cursor: "pointer" }}
              >
                League
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: "30px", paddingBottom: "2rem" }}>
            {pageNum === 1 && <MyRewards />}
            {pageNum === 2 && <Leaderboard />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Rewards;
