import { useContext, useEffect, useState } from "react";
import axios, { axiosNoApi, axiosNoBase } from "../axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Audio, Oval } from "react-loader-spinner";
import { Context } from "../Context";
import { useGoogleLogin } from "@react-oauth/google";
import { useLogout } from "./logout";

export const useValidateReferralCode = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();
  const validateReferralCode = async () => {
    await axios
      .get(`/user/referral/?code=${code}`)
      .then(function (response) {
        if (response.data.results[0]?.code === code) {
          dispatch({
            type: "SET_REFERRAL_CODE",
            payload: response.data.results[0]?.code.toString(),
          });

          navigate("/signup");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (code) {
      validateReferralCode();
    }
  }, [code]);
};

export const useUserAuth = () => {
  const [googleUserInfo, setGoogleUserInfo] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const { state } = useContext(Context);
  const { referral_code } = state;

  const authWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const googleUser = await axiosNoBase
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);
      setGoogleUserInfo(googleUser);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (googleUserInfo) {
      const data = {
        email: googleUserInfo?.email,
        first_name: googleUserInfo?.given_name,
        last_name: googleUserInfo?.family_name,
        client_id:
          "940199083503-p467hampdagv85tudst5m1m8sea2u4if.apps.googleusercontent.com",
        referral_code: referral_code,
      };
      axiosNoApi
        .post("/social_auth/v3/google", data)
        .then((res) => {
          localStorage.setItem("token", res.data.access);
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log(err.response);

          if (err.response.data.non_field_errors) {
            setErrorMsg(err.response.data.non_field_errors[0]);
          }
        });
    }
  }, [googleUserInfo]);

  const login = async (data) => {
    setErrorMsg(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#fff"
        ariaLabel="three-dots-loading"
      />
    );
    axios
      .post("/token/obtain", data)
      .then(function (response) {
        localStorage.setItem("token", response?.data?.access);
        navigate("/dashboard");
      })
      .catch(function (error) {
        if (error.code === "ERR_NETWORK") {
          setErrorMsg("No internet Connection");
        }
        if (error?.response?.data?.detail) {
          setErrorMsg(error.response.data.detail);
        }
      });
  };

  const signup = async (userData, handleClose) => {
    delete userData.confirmPassword;
    if (userData.referral_code === "") {
      delete userData.referral_code;
    }
    handleClose();
    setErrorMsg(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#fff"
        ariaLabel="three-dots-loading"
      />
    );

    await axios
      .post("/signup", userData)
      .then(function (response) {
        localStorage.setItem("ref", response?.data?.uidb);
        setTimeout(navigate("/signup/verify"), 5000);
      })
      .catch(function (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setErrorMsg("No internet Connection");
        }
        if (error.code === "ERR_BAD_REQUEST") {
          setErrorMsg(error?.response?.data?.message);
          localStorage.setItem("ref", error?.response?.data?.uidb);
          setTimeout(navigate("/signup/verify"), 5000);
        }
        if (error?.response?.data?.email)
          setErrorMsg(error?.response?.data?.email[0]);
      });
  };

  return { authWithGoogle, signup, login, errorMsg, setErrorMsg };
};

export const useStates = () => {
  const { dispatch } = useContext(Context);
  const getStates = async () => {
    try {
      const response = await axiosNoBase.get(
        "https://nigeria-states-towns-lga.onrender.com/api/states"
      );
      dispatch({ type: "SET_STATES", payload: response?.data });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStates();
  }, []);
};

export const useBanks = () => {
  const { dispatch } = useContext(Context);
  const { logout } = useLogout();
  const fetchBanks = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .get("/available-banks", config)
      .then(function (response) {
        dispatch({
          type: "SET_BANKS",
          payload: Object.keys(response?.data?.data?.banks),
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.data.code === "token_not_valid") {
          logout();
        }
      });
  };
  useEffect(() => {
    fetchBanks();
  }, []);
};

export const useUserBanks = () => {
  const { state, dispatch } = useContext(Context);
  const { userBanks } = state;
  const [isLoading, setIsloading] = useState(false);
  const { logout } = useLogout();

  const fetchUserBanks = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setIsloading(true);
    await axios
      .get("/wallet/bank", config)
      .then(function (response) {
        setIsloading(false);
        dispatch({ type: "SET_USER_BANKS", payload: response?.data });
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.code === "token_not_valid") {
          logout();
        }
      });
  };
  useEffect(() => {
    if (userBanks?.length === 0) {
      fetchUserBanks();
    }
  }, []);

  return { isLoading, fetchUserBanks };
};

export const useAccountDetails = () => {
  const { state, dispatch } = useContext(Context);
  const { accountDetails } = state;
  const { logout } = useLogout();

  const fetchAccountDetails = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .get("/wallet/v2/deposit", config)
      .then(function (response) {
        dispatch({ type: "SET_NO_BANK_DETAILS", payload: false });
        dispatch({ type: "SET_ACCOUNT_DETAILS", payload: response?.data });
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          logout();
        }
        if (error?.response?.status === 400) {
          dispatch({ type: "SET_NO_BANK_DETAILS", payload: true });
        }
      });
  };
  useEffect(() => {
    if (accountDetails?.name === undefined) {
      fetchAccountDetails();
    }
  }, []);
};

export const useAddBank = (setOpenForm) => {
  const [serverError, setServerError] = useState("");
  const { logout } = useLogout();
  const { fetchUserBanks } = useUserBanks();
  const addBank = async (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setServerError(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#592E6D"
        ariaLabel="three-dots-loading"
      />
    );
    await axios
      .post("/wallet/fill-bank", data, config)
      .then(function (response) {
        if (response?.data?.message) {
          setServerError(response?.data?.message);
        }

        fetchUserBanks();
        setTimeout(() => setOpenForm(false), "5000");
      })
      .catch(function (error) {
        setServerError(error?.response?.data?.message);
        if (error?.response?.status === 401) {
          logout();
        }
        if (error?.response?.data?.account_number) {
          setServerError(error?.response?.data?.account_number[0]);
        }
        console.log(error);
      });
  };

  return { serverError, addBank };
};

export const useLGAs = () => {
  const { state, dispatch } = useContext(Context);
  const { userState } = state;
  const getLGAs = async () => {
    try {
      const response = await axiosNoBase.get(
        `https://nigeria-states-towns-lga.onrender.com/api/${userState}/lgas`
      );
      dispatch({ type: "SET_LGAS", payload: response?.data });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userState !== "") {
      getLGAs();
    }
  }, [userState]);
};

export const useSettings = (successMsg) => {
  const [serverError, setServerError] = useState("");
  const navigate = useNavigate();
  const update = async (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setServerError(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#592E6D"
        ariaLabel="three-dots-loading"
      />
    );
    await axios
      .put("/user/update", data, config)
      .then(function (response) {
        setServerError(successMsg);
        setTimeout(() => {
          navigate("/settings");
        }, "3000");
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.data) {
          setServerError(error.response.data[0]);
        }
      });
  };

  return { update, serverError };
};

export const useUpdateDetails = () => {
  const [serverError, setServerError] = useState("");
  const navigate = useNavigate();

  const uploadImage = async (data) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const formData = new FormData();
    formData.append("user_image", data.file[0]);

    setServerError(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#592E6D"
        ariaLabel="three-dots-loading"
      />
    );
    await axios
      .put("/wallet/update", formData, config)
      .then(function (response) {
        setServerError("Image uploaded successfully");
        setTimeout(() => {
          navigate("/settings");
        }, "3000");
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.data) {
          setServerError(error.response.data[0]);
        }
        if (error?.response?.status === 400) {
          setServerError(error.response.data.user_image);
        }
        if (error?.response?.status === 500) {
          setServerError(
            "There was an error communitcating with the server please try again."
          );
        }
      });
  };

  const update = async (data, successMsg) => {
    const formData = new FormData();
    formData.append("phone", data.phone);
    formData.append("user_image", data.user_image);
    formData.append("address", data.address);
    formData.append("land_mark_area", data.land_mark_area);
    formData.append("state", data.state);
    formData.append("local_government_area", data.local_government_area);
    formData.append("bvn", data.bvn);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    setServerError(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#592E6D"
        ariaLabel="three-dots-loading"
      />
    );
    await axios
      .put("/wallet/update", formData, config)
      .then(function (response) {
        setServerError(successMsg);
        setTimeout(() => {
          navigate("/settings");
        }, "3000");
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.data) {
          setServerError(error.response.data[0]);
        }
        if (error?.response?.status === 400) {
          setServerError(error.response.data.user_image);
        }
        if (error?.response?.status === 500) {
          setServerError(
            "There was an error communitcating with the server please try again."
          );
        }
      });
  };

  return { uploadImage, serverError, update };
};

export const usePoolInfo = () => {
  const { dispatch } = useContext(Context);
  const poolData = async () => {
    try {
      dispatch({ type: "START_POOL_INFO_LOADING" });
      const response = await axios.get("/savings-pool/date/data");
      dispatch({ type: "SET_POOL_INFO", payload: response?.data?.data });
      localStorage.setItem("poolInfo", JSON.stringify(response?.data?.data));
      dispatch({ type: "STOP_POOL_INFO_LOADING" });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    poolData();
  }, []);
};

export const useBVNUpdate = () => {
  const [BVNStatus, setBVNStatus] = useState("");
  const [serverError, setServerError] = useState("");
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const token = localStorage.getItem("token");
  const { logout } = useLogout();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const checkBVN = async (bvn) => {
    setBVNStatus(
      <Oval
        height={15}
        width={15}
        color="#290349"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#fff"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    );
    setShouldUpdate(false);
    try {
      const response = await axios.post("/validateBVN", bvn, config);
      if (response?.status === 200) {
        setBVNStatus(
          <div className=" text-[#25CF43] text-[0.625rem] font-AR flex items-center gap-x-[9px] mt-[5px]">
            {" "}
            Correct details! <img src="/assets/images/correct.png" alt="" />
          </div>
        );
        setShouldUpdate(true);
      }
    } catch (error) {
      setBVNStatus(
        <div className=" text-[#F00] text-[0.625rem] font-AR mt-[5px]">
          The details you entered are invalid!
        </div>
      );
      setShouldUpdate(false);
      if (error?.response?.status === 401) {
        logout();
      }
      console.log(error);
    }
  };

  const updateBVN = async (bvn) => {
    setServerError(
      <Oval
        height={15}
        width={15}
        color="#290349"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#fff"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    );
    try {
      const response = await axios.post("/updateBVN", bvn, config);
      if (response?.status === 200) {
        setServerError("BVN updated successfully");
      }
    } catch (error) {
      setServerError("Something went wrong!");
      if (error?.response?.status === 401) {
        logout();
      }
      console.log(error);
    }
  };

  return {
    BVNStatus,
    checkBVN,
    serverError,
    setServerError,
    updateBVN,
    shouldUpdate,
  };
};

export const useGamefiSavings = () => {
  const { dispatch, state } = useContext(Context);
  const { joinGamefiPool } = state;
  const { logout } = useLogout();

  const getSavings = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get("/mysavings", config);
      dispatch({ type: "SET_GAMEFI_SAVINGS", payload: response?.data });
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        logout();
      }
    }
  };

  useEffect(() => {
    getSavings();
  }, [joinGamefiPool]);
};

export const useJackpotPoolInfo = () => {
  const { state, dispatch } = useContext(Context);
  const { joinJackpotPool } = state;
  const poolData = async () => {
    try {
      dispatch({ type: "START_JACKPOT_POOL_INFO_LOADING" });
      const response = await axios.get("/jackpot-savings/pool-raffle-date");
      dispatch({
        type: "SET_JACKPOT_POOL_INFO",
        payload: response?.data?.data,
      });
      localStorage.setItem(
        "jackpotPoolInfo",
        JSON.stringify(response?.data?.data)
      );
      dispatch({ type: "STOP_JACKPOT_POOL_INFO_LOADING" });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    poolData();
  }, [joinJackpotPool]);
};

export const useUserInfo = () => {
  const { logout } = useLogout();

  const { dispatch, state } = useContext(Context);
  const { joinGamefiPool, joinJackpotPool } = state;
  useEffect(() => {
    const getUserInfo = async () => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .get("/user/points/detail", config)
        .then(function (response) {
          dispatch({
            type: "SET_USER_INFO",
            payload: response?.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            logout();
          }
        });
    };
    getUserInfo();
  }, [joinGamefiPool, joinJackpotPool]);
};

export const useReferralInfo = () => {
  const { logout } = useLogout();
  const { state, dispatch } = useContext(Context);
  const { refetch_referral_details } = state;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchReferralDetails = async () => {
    await axios
      .get("/user/referrer/details", config)
      .then(function (response) {
        dispatch({ type: "SET_REFERRAL_DETAILS", payload: response?.data });
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          logout();
        }
      });
  };

  const fetchReferralLeaders = async () => {
    await axios
      .get("/referrals/ranking", config)
      .then(function (response) {
        dispatch({
          type: "SET_REFERRAL_REWARDS_DETAILS",
          payload: response?.data,
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          logout();
        }
      });
  };

  useEffect(() => {
    fetchReferralDetails();
    fetchReferralLeaders();
  }, [refetch_referral_details]);
};

export const useRewardLeague = () => {
  const { logout } = useLogout();
  const { dispatch } = useContext(Context);
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchRewardLeague = async () => {
    await axios
      .get("/user/ranking/leadership-board", config)
      .then(function (response) {
        dispatch({ type: "SET_REWARDS_DETAILS", payload: response?.data });
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 401) {
          logout();
        }
      });
  };

  useEffect(() => {
    fetchRewardLeague();
  }, []);
};

export const useWinningInfo = () => {
  const { dispatch } = useContext(Context);
  useEffect(() => {
    const getWinningInfo = async () => {
      await axios
        .get("/winning-pool/last-month")
        .then(function (response) {
          dispatch({ type: "SET_WINNING_PRIZE", payload: response?.data });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    getWinningInfo();
  }, []);
};

export const useJackpotWinningInfo = () => {
  const { dispatch } = useContext(Context);
  useEffect(() => {
    const getWinningInfo = async () => {
      await axios
        .get("/jackpot-savings/amount")
        .then(function (response) {
          dispatch({
            type: "SET_JACKPOT_WINNING_PRIZE",
            payload: response?.data?.data?.amount_winnable,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    getWinningInfo();
  }, []);
};

export const useGamefiTicketData = () => {
  const { dispatch, state } = useContext(Context);
  const { joinGamefiPool } = state;
  useEffect(() => {
    const getTicketData = async () => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .get("/current-tickets", config)
        .then(function (response) {
          dispatch({ type: "SET_TICKETS", payload: response?.data });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    getTicketData();
  }, [joinGamefiPool]);
};

export const useGamefiCurrentPrize = () => {
  const { dispatch } = useContext(Context);
  useEffect(() => {
    const ws = new WebSocket("wss://api.onecap.africa/socket/winning-amount");
    ws.onmessage = (message) => {
      dispatch({
        type: "SET_GAMEFI_CURRENT_PRIZE",
        payload: JSON.parse(message.data),
      });
    };

    return () => {
      ws.close();
    };
  }, []);
};

export const useJackpotCurrentPrize = () => {
  const { dispatch } = useContext(Context);
  useEffect(() => {
    const ws = new WebSocket(
      "wss://api.onecap.africa/socket/jackpot/winning-amount"
    );
    ws.onmessage = (message) => {
      dispatch({
        type: "SET_JACKPOT_CURRENT_PRIZE",
        payload: JSON.parse(message.data),
      });
    };

    return () => {
      ws.close();
    };
  }, []);
};

export const useJackpotTicketData = () => {
  const { state, dispatch } = useContext(Context);
  const { joinJackpotPool } = state;
  useEffect(() => {
    const getTicketData = async () => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .get(
          `/jackpot-tickets?user_id=${localStorage.getItem("current_user")}`,
          config
        )
        .then(function (response) {
          dispatch({
            type: "SET_JACKPOT_TICKETS",
            payload: response?.data?.results,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    getTicketData();
  }, [joinJackpotPool]);
};

export const useJackpotSavings = () => {
  const { state, dispatch } = useContext(Context);
  const { joinJackpotPool } = state;
  useEffect(() => {
    const getJackpotSavings = async () => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .get(
          `/jackpot-savings?user_id=${localStorage.getItem("current_user")}`,
          config
        )
        .then(function (response) {
          dispatch({
            type: "SET_JACKPOT_SAVINGS",
            payload: response?.data?.results,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    getJackpotSavings();
  }, [joinJackpotPool]);
};

export const useJackpotPoints = () => {
  const { state, dispatch } = useContext(Context);
  const { joinJackpotPool } = state;
  useEffect(() => {
    const getJackpotPoints = async () => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .get("/jackpot-savings/total-rewards", config)
        .then(function (response) {
          if (response.status === 204) {
            dispatch({
              type: "SET_JACKPOT_POINTS",
              payload: "0",
            });
          } else {
            dispatch({
              type: "SET_JACKPOT_POINTS",
              payload: response?.data?.accumulated_gains,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    getJackpotPoints();
  }, [joinJackpotPool]);
};

export const useCycleData = () => {
  const { dispatch } = useContext(Context);
  useEffect(() => {
    const getCycleData = async () => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .get("/pool-cycle/data", config)
        .then(function (response) {
          dispatch({
            type: "SET_CYCLE",
            payload: response?.data?.pool_cycle_number,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    getCycleData();
  }, []);
};

export const useUserWalletData = () => {
  const { dispatch, state } = useContext(Context);
  const { joinGamefiPool, joinJackpotPool } = state;
  const { logout } = useLogout();
  useEffect(() => {
    const getUserWalletData = async () => {
      const token = localStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .get("/wallet/detail", config)
        .then(function (response) {
          dispatch({ type: "SET_USER_WALLET_DATA", payload: response?.data });
          localStorage.setItem("current_user", response?.data?.id);
        })
        .catch(function (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            logout();
          }
        });
    };
    getUserWalletData();
  }, [joinGamefiPool, joinJackpotPool]);
};

export const useUserProfile = (handleOpen) => {
  const { dispatch } = useContext(Context);
  const { logout } = useLogout();
  useEffect(() => {
    const getUserProfile = async () => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios
        .get("/profile", config)
        .then(function (response) {
          dispatch({ type: "SET_USER_PROFILE", payload: response?.data });

          if (
            response.data.kyc_level === 1 &&
            typeof handleOpen === "function"
          ) {
            handleOpen();
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            logout();
          }
        });
    };
    getUserProfile();
  }, []);
};

export const useUserPreference = () => {
  const { dispatch } = useContext(Context);
  useEffect(() => {
    const getUserPreference = async () => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get("/user/preference/settings", config);
      dispatch({ type: "SET_USER_PREFERENCE", payload: data });
    };
    getUserPreference();
  }, []);
};

export const useRewardItems = () => {
  const [isRewardAvailable, setIsRewardAvailable] = useState(true);
  const { logout } = useLogout();
  const { dispatch } = useContext(Context);

  useEffect(() => {
    const getRewardItems = async () => {
      await axiosNoApi
        .get("/rewards/v1/items")
        .then(function (response) {
          dispatch({ type: "SET_REWARDS", payload: response?.data?.data });
        })
        .catch(function (error) {
          console.log(error);
          if (error?.response?.status === 204) {
            setIsRewardAvailable(false);
          }

          if (error?.response?.status === 401) {
            logout();
          }
        });
    };
    getRewardItems();
  }, []);

  return { isRewardAvailable };
};

export const useCheckOut = () => {
  const [checkOutLoading, setCheckOutLoading] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isError, setIsError] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const { dispatch } = useContext(Context);
  const { logout } = useLogout();
  const checkOut = async (cart) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = JSON.stringify({ item_and_quantity: cart });

    setCheckOutLoading(true);
    setIsError(false);
    await axiosNoApi
      .post("/rewards/v1/auth/order", data, config)
      .then(function (response) {
        dispatch({ type: "CLEAR_CART" });
        setFeedback(response?.data?.status);
      })
      .catch(function (error) {
        setIsError(true);
        setFeedback(error?.response?.data?.error);
        if (error?.response?.status === 401) {
          logout();
        }
        console.log(error);
      })
      .finally(() => {
        setIsCompleted(true);
        setCheckOutLoading(false);
      });
  };
  return {
    checkOut,
    checkOutLoading,
    feedback,
    isError,
    isCompleted,
    setIsCompleted,
  };
};

export const useCheckOrders = () => {
  const { dispatch } = useContext(Context);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [ordersAvailable, setOrdersAvailable] = useState(true);
  const navigate = useNavigate();
  const { logout } = useLogout();

  const fetchOrders = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setOrdersLoading(true);
    await axiosNoApi
      .get("/rewards/v1/auth/orders", config)
      .then(function (response) {
        dispatch({ type: "SET_ORDERS", payload: response?.data?.data });

        setOrdersLoading(false);
        navigate("/track-rewards");
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 204) {
          setOrdersAvailable(false);
        }
        if (error?.response?.status === 401) {
          logout();
        }
      });
  };

  return { ordersLoading, ordersAvailable, setOrdersAvailable, fetchOrders };
};

export const useOrders = () => {
  const { logout } = useLogout();
  const { dispatch } = useContext(Context);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [ordersAvailable, setOrdersAvailable] = useState(true);

  const fetchOrders = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setOrdersLoading(true);
    await axiosNoApi
      .get("/rewards/v1/auth/orders", config)
      .then(function (response) {
        dispatch({ type: "SET_ORDERS", payload: response?.data?.data });
        setOrdersLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 204) {
          setOrdersAvailable(false);
        }
        if (error?.response?.status === 401) {
          logout();
        }
      });
  };
  useEffect(() => {
    fetchOrders();
  }, []);

  return { ordersLoading, ordersAvailable, fetchOrders };
};

export const useOrder = () => {
  const { logout } = useLogout();
  const { dispatch } = useContext(Context);
  const [orderLoading, setOrdersLoading] = useState(false);

  const fetchOrder = async () => {
    const token = localStorage.getItem("token");
    const orderId = localStorage.getItem("orderId");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setOrdersLoading(true);
    await axiosNoApi
      .get(`/rewards/v1/auth/order/${orderId}`, config)
      .then(function (response) {
        dispatch({ type: "SET_ORDER", payload: response?.data });
        setOrdersLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 204) {
        }
        if (error?.response?.status === 401) {
          logout();
        }
      });
  };
  useEffect(() => {
    fetchOrder();
  }, []);

  return { orderLoading };
};

export const useRedeem = () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [redeemError, setRedeemError] = useState("");
  const { state, dispatch } = useContext(Context);
  const { referral_details } = state;
  const { logout } = useLogout();

  const redeemTokens = async () => {
    const points = referral_details?.available_point;
    setRedeemError(
      <Oval
        height={15}
        width={15}
        color="#290349"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#fff"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    );
    await axios
      .post("/user/referral/convert-point", { points }, config)
      .then((response) => {
        setRedeemError(response?.data?.message);
        dispatch({ type: "REFETCH_REFERRAL_DETAILS" });
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          console.log(error);
          setRedeemError(
            error?.response?.data[0] || error?.response?.data?.points[0]
          );
        }
        if (error?.response?.status === 401) {
          logout();
        }
      });
  };

  return { redeemTokens, redeemError, setRedeemError };
};

export const useQuizBoardData = () => {
  const { dispatch } = useContext(Context);
  const [quizBoardLoading, setQuizBoardLoading] = useState(false);
  const { logout } = useLogout();
  useEffect(() => {
    const getQuizBoardData = async () => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setQuizBoardLoading(true);
      await axios
        .get(`/quiz/board/${localStorage.getItem("currentQuizId")}`, config)
        .then(function (response) {
          setQuizBoardLoading(false);
          dispatch({
            type: "SET_QUIZ_BOARD_DATA",
            payload: response?.data?.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          if (error?.response?.status === 401) {
            logout();
          }
        });
    };
    getQuizBoardData();
  }, []);

  return quizBoardLoading;
};

export const useJoinPool = () => {
  const { logout } = useLogout();
  const [joinPoolError, setJoinPoolError] = useState("");
  const { dispatch } = useContext(Context);

  const joinGamefiPool = async (amount_saved, setModal) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setJoinPoolError(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#592E6D"
        ariaLabel="three-dots-loading"
      />
    );
    await axios
      .post("/join-savings", amount_saved, config)
      .then(function () {
        dispatch({ type: "JOIN_GAMEFI_POOL" });
        setModal(2);

        setTimeout(() => {
          setJoinPoolError("");
          setModal(1);
        }, 5000);
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 400) {
          setJoinPoolError(error?.response?.data?.amount_saved[0]);
        }
        if (error?.response?.data?.detail) {
          setJoinPoolError(error?.response?.data?.detail);
        }
        if (error?.response?.status === 406) {
          setJoinPoolError("Insufficient funds");
        }
        if (error?.response?.status === 401) {
          setJoinPoolError("session expired");
          if (error?.response?.status === 401) {
            logout();
          }
        }
        setModal(3);
      });
  };

  const joinJackpotPool = async (data, setModal) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setJoinPoolError(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#592E6D"
        ariaLabel="three-dots-loading"
      />
    );
    await axios
      .post("/join-jackpot", data, config)
      .then(function () {
        dispatch({ type: "JOIN_JACKPOT_POOL" });
        setModal(2);

        setTimeout(() => {
          setJoinPoolError("");
          setModal(1);
        }, 5000);
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.status === 400) {
          setJoinPoolError(error?.response?.data?.amount[0]);
        }
        if (error?.response?.data?.detail) {
          setJoinPoolError(error?.response?.data?.detail);
        }
        if (error?.response?.status === 406) {
          setJoinPoolError("Insufficient funds");
        }
        if (error?.response?.status === 401) {
          setJoinPoolError("session expired");
          if (error?.response?.status === 401) {
            logout();
          }
        }
        setModal(3);
      });
  };

  return { joinGamefiPool, joinJackpotPool, joinPoolError };
};
