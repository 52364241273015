import { AiOutlineClose } from "react-icons/ai";

import { Container, Box, Typography, Button, TextField } from "@mui/material";
import axios from "../helpers/axios";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { formstyles } from "./formstyles";
import { useState } from "react";
import { Audio } from "react-loader-spinner";

function Reset() {
  // const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setErrorMsg(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#fff"
        ariaLabel="three-dots-loading"
      />
    );
    axios
      .post("/forgot-password", data)
      .then(function (response) {
        setErrorMsg(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setErrorMsg("No internet Connection");
        }
        if (error.response.status === 400) {
          setErrorMsg("User with email doesn't exist");
        }
        if (error?.response?.data?.detail) {
          setErrorMsg(error.response.data.detail);
        }
      });
  };
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #290349 0%, #592E6D 100%)",

        margin: "0px",
        boxSizing: "border-box",
        height: "100vh",
        paddingTop: "1px",
      }}
    >
      <Link to="/">
        <AiOutlineClose
          color="#fff"
          fontSize={"24px"}
          style={{ position: "fixed", right: 20, top: 20, cursor: "pointer" }}
        />
      </Link>
      <Box
        sx={{
          maxWidth: "600px",
          height: "100%",
          margin: "0 auto",
          background: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container sx={{ maxWidth: "600px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
              <Typography
                variant="h6"
                sx={{ color: "#fff", fontWeight: "600" }}
              >
                Forgot password?
              </Typography>
              <Typography variant="body2" sx={{ color: "#fff" }}>
                Enter your email below, you will receive an email with
                instructions on how to reset your password in a few minutes.
              </Typography>
            </Box>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                color: "#fff",
                mt: "20px",
              }}
            >
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #fff",
                  padding: "3px 0 3px 3px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  type={"email"}
                  label="EMAIL"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={formstyles}
                  name="email"
                  {...register("email")}
                  error={errors.email?.message}
                />
                <Box></Box>
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.email?.message}
                </Typography>
              </Box>

              {errorMsg}
            </Box>
            <Box mt={"40px"} sx={{ display: "flex", flexDirection: "column" }}>
              <Button
                type="submit"
                sx={{
                  background: "#a852d2",
                  ":hover": {
                    bgcolor: "#a852dd",
                  },
                  color: "#fff",

                  height: "45px",
                  textTransform: "none",
                }}
              >
                <Typography variant="body2">Continue</Typography>
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Box>
  );
}

export default Reset;
