import { useContext, useState } from "react";
import { Context } from "../../helpers/Context";
import { Done, FilterList, RadioButtonUnchecked } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";

const MyReferrals = () => {
  const { state } = useContext(Context);
  const { referral_details } = state;
  const [referralId, setReferralId] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [remindFeedback, setRemindFeedback] = useState("");
  const token = localStorage.getItem("token");
  const iconStyle = {
    fontSize: "15px",
    marginRight: "2px",
    fontWeight: "800",
    color: "green",
  };

  const iconNotActive = {
    fontSize: "15px",
    marginRight: "2px",
    color: "#290349",
  };

  const iconText = {
    display: "flex",
    alignItems: "base-line",
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const remindFriend = async (ping_url, id) => {
    setReferralId(id);
    await axios
      .get(`${ping_url}`, config)
      .then(function (response) {
        setRemindFeedback(response?.data?.message);
        setShowFeedback(true);
        setTimeout(() => {
          setShowFeedback(false);
        }, 5000);
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          setRemindFeedback(error?.response?.data);
          setShowFeedback(true);
          setTimeout(() => {
            setShowFeedback(false);
          }, 5000);
        }
        console.log(error);
      });
  };

  return (
    <>
      <Box
        sx={{
          padding: "20px 0",
          display: "flex",
          borderRadius: "5px",
          backgroundColor: "#E3C4FD",
          color: "#290349",
        }}
      >
        <Box
          sx={{
            border: "none",
            borderRight: "0.5px solid #FF1CF7",
            flex: "3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="" src="/assets/images/earnings.png" />
        </Box>

        <Box sx={{ paddingLeft: "40px", flex: "6" }}>
          <Typography
            variant="body2"
            fontWeight={200}
            marginBottom={0.8}
            paddingRight={0.5}
          >
            Increase earnings by 50%
          </Typography>
          <Typography variant="caption" lineHeight={1} paddingRight={0.5}>
            Increase referral earnings on each successful invite
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "0.5px solid #FF1CF7",
          color: "#290349",
          padding: "15px 0",
        }}
      >
        <Typography variant="body2">Referral Details</Typography> <FilterList />
      </Box>

      {referral_details?.referrals?.length < 1 && (
        <Box
          sx={{ padding: "15px 10px", textAlign: "center", color: "#290349" }}
        >
          <Typography variant="caption">
            You have not referred anyone yet.{" "}
          </Typography>
          <Typography variant="caption">
            Click on the share button to share your referral link with your
            friends
          </Typography>
        </Box>
      )}
      {referral_details.referrals?.map((referral) => {
        return (
          <Box
            key={referral.id}
            sx={{
              color: "#290349",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "15px 10px",
              borderBottom: "0.2px solid #FF1CF7",
            }}
          >
            <Typography variant="body2" fontWeight={800}>
              {referral?.first_name} {referral.last_name}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography variant="caption">Referral Status:</Typography>
              <Typography
                variant="caption"
                fontWeight={800}
                sx={{ marginLeft: "2px" }}
              >
                {referral.has_invested === true
                  ? "Successful"
                  : "No Investment"}
              </Typography>
            </Box>
            <Box
              sx={{
                color: "#290349",
                marginTop: "10px",
                display: "flex",
                gap: "20px",
              }}
            >
              <Box sx={iconText}>
                {referral.is_verified === true ? (
                  <Done sx={iconStyle} />
                ) : (
                  <RadioButtonUnchecked sx={iconNotActive} />
                )}
                <Typography variant="caption">Signed up</Typography>
              </Box>
              <Box sx={iconText}>
                {referral.profile_completed === true ? (
                  <Done sx={iconStyle} />
                ) : (
                  <RadioButtonUnchecked sx={iconNotActive} />
                )}
                <Typography variant="caption">Profile Complete</Typography>
              </Box>
              <Box sx={iconText}>
                {referral.has_invested === true ? (
                  <Done sx={iconStyle} />
                ) : (
                  <RadioButtonUnchecked sx={iconNotActive} />
                )}
                <Typography variant="caption">Invested</Typography>
              </Box>
            </Box>

            {referral.has_invested === true ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <img
                  alt=""
                  src="/assets/images/reward.png"
                  style={{ marginRight: "15px" }}
                />
                <Typography variant="caption" fontWeight={800}>
                  You've got a reward
                </Typography>
              </Box>
            ) : (
              <>
                <Button
                  sx={{
                    textTransform: "none",
                    width: "100%",
                    marginTop: "10px",
                    padding: "8px 0",
                    background: "#290349",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#290349b7",
                    },
                  }}
                  onClick={() => remindFriend(referral.ping_url, referral.id)}
                >
                  <Typography variant="caption" fontWeight={800}>
                    Remind {referral?.first_name}
                  </Typography>
                </Button>
                <Box sx={{ textAlign: "center" }}>
                  {showFeedback && referralId === referral.id && (
                    <Typography variant="caption" color={"red"}>
                      {remindFeedback}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default MyReferrals;
