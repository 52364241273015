import React, { useState } from "react";
import Navigation from "./Navigation";
import Accord from "./Accord";

const FAQ = () => {
  const [arr, setArr] = useState([]);
  const handleClick = (id) => {
    if (arr?.includes(id)) {
      const filteredArray = arr?.filter((ar) => ar !== id);
      setArr(filteredArray);
    } else {
      setArr((ar) => [...ar, id]);
    }
  };
  return (
    <>
      <Navigation />
      <div className=" w-full px-[7%] md:px-[13.8%] pb-[50px] mt-[20px]">
        <h2 className=" text-[2.5rem] text-black font-SF font-[500]">
          Frequently Asked Questions (FAQ)
        </h2>

        <div className="w-full mt-[28px] flex flex-col gap-y-[24px]">
          <Accord
            id="1"
            idArray={arr}
            heading="What is OneCap?"
            text={
              <p>
                OneCap is a prize-linked and reward-based savings platform.
                OneCap introduces an innovative gamified approach to savings,
                allowing you to take control of your financial future with an
                engaging and rewarding experience.
              </p>
            }
            click={() => handleClick("1")}
          />
          <Accord
            id="2"
            idArray={arr}
            heading="What are OneCap tokens?"
            text={
              <p>
                OneCap tokens are digital in-app currency that can be earned and
                spent within the OneCap platform.
              </p>
            }
            click={() => handleClick("2")}
          />
          <Accord
            id="3"
            idArray={arr}
            heading="How can I earn OneCap tokens?"
            text={
              <p>
                You can earn OneCap tokens by participating in various
                activities, such as joining savings pools, inviting friends, and
                taking part in the weekly quiz, among other engaging activities
              </p>
            }
            click={() => handleClick("3")}
          />
          <Accord
            id="4"
            idArray={arr}
            heading="What can I do with OneCap tokens?"
            text={
              <p>
                You can redeem your OneCap tokens earned from referring friends
                for cash rewards. Every 100 tokens earned will be automatically
                converted to N1000. Additionally, OneCap tokens earned from
                various activities, such as joining a savings pool or
                participating in the weekly quiz, can be used to claim different
                rewards in the "Claim Reward" section on the Rewards page.
              </p>
            }
            click={() => handleClick("4")}
          />

          <Accord
            id="5"
            idArray={arr}
            heading="How does the referral program work?"
            text={
              <p>
                When you refer your friends, you earn 10 OneCap tokens. Your
                OneCap tokens will be converted to cash after you accumulate 100
                OneCap tokens. 100 OC tokens earns you N1,000
              </p>
            }
            click={() => handleClick("5")}
          />

          <Accord
            id="6"
            idArray={arr}
            heading="How does the referral program work?"
            text={
              <p>
                When you refer your friends, you earn 10 OneCap tokens. Your
                OneCap tokens will be converted to cash after you accumulate 100
                OneCap tokens. 100 OC tokens earns you N1,000
              </p>
            }
            click={() => handleClick("6")}
          />

          <Accord
            id="7"
            idArray={arr}
            heading="Do I have to join a pool to qualify for prize rewards?"
            text={
              <p>
                Yes, to be eligible for prize rewards, you need to join either
                the Naira-Gamified Savings Pool or the Ultimate Jackpot Savings
                Pool. However, you can choose to participate in both pools
                simultaneously.
              </p>
            }
            click={() => handleClick("7")}
          />

          <Accord
            id="8"
            idArray={arr}
            heading="What is the process for selecting winners?"
            text={
              <p>
                We use a sophisticated algorithm to randomly select winning
                tickets from the pool, ensuring a fair and unbiased selection
                process.
              </p>
            }
            click={() => handleClick("8")}
          />

          <Accord
            id="9"
            idArray={arr}
            heading="How does the Naira-Gamified Savings Pool work?"
            text={
              <p>
                The Naira-Gamified Savings Pool is designed to reward all its
                participants. You can join the pool with a minimum deposit of
                N2,000. For every N2,000 deposited, you receive one ticket. For
                example, if you deposit N4,000, you will receive two tickets,
                increasing your chances of winning. Currently, the pool cycle
                runs for 30 days, with the raffle draw conducted at the end of
                the month.
              </p>
            }
            click={() => handleClick("9")}
          />

          <Accord
            id="10"
            idArray={arr}
            heading="How does the Ultimate Jackpot Pool work?"
            text={
              <p>
                The Ultimate Jackpot Pool is a winner-take-all pool where one
                ticket user emerges as the sole winner of the pool prize. To
                participate, you can deposit a minimum of N1,000, and for every
                N1,000 deposited, you receive one ticket. For instance, if you
                deposit N5,000, you will receive five tickets, increasing your
                chances of winning. Currently, the pool cycle runs for 30 days,
                with weekly raffle draws held every Friday.
              </p>
            }
            click={() => handleClick("10")}
          />

          <Accord
            id="11"
            idArray={arr}
            heading="Which pool should I join?"
            text={
              <p>
                The choice between the Naira-Gamified Savings Pool and the
                Ultimate Jackpot Savings Pool depends on your preferences. If
                you prefer a conservative approach with rewards for all
                participants, the Naira-Gamified Savings Pool is suitable.
                However, if you seek higher stakes and enjoy the thrill of
                competition, the Ultimate Jackpot Savings Pool is a great
                choice. You can also choose to participate in both pools
                simultaneously.
              </p>
            }
            click={() => handleClick("11")}
          />

          <Accord
            id="12"
            idArray={arr}
            heading="What is the difference between the pools?"
            text={
              <p>
                The Ultimate Jackpot Pool awards the entire pool prize to a
                single winner in weekly raffle draws. In contrast, the
                Naira-Gamified Savings Pool distributes rewards to all
                participants, with winners earning additional rewards. The
                Ultimate Jackpot Pool conducts its draw weekly, while the
                Naira-Gamified Savings Pool conducts the draw at the end of each
                month.
              </p>
            }
            click={() => handleClick("12")}
          />

          <Accord
            id="13"
            idArray={arr}
            heading="Does OneCap manage the money?"
            text={
              <p>
                No, OneCap does not manage the funds directly. However, we have
                partnered with trusted, licensed, and credible financial
                institutions to safeguard and manage the funds.
              </p>
            }
            click={() => handleClick("13")}
          />

          <Accord
            id="14"
            idArray={arr}
            heading="Are my savings deposits safe?"
            text={
              <p>
                Yes, reputable and licensed financial institutions securely
                manage all funds, ensuring the safety of your savings deposits.
              </p>
            }
            click={() => handleClick("14")}
          />

          <Accord
            id="15"
            idArray={arr}
            heading="Can I withdraw my money at any time?"
            text={
              <p>
                You have the flexibility to withdraw your rewards and funds at
                any time. However, if you are part of a savings pool, you will
                need to wait until the end of the pool cycle before you can
                withdraw your funds.
              </p>
            }
            click={() => handleClick("15")}
          />

          <p>
            We hope this FAQ provides clear and concise answers to your
            questions about OneCap. If you have any further inquiries, feel free
            to reach out to us. Start your journey to financial empowerment with
            OneCap today!
          </p>
        </div>
      </div>
    </>
  );
};

export default FAQ;
