import { Box, Card, Paper, Typography } from "@mui/material";
import axios from "../../helpers/axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos, MoreHoriz } from "@mui/icons-material/";
import NavLayout from "../NavLayout";
import { Oval } from "react-loader-spinner";

function WithdrawalHistory() {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const navigate = useNavigate();
  const [funds, setFunds] = useState(0);
  const [walletId, setWalletId] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const fetchData = async () => {
    await axios
      .get("/wallet/detail", config)
      .then(function (response) {
        setFunds(response?.data?.funds);
        setWalletId(response?.data?.wallet_id);
      })
      .catch(function (error) {
        console.log(error);
        navigate("/signin");
      });
  };
  const fetchTransactions = async () => {
    setIsloading(true);
    await axios
      .get("/withdrawal-history", config)
      .then(function (response) {
        setIsloading(false);
        const investmentData = response?.data;
        setTransactions(investmentData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
    fetchTransactions();
  }, []);
  return (
    <NavLayout
      title="Withdrawal History"
      content={
        <Box maxWidth={"500px"} sx={{ margin: "0 auto" }}>
          <Box>
            <Box
              sx={{
                padding: "24px 20px",
                borderBottom: "0.1px solid #FFFFFF80",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "16px",
                    cursor: "pointer",
                    position: "absolute",
                    left: "0",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <Box textAlign={"center"} width="100%">
                  <Typography variant="body1">Withdrawal History</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ padding: "24px 20px" }}>
              <Card
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  border: "0.01px solid rgba(255,28,247,0.4)",
                  padding: "20px",
                  color: "#fff",
                  borderRadius: "10px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="caption" sx={{ fontWeight: "200" }}>
                    Wallet ID
                  </Typography>
                  <Typography variant="caption" sx={{ fontWeight: "200" }}>
                    Available Funds
                  </Typography>
                </Box>
                <Box
                  mt={""}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="caption" sx={{ fontWeight: "500" }}>
                    {walletId}
                  </Typography>
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    <span style={{ fontSize: "16px" }}>N</span>
                    {parseFloat(funds)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                </Box>
                <Box mt={"30px"}>
                  <Box sx={{ display: "flex", gap: "6px" }}>
                    <Typography variant="caption" sx={{ fontWeight: "200" }}>
                      Status:
                    </Typography>
                    <Typography variant="caption" sx={{ fontWeight: "500" }}>
                      Active
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>
          <Box sx={{ padding: "1px 20px", pb: "60px", background: "#fff" }}>
            <Paper
              sx={{
                marginTop: "-10px",
                border: "0.5px solid #FF1CF7",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                height: "500px",
                overflow: "scroll",
                padding: "20px",
              }}
            >
              {isLoading ? (
                <Box
                  h={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Oval
                    height={80}
                    width={80}
                    color="#290349"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#592e6d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </Box>
              ) : transactions.length > 0 ? (
                transactions.map((transaction, index) => {
                  return (
                    <Box
                      key={transaction.id}
                      sx={{
                        background: "#fff",
                        border: "0.01px solid rgba(255,28,247,0.4)",
                        borderLeft: "0",
                        borderRight: "0",
                        cursor: "pointer",
                        color: "#592E6D",
                        padding: "16px 16px 0",
                      }}
                      onClick={() => navigate(`/wallet/${transaction.ref}`)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "12px", fontWeight: "600" }}
                        >
                          Withdrawal
                        </Typography>
                        <Typography
                          sx={{ fontSize: "12px", fontWeight: "600" }}
                        >
                          {transaction.time_created.slice(0, 10)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          my: "4px",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          N
                          {transaction.amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "red",
                          }}
                        >
                          -
                          {parseFloat(transaction.amount)
                            .toFixed(1)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="caption">
                          {transaction.status === 3 ? "Verified" : "Pending"}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "12px", fontWeight: "300" }}
                        >
                          <MoreHoriz />
                        </Typography>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Box>No active withdrawal</Box>
              )}
            </Paper>
          </Box>
        </Box>
      }
    />
  );
}

export default WithdrawalHistory;
