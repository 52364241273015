import { useContext } from "react";
import { FilterList } from "@mui/icons-material";
import { theme } from "../../helpers/MuiThemes";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import { Context } from "../../helpers/Context";

const Leaderboard = () => {
  const { state } = useContext(Context);
  const { rewards_details } = state;

  const tableData = rewards_details?.map((detail, index) => (
    <TableRow key={index}>
      <TableCell
        sx={{ textAlign: "center", borderBottom: "0.2px solid #FF1CF7" }}
      >
        {" "}
        {index + 1}
      </TableCell>
      <TableCell
        sx={{ textAlign: "center", borderBottom: "0.2px solid #FF1CF7" }}
      >
        {" "}
        {detail?.user?.first_name?.substring(0, 2)}
        <sup>***</sup>
      </TableCell>
      <TableCell
        sx={{ textAlign: "center", borderBottom: "0.2px solid #FF1CF7" }}
      >
        {" "}
        {detail?.user?.wallet_id?.substring(0, 5)}....
      </TableCell>
      <TableCell
        sx={{
          borderBottom: "0.2px solid #FF1CF7",
          cursor: "pointer",
        }}
      >
        <ThemeProvider theme={theme}>
          <Tooltip title={detail?.name_rank}>
            <img
              src={`/assets/images/avatars/${detail?.name_rank}.png`}
              style={{ height: "30px", width: "30px", borderRadius: "50%" }}
            />
          </Tooltip>
        </ThemeProvider>
      </TableCell>
      <TableCell
        sx={{ textAlign: "center", borderBottom: "0.2px solid #FF1CF7" }}
      >
        {" "}
        {detail?.total_token_point}
      </TableCell>
    </TableRow>
  ));
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "0.5px solid #FF1CF7",
          color: "#290349",
          padding: "15px 0",
        }}
      >
        <Typography variant="body2">League Details</Typography> <FilterList />
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ borderBottom: "0.2px solid #FF1CF7", color: "#290349" }}
            >
              S/N
            </TableCell>
            <TableCell
              sx={{ borderBottom: "0.2px solid #FF1CF7", color: "#290349" }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{ borderBottom: "0.2px solid #FF1CF7", color: "#290349" }}
            >
              Wallet ID
            </TableCell>
            <TableCell
              sx={{ borderBottom: "0.2px solid #FF1CF7", color: "#290349" }}
            >
              Avatar
            </TableCell>
            <TableCell
              sx={{ borderBottom: "0.2px solid #FF1CF7", color: "#290349" }}
            >
              Total Token
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableData}</TableBody>
      </Table>
    </Box>
  );
};

export default Leaderboard;
