import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";
import { useUpdateDetails } from "../../helpers/Hooks/Api";
import "react-phone-number-input/style.css";
import "./styles.css";
import PhoneInput from "react-phone-number-input";
import { useState } from "react";

const Changephone = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState();
  const {
    formState: { errors },
  } = useForm({});

  const { update, serverError } = useUpdateDetails();

  const updatePhone = () => {
    if (phone === undefined) {
      return;
    } else {
      update(
        {
          phone,
          address: null,
          user_image: "",
          land_mark_area: null,
          state: null,
          local_government_area: null,
          bvn: "",
        },
        "Phone number updated successfully"
      );
    }
  };

  return (
    <NavLayout
      title="Update Full Name"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#fff",
            minHeight: "100vh",
            margin: "0 auto",
            paddingTop: "1px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              src={"/assets/images/close.png"}
              alt=""
              onClick={() => navigate(-1)}
              className=" absolute left-[14px] cursor-pointer"
            />

            <Typography className=" text-brandPurp text-[1.125rem] font-AR">
              Update Phone Number
            </Typography>
          </Box>

          <Box width={"100%"} px={"20px"} margin={"0px auto"}>
            <Box
              sx={{
                marginTop: "40px",
              }}
            ></Box>

            <div className=" text-center text-brandPurp text-[0.875rem] max-w-[338px] mt-[58px] mb-[65px] m-[0_auto]">
              Update your contact information
            </div>
            <div>
              <PhoneInput
                placeholder="PHONE NUMBER"
                defaultCountry="NG"
                value={phone}
                onChange={setPhone}
              />

              <Typography variant="caption" className="mt-[20px]">
                {serverError}
              </Typography>

              <Box margin="100px auto" width="100%">
                <button
                  onClick={updatePhone}
                  className=" rounded-[5px] bg-brandPurp w-full h-[56px] text-white text-[0.75rem] font-SF font-[600]"
                >
                  Save
                </button>
              </Box>
            </div>
          </Box>
        </Box>
      }
    />
  );
};

export default Changephone;
