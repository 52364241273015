import { useContext } from "react";
import { FilterList } from "@mui/icons-material";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import { Context } from "../../helpers/Context";
const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "rgba(89, 46, 109, 0.8)",
        },
      },
    },
  },
});
const ReferralLeaderBoard = () => {
  const { state } = useContext(Context);
  const { referral_rewards_details } = state;
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "0.5px solid #FF1CF7",
          color: "#290349",
          padding: "15px 0",
        }}
      >
        <Typography variant="body2">League Details</Typography>{" "}
        <FilterList />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ borderBottom: "0.2px solid #FF1CF7", color: "#290349" }}
            >
              S/N
            </TableCell>
            <TableCell
              sx={{ borderBottom: "0.2px solid #FF1CF7", color: "#290349" }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{ borderBottom: "0.2px solid #FF1CF7", color: "#290349" }}
            >
              Wallet ID
            </TableCell>
            <TableCell
              sx={{ borderBottom: "0.2px solid #FF1CF7", color: "#290349" }}
            >
              Avatar
            </TableCell>
            <TableCell
              sx={{ borderBottom: "0.2px solid #FF1CF7", color: "#290349" }}
            >
              Total Token
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {referral_rewards_details.length > 0
            ? referral_rewards_details?.map((detail, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderBottom: "0.2px solid #FF1CF7",
                    }}
                  >
                    {" "}
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderBottom: "0.2px solid #FF1CF7",
                    }}
                  >
                    {" "}
                    {detail?.referrer?.first_name?.substring(0, 2)}
                    <sup>***</sup>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderBottom: "0.2px solid #FF1CF7",
                    }}
                  >
                    {" "}
                    {detail?.referrer?.wallet_id?.substring(0, 5)}....
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "0.2px solid #FF1CF7",
                      cursor: "pointer",
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <Tooltip title={detail?.rank}>
                        <img
                          src={`/assets/images/avatars/${detail?.rank}.png`}
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "50%",
                          }}
                        />
                      </Tooltip>
                    </ThemeProvider>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderBottom: "0.2px solid #FF1CF7",
                    }}
                  >
                    {" "}
                    {detail?.total_point_earned}
                  </TableCell>
                </TableRow>
              ))
            : ""}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ReferralLeaderBoard;
