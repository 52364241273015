import { useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClickOutCP } from "../helpers/Hooks/ClickOut";
import { Context } from "../helpers/Context";
import { useScrollToTop } from "../helpers/Hooks/ScrollTo";

const Navigation = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [openFeature, setOpenFeature] = useState(false);
  const featureRef = useRef();
  const subFeatureRef = useRef();
  const navRef = useRef();

  ClickOutCP(setOpenFeature, openFeature, featureRef, subFeatureRef);
  const location = useLocation();
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);

  const handleNavigate = (nodeName) => {
    if (location.pathname !== "/") {
      navigate("/");

      dispatch({
        type: "SET_NODE_REF",
        payload: {
          nodeName,
          time: new Date().getSeconds(),
        },
      });
    } else {
      dispatch({
        type: "SET_NODE_REF",
        payload: {
          nodeName,
          time: new Date().getSeconds(),
        },
      });
    }
  };

  useScrollToTop(navRef);

  return (
    <>
      <nav
        ref={navRef}
        className=" w-full h-[100px] bg-[#fff] flex items-center justify-between px-[24px] md:px-[24px] lg:px-[13.8%] "
      >
        <div className=" items-center gap-x-[2.25rem] hidden md:flex ">
          <img
            src="/assets/images/homeLogo.png"
            alt=""
            className=" cursor-pointer"
            onClick={() => navigate("/")}
          />
          <div className=" flex items-center gap-x-[1.5rem] font-SF text-brandPurp text-[1.125rem] font-[400]">
            <Link
              to={"/about-us"}
              className={`${
                location.pathname.split("/")[1] === "about-us" && "font-[700]"
              }`}
            >
              About Us
            </Link>
            <div
              ref={featureRef}
              className="relative cursor-pointer"
              onClick={() => setOpenFeature(!openFeature)}
            >
              Features
              <div
                ref={subFeatureRef}
                className={`${
                  openFeature ? "opacity-100" : "opacity-0 hidden"
                } transition-opacity duration-300 ease-in-out w-[18.0625rem] h-[18.3125rem] bg-white shadow-[4px_4px_4px_0px_rgba(255,255,255,0.50)] rounded-[0px_0px_10px_10px] flex flex-col gap-y-[18px] absolute top-[40px] p-[11px_24px] z-20`}
              >
                <p
                  onClick={() => handleNavigate("gRef")}
                  className=" text-[1rem] font-[400] font-SF text-[rgba(0,0,0,0.75)]"
                >
                  Naira Gamified Savings Pool
                </p>
                <p
                  onClick={() => handleNavigate("jRef")}
                  className=" text-[1rem] font-[400] font-SF text-[rgba(0,0,0,0.75)]"
                >
                  Ultimate Jackpot Savings Pool
                </p>
              </div>
            </div>
            <p
              onClick={() => handleNavigate("fRef")}
              className=" hover:font-[700]"
            >
              Contact
            </p>
          </div>
        </div>

        <div className="items-center gap-x-[1.125rem] hidden md:flex">
          <Link
            to={"/signin"}
            className=" p-0 border-none bg-[transparent] font-SF text-brandPurp text-[1.125rem] font-[700]"
          >
            Login
          </Link>
          <Link
            to={"/signup"}
            className=" w-[7.125rem] h-[3.125rem] rounded-[30px] text-white bg-brandPurp text-[1.125rem] font-SF font-[700] flex items-center justify-center"
          >
            Sign up
          </Link>
        </div>

        <img
          src="/assets/images/1cap.png"
          alt=""
          className="md:hidden cursor-pointer"
          onClick={() => navigate("/")}
        />
        <img
          src={`/assets/images/${mobileMenu ? "close" : "hamburger"}.png`}
          alt=""
          className="md:hidden w-[24px] h-[24px] cursor-pointer"
          onClick={() => setMobileMenu(!mobileMenu)}
        />
      </nav>

      <div
        className={` flex flex-col overflow-hidden md:hidden bg-brandPurp text-white items-center gap-y-[20px] transition-all duration-300 ease-in-out   ${
          mobileMenu ? "max-h-[240px] pt-[20px] pb-[25px]" : "max-h-0 py-0"
        }`}
      >
        <Link to={"/signin"}> Sign in</Link>
        <Link
          to={"/signup"}
          className=" w-full border-[1px] border-white rounded-[10px] text-center p-[8px_40px]"
        >
          {" "}
          Sign up
        </Link>
      </div>
    </>
  );
};

export default Navigation;
