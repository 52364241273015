import React from "react";

const RankCard = ({
  sn,
  name,
  date,
  time,
  timeUsed,
  point,
  rankId,
  userId,
}) => {
  return (
    <div
      className={` w-full p-[19px_0] ${
        sn === 1 && "border-b-[0.2px] border-b-[#ff1cf7]"
      }`}
    >
      <div
        className={`w-full flex items-center justify-between p-[3px_19px] ${
          rankId === userId &&
          " rounded-[20px] bg-[#d8b1d4] border-[1px] border-[#d8b1d4]"
        } `}
      >
        <span className="text-[0.65rem] text-brandPurp font-SF font-[300]">
          {sn}
        </span>
        <img src="/assets/images/user.png" alt="" />
        <span className="text-[0.65rem] text-brandPurp font-SF font-[700]">
          {name}
        </span>
        <span className="text-[0.65rem] text-brandPurp font-SF font-[300]">
          {date}
        </span>
        <span className="text-[0.65rem] text-brandPurp font-SF font-[300]">
          {time}
        </span>
        <span className="w-[50px] text-[0.65rem] text-brandPurp font-SF font-[300]">
          {timeUsed}
        </span>
        <span className="text-[0.65rem] text-brandPurp font-SF font-[700]">
          {point}pts
        </span>
      </div>
    </div>
  );
};

export default RankCard;
