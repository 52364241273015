import { yupResolver } from "@hookform/resolvers/yup";
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import axios from "../helpers/axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { formstyles, resetValidationSchema } from "./formstyles";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useState } from "react";
import { Audio } from "react-loader-spinner";

function ChangePassword() {
  const navigate = useNavigate();
  const uidb64 = localStorage.getItem("uidb64");
  const token = localStorage.getItem("resetToken");
  if (!uidb64 || !token) {
    navigate("/signin");
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetValidationSchema),
  });
  // const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = async (data) => {
    delete data.confirmPassword;
    data.uidb64 = uidb64;
    data.token = token;
    setErrorMsg(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#fff"
        ariaLabel="three-dots-loading"
      />
    );
    await axios
      .patch("/set-password", data)
      .then(function (response) {
        // setSuccessMsg("Password reset successful");
        localStorage.removeItem("uidb64");
        localStorage.removeItem("resetToken");
        setTimeout(() => navigate("/signin"), 5000);
      })
      .catch(function (error) {
        setErrorMsg("An error occured");
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setErrorMsg("No internet Connection");
        }
        if (error.response?.status === 400) setErrorMsg("Invalid credentials");
        if (error.response?.status === 401) {
          setErrorMsg(
            "The reset link is invalid. Redirecting to reset password page to generate a new link..."
          );
          setTimeout(() => {
            navigate("/reset-password");
          }, 5000);
        }
        if (error.response?.status === 500) setErrorMsg("Server Error");
      });
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Onecap | Reset Password</title>
        </Helmet>
      </HelmetProvider>
      <Box
        sx={{
          background: "linear-gradient(180deg, #290349 0%, #592E6D 100%)",

          margin: "0px",
          boxSizing: "border-box",
          height: "100vh",
          paddingTop: "60px",
        }}
      >
        <Box
          sx={{
            maxWidth: "600px",
            height: "100%",
            margin: "0 auto",
            background: "transparent",
            display: "flex",
          }}
        >
          <Container
            sx={{
              maxWidth: "600px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "#fff", fontWeight: "600" }}
              >
                Update Password
              </Typography>
              <Typography variant="body2" mt="20px" color="#fff">
                To ensure that account is well protected, please use 8 or more
                characters with a mix of letters, numbers & symbols.
              </Typography>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  color: "#fff",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "5px",
                    border: "0.5px solid #fff",
                    padding: "3px 0 3px 3px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="PASSWORD"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            sx={{ cursor: "pointer" }}
                            variant="caption"
                            pr={"5px"}
                            color={"#fff"}
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? "HIDE" : "SHOW"}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    sx={formstyles}
                    {...register("password")}
                    error={errors.password?.message}
                  />
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", paddingRight: "5px" }}
                    color="red"
                  >
                    {errors.password?.message}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderRadius: "5px",
                    border: "0.5px solid #fff",
                    padding: "3px 0 3px 3px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="CONFIRM PASSWORD"
                    name="confirmPassword"
                    type={"password"}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={formstyles}
                    {...register("confirmPassword")}
                    error={errors.confirmPassword?.message}
                  />
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", paddingRight: "5px" }}
                    color="red"
                  >
                    {errors.confirmPassword?.message}
                  </Typography>
                </Box>

                <Box>{errorMsg}</Box>
              </Box>
              <Box
                mt={"40px"}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  type="submit"
                  sx={{
                    background: "#a852d2",
                    ":hover": {
                      bgcolor: "#a852dd",
                    },
                    color: "#fff",
                    height: "45px",
                    textTransform: "none",
                  }}
                >
                  Continue
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default ChangePassword;
