import { useEffect, useState, useContext } from "react";

import NavLayout from "../NavLayout";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Snackbar } from "@mui/material";
import { requestForToken, onMessageListener } from "../../Auth/firebase";
import Timer from "../Timer";
import { Context } from "../../helpers/Context";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  useAccountDetails,
  useCycleData,
  useGamefiCurrentPrize,
  useJackpotCurrentPrize,
  useJackpotPoolInfo,
  useJackpotWinningInfo,
  usePoolInfo,
  useUserBanks,
  useUserInfo,
  useUserPreference,
  useUserWalletData,
  useWinningInfo,
} from "../../helpers/Hooks/Api";
import {
  getCorrectYear,
  getLastMonth,
  useWeek,
} from "../../helpers/Hooks/TimeFormat";

const Dashboard = () => {
  useWinningInfo();
  useJackpotWinningInfo();
  useUserInfo();
  useUserWalletData();
  useCycleData();
  usePoolInfo();
  useJackpotPoolInfo();
  useGamefiCurrentPrize();
  useJackpotCurrentPrize();
  useUserPreference();
  useAccountDetails();
  useUserBanks();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [allowPush, setAllowPush] = useState(true);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const { state } = useContext(Context);
  const { prizeWeek } = useWeek();
  const {
    poolInfo,
    jackpotPoolInfo,
    userWalletData,
    userInfo,
    winningPrize,
    jackpotWinningPrize,
    gamefiCurrentPrize,
    jackpotCurrentPrize,
  } = state;
  const [showId, setShowId] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const openPush = () => {
    setOpen(true);
  };

  const closePush = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const checkPermission = () => {
    if (localStorage.getItem("pushAllowed") === "true") {
      setAllowPush(true);
    } else {
      setAllowPush(false);
    }
  };

  useEffect(() => {
    checkPermission();
    if (notification?.title) {
      openPush();
    }
  }, []);
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closePush}
        title={notification.title}
        message={notification.body}
      />
      <NavLayout
        title="Dashboard"
        content={
          <div className=" bg-white max-w-[31.25rem] pb-[100px] m-[0_auto] relative">
            <div
              className={`w-full bg-[linear-gradient(180deg,#3D0158_0%,#3D0158_100%)] py-[10px] font-SF text-white text-[0.875rem] text-center flex items-center justify-center gap-x-[10px] absolute transition-opacity duration-500 ease-in-out ${
                allowPush ? "opacity-0" : "opacity-100 z-20"
              }`}
            >
              This app needs permisson to allow push notification
              <button
                disabled={allowPush}
                className={` bg-white text-brandPurp py-[5px] px-[8px]  rounded-[5px] cursor-pointer`}
                onClick={() => {
                  requestForToken();
                  localStorage.setItem("pushAllowed", "true");
                  setAllowPush(true);
                }}
              >
                Allow
              </button>
            </div>

            <div className="flex items-center justify-between pt-[17px] px-[18px]">
              <div className="flex items-center gap-x-[6px]">
                <Link to={"/settings"}>
                  <Avatar
                    src={userWalletData?.user_image}
                    sx={{ height: "3.125rem", width: "3.125rem" }}
                  />
                </Link>
                <span className=" font-EN font-[200] text-[1rem] text-[rgba(41,3,73,0.80)]">
                  Hello, {userWalletData?.user?.toString().split(" ")[0]}
                </span>
              </div>
              <div className="flex gap-x-[18.04px]">
                <img
                  src="/assets/images/blackStar.png"
                  alt=""
                  className=" cursor-pointer z-[1]"
                  onClick={() => navigate("/rewards")}
                />
                <img
                  src="/assets/images/blackBell.png"
                  alt=""
                  className=" cursor-pointer"
                />
              </div>
            </div>

            <div className="h-[0.2px] w-full bg-brandPurp mt-[22px] mb-[14px]"></div>

            <div className="w-full px-[18px] transition-all duration-700 ease-in-out">
              <div className=" font-SF font-[400] text-[0.875rem] text-[rgba(41,3,73,0.80)]">
                Accounts Overview
              </div>

              <div className=" w-full bg-brandPurp bg-bgDash bg-cover bg-center bg-no-repeat rounded-[10px] h-[140px] relative mt-[18px] px-[18px] pt-[24px]">
                <div className="rounded-[50%] flex items-center justify-center absolute left-[-10px] top-[-8px] pl-[10px] pt-[5px]">
                  <img
                    src="/assets/images/eyeOpen.png"
                    alt=""
                    className={`cursor-pointer transition-all duration-300 ease-in-out ${
                      showId ? "max-w-0 opacity-0" : "max-w-[24px] opacity-100"
                    }  h-[24px] text-[#290349]`}
                    onClick={() => setShowId(true)}
                  />
                  <img
                    src="/assets/images/eyeHide.png"
                    alt=""
                    className={`cursor-pointer transition-all duration-300 ease-in-out ${
                      showId ? "max-w-[24px] opacity-100" : "max-w-0 opacity-0"
                    }  h-[24px] text-[#290349]`}
                    onClick={() => setShowId(false)}
                  />
                </div>

                <div className="w-full flex items-center justify-between">
                  <span className=" font-[300] text-[rgba(255,255,255,0.90)] text-[0.875rem] font-SF ">
                    Wallet ID
                  </span>{" "}
                  <span className=" font-[300] text-[rgba(255,255,255,0.90)] text-[0.875rem] font-SF ">
                    Balance
                  </span>
                </div>
                <div className="w-full flex items-center justify-between">
                  <div className="relative h-[2rem]">
                    <span
                      className={`font-[500] text-[rgba(255,255,255,0.90)] text-[0.875rem] font-SF absolute top-0 `}
                    >
                      {userWalletData?.wallet_id}
                    </span>
                  </div>
                  <div className="relative h-[2rem]">
                    <div
                      className={`font-[500] text-[rgba(255,255,255,0.85)] text-[1.5rem] font-SF flex items-center absolute top-0 right-0 transition-opacity duration-700 ease-in-out ${
                        showId ? "opacity-0" : "opacity-100"
                      } `}
                    >
                      *****
                    </div>

                    <div
                      className={`font-[500] text-[rgba(255,255,255,0.85)] text-[0.875rem] font-SF flex items-center absolute top-[-2px] right-0 transition-opacity duration-700 ease-in-out ${
                        showId ? "opacity-100" : "opacity-0"
                      } `}
                    >
                      ₦
                      <span className=" text-[1.5rem] font-[400]">
                        {parseFloat(userWalletData?.funds)
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .
                      </span>
                      <span>
                        {userWalletData?.funds?.toString().split(".")[1] ===
                        undefined
                          ? "0"
                          : userWalletData?.funds
                              ?.toString()
                              .split(".")[1]
                              ?.slice(0, 2)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between">
                  <div>
                    <p className=" font-[300] text-[rgba(255,255,255,0.90)] text-[0.875rem] font-EN ">
                      Rewards
                    </p>
                    <p className=" font-[500] text-[rgba(255,255,255,0.90)] text-[0.875rem] font-SF ">
                      {userInfo?.available_token_point} OC Tokens
                    </p>
                  </div>
                  <button
                    className=" self-end border-[1px] border-[rgba(255,28,247,0.50)] rounded-[10px] bg-[#702F90] w-[9.5rem] h-[2rem] font-[400] text-white text-[0.875rem] font-SF"
                    onClick={() => {
                      navigate("/dashboard/wallet");
                    }}
                  >
                    Wallet
                  </button>
                </div>
              </div>

              <div className=" text-[0.875rem] text-brandPurp font-[400] font-SF space-[-0.078px] mt-[18px]">
                Quick Links
              </div>
              <div className=" flex items-center justify-evenly mt-[18px]">
                <div className=" flex flex-col items-center text-[0.75rem] text-[rgba(0,0,0,0.80)] font-[500] font-EN gap-y-[8px]">
                  <button
                    className="w-[4.5rem] h-[4.5rem] bg-[#702F90] rounded-[10px] flex items-center justify-center"
                    onClick={() => navigate("/jackpot-pool")}
                  >
                    <img src="/assets/images/jackpotIcon.png" alt="" />
                  </button>
                  Ultimate Jackpot
                </div>

                <div
                  className=" flex flex-col items-center text-[0.75rem] text-[rgba(0,0,0,0.80)] font-[500] font-EN gap-y-[8px] cursor-pointer"
                  onClick={() => navigate("/gamefi-pool")}
                >
                  <button className="w-[4.5rem] h-[4.5rem] bg-[#702F90] rounded-[10px] flex items-center justify-center">
                    <img src="/assets/images/gamfiIcon.png" alt="" />
                  </button>
                  Naira GSP
                </div>

                <div
                  className=" flex flex-col items-center text-[0.75rem] text-[rgba(0,0,0,0.80)] font-[500] font-EN gap-y-[8px]"
                  onClick={() => navigate("/quiz")}
                >
                  <button className="w-[4.5rem] h-[4.5rem] bg-[#702F90] rounded-[10px] flex items-center justify-center">
                    <img src="/assets/images/quizBook.png" alt="" />
                  </button>
                  Quiz
                </div>
              </div>
              <div className="mt-[24px] w-full h-[240px] rounded-[8px]">
                <Slider {...settings}>
                  <div className=" bg-[rgba(227,196,253,0.50)] w-full h-[240px] shadow-[0px_8px_24px_0px_rgba(0,0,0,0.15)] rounded-[10px] p-[18px]">
                    <div className=" text-[rgba(0,0,0,0.30)] text-[0.875rem] font-SF font-[800] space-[-0.078px]">
                      NAIRA GAMIFIED SAVINGS POOL DRAW
                    </div>

                    <div className="mt-[18px] w-full h-[72px] flex items-center gap-x-[9px]">
                      <div className="h-full w-[20.22%] flex items-center justify-center rounded-tl-[10px] rounded-bl-[10px] bg-[#702F90] shadow-[0px_4px_4px_#E3C4FD]">
                        <img src="/assets/images/jar.png" alt="" />
                      </div>

                      <div className="h-full w-[77.23%] shadow-[0px_8px_24px_0px_rgba(0,0,0,0.15)] rounded-tr-[10px] rounded-br-[10px]">
                        <div className="flex items-center justify-between py-[9px] px-[18px]">
                          <span className=" text-[0.75rem] text-[rgba(0,0,0,0.80)] font-SF font-[500] ">
                            Prize Amount
                          </span>
                          <span className=" text-[0.75rem] text-[rgba(0,0,0)] font-ENL">
                            {getLastMonth()}, {getCorrectYear()}
                          </span>
                        </div>

                        <div className=" flex w-full items-center justify-between px-[18px]">
                          <div className=" text-[1.5rem] font-[500]  bg-gradient-to-b  from-[#702F90] from-[0%] to-[#290349] to-[100%] bg-clip-text text-[transparent] ">
                            ₦
                            {winningPrize
                              ? parseFloat(winningPrize?.amount)
                                  .toFixed(0)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00"
                              : "0.00"}
                          </div>{" "}
                          <span className=" flex text-[0.75rem] text-black font-ENL items-center gap-x-[2px]">
                            {" "}
                            {gamefiCurrentPrize?.percentage_increase}%{" "}
                            <svg
                              width="16"
                              height="14"
                              viewBox="0 0 16 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={`${
                                !gamefiCurrentPrize?.is_bullish && "rotate-180"
                              }`}
                            >
                              <path
                                d="M8 0L15.7942 13.5H0.205771L8 0Z"
                                fill={
                                  gamefiCurrentPrize?.is_bullish
                                    ? "#4CAF50"
                                    : "#CD5C5C"
                                }
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-[13px] text-[0.75rem] text-[rgba(0,0,0,0.80)] text-center font-SF font-[500]">
                      Countdown to prize draw
                    </div>

                    <div className=" w-full flex justify-center">
                      <Timer
                        time={poolInfo?.raffle_draw_date}
                        location="gamfi"
                      />
                    </div>
                  </div>

                  <div className="bg-[rgba(255,28,247,0.15)] w-full h-[240px] shadow-[0px_8px_24px_0px_rgba(0,0,0,0.15)] rounded-[10px] p-[18px]">
                    <div className=" text-[rgba(0,0,0,0.30)] text-[0.875rem] font-SF font-[800] space-[-0.078px]">
                      ULTIMATE JACKPOT SAVINGS POOL DRAW
                    </div>

                    <div className="mt-[18px] w-full h-[72px] flex items-center gap-x-[9px]">
                      <div className="h-full w-[20.22%] flex items-center justify-center rounded-tl-[10px] rounded-bl-[10px] bg-[#962C81] shadow-[0px_4px_4px_#E3C4FD]">
                        <img src="/assets/images/jar.png" alt="" />
                      </div>

                      <div className="h-full w-[77.23%] shadow-[0px_8px_24px_0px_rgba(0,0,0,0.15)] rounded-tr-[10px] rounded-br-[10px]">
                        <div className="flex items-center justify-between py-[9px] px-[18px]">
                          <span className=" text-[0.75rem] text-[rgba(0,0,0,0.80)] font-SF font-[500] ">
                            Price Amount
                          </span>
                          <span className=" text-[0.75rem] text-[rgba(0,0,0)] font-ENL">
                            WEEK {prizeWeek}, {getCorrectYear()}
                          </span>
                        </div>

                        <div className=" flex w-full items-center justify-between px-[18px]">
                          <div className=" text-[1.5rem] font-[500]  bg-gradient-to-b  from-[#962C81] from-[0%] to-[#962C81] to-[100%] bg-clip-text text-[transparent] ">
                            ₦
                            {parseFloat(jackpotWinningPrize)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00"}
                          </div>{" "}
                          <span className=" flex text-[0.75rem] text-black font-ENL items-center gap-x-[2px]">
                            {" "}
                            {jackpotCurrentPrize?.percentage_increase}%{" "}
                            <svg
                              width="16"
                              height="14"
                              viewBox="0 0 16 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={`${
                                !jackpotCurrentPrize?.is_bullish && "rotate-180"
                              }`}
                            >
                              <path
                                d="M8 0L15.7942 13.5H0.205771L8 0Z"
                                fill={
                                  jackpotCurrentPrize?.is_bullish
                                    ? "#4CAF50"
                                    : "#CD5C5C"
                                }
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-[13px] text-[0.75rem] text-[rgba(0,0,0,0.80)] text-center font-SF font-[500]">
                      Countdown to prize draw
                    </div>

                    <div className=" w-full flex justify-center">
                      <Timer
                        time={jackpotPoolInfo?.raffle_draw_date}
                        location="jackpot"
                      />
                    </div>
                  </div>
                </Slider>
              </div>

              <div className=" text-[0.875rem] text-brandPurp font-[400] font-SF space-[-0.078px] mt-[18px]">
                Pools
              </div>
              <div
                className="mt-[13px] w-full p-[10px] md:p-[18px] flex items-center justify-center bg-[linear-gradient(180deg,#3D0158_0%,#3D0158_100%)] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] border-[#FF1CF7] border-[0.5px] rounded-[10px] cursor-pointer"
                onClick={() => navigate("/jackpot-pool")}
              >
                <div className="flex items-center justify-between gap-x-[14px]">
                  <img src="/assets/images/jackpotIcon.png" alt="" />
                  <div>
                    <p className=" text-[0.875rem] text-white font-SF font-[700] space-x-[-0.078px]">
                      Ultimate Jackpot Savings Pool
                    </p>
                    <div className="mt-[18px] text-[0.875rem] text-white font-SF font-[400] leading-[18px] max-w-[15.9rem]">
                      <span className=" font-[800] space-x-[-0.078px]">
                        Save NGN 1,000 & enter the weekly draw to multiply your
                        savings by 5X!
                      </span>
                    </div>
                  </div>
                  <img src="/assets/images/arrowForwardWhite.png" alt="" />
                </div>
              </div>

              <div
                className="mt-[20px] w-full p-[10px] md:p-[18px] flex items-center justify-center bg-[linear-gradient(180deg,#962C81_0%,#962C81_100%)] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] border-[#FF1CF7] border-[0.5px] rounded-[10px] cursor-pointer"
                onClick={() => navigate("/gamefi-pool")}
              >
                <div className="flex items-center justify-between gap-x-[14px]">
                  <img src="/assets/images/gamfiIcon.png" alt="" />
                  <div>
                    <p className=" text-[0.875rem] text-white font-SF font-[700] space-x-[-0.078px]">
                      Naira Gamified Savings Pool
                    </p>
                    <div className="mt-[18px] text-[0.875rem] text-white font-SF font-[400] leading-[18px] max-w-[15.9rem]">
                      <span className=" font-[800] space-x-[-0.078px]">
                        Save NGN 2,000 & enter the monthly draw to multiply your
                        savings by 2X!
                      </span>
                    </div>
                  </div>
                  <img src="/assets/images/arrowForwardWhite.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        }
      ></NavLayout>
    </>
  );
};

export default Dashboard;
