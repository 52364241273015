import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "rgba(89, 46, 109, 0.8)",
        },
      },
    },
  },
});
