import { Box, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material/";
import { useNavigate, useLocation } from "react-router-dom";
import { Oval } from "react-loader-spinner";

import axios from "../../helpers/axios";
import { useState, useEffect } from "react";
import NavLayout from "../NavLayout";

const ViewTransaction = () => {
  const [isLoading, setIsloading] = useState(true);
  const location = useLocation();
  const ref = location.pathname.split("/")[3];
  const token = localStorage.getItem("token");
  const [investment, setInvestment] = useState({});
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchDetail = async () => {
    await axios
      .get(`/transactions/${ref}`, config)
      .then(function (response) {
        setIsloading(false);
        setInvestment(response?.data);
        console.log(investment);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchDetail();
  }, []);
  const navigate = useNavigate();
  return (
    <NavLayout
      title="Savings Details"
      content={
        <Box maxWidth={"500px"} sx={{ margin: "0 auto" }}>
          <Box
            sx={{
              paddingBottom: "20px",
            }}
          >
            <Box
              sx={{
                padding: "24px 20px",
                borderBottom: "0.1px solid #FFFFFF80",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#fff",
                  marginBottom: "10px",
                }}
              >
                <ArrowBackIos
                  sx={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
                <Box textAlign={"center"} width="100%">
                  <Typography variant="body1">Savings Details</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ padding: "24px 20px" }}>
            <Box
              sx={{
                background: "#fff",
                height: "600px",
                overflow: "scroll",
                border: "0.01px solid rgba(255,28,247,0.4)",
                borderRadius: "10px",
                px: "10px",
                color: "#592E6D",
                mb: "50px",
              }}
            >
              {isLoading ? (
                <Box
                  h={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Oval
                    height={80}
                    width={80}
                    color="#290349"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#592e6d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </Box>
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "16px",
                    }}
                  >
                    <Typography></Typography>
                  </Box>
                  <InvestmentCard
                    title={"Amount Deposited"}
                    value={
                      "₦" +
                      investment.amount
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                  <InvestmentCard title={"Type"} value={investment.category} />
                  <InvestmentCard
                    title={"Date"}
                    value={investment.time_stamp.slice(0, 10)}
                  />
                  <InvestmentCard
                    title={"Remarks"}
                    value={investment.remarks}
                  />
                  <InvestmentCard
                    title={"Description"}
                    value={investment.description}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      }
    />
  );
};
const InvestmentCard = ({ title, value }) => {
  return (
    <Box
      display={"flex"}
      alignItems="center"
      justifyContent={"space-between"}
      borderBottom={"0.2px solid gray"}
      py={"10px"}
    >
      <Typography fontWeight={700} variant="caption">
        {title}
      </Typography>
      <Typography fontWeight={700} variant="caption">
        {value}
      </Typography>
    </Box>
  );
};

export default ViewTransaction;
