const OneCap = () => {
  return (
    <div
      id="onecap"
      className="w-full h-[811px] flex items-center justify-center bg-bg2Mobile md:bg-bg2 bg-no-repeat bg-cover bg-center bg-brandPurp"
    >
      <img src="/assets/images/OneCap.svg" alt="" />
    </div>
  );
};

export default OneCap;
