import { yupResolver } from "@hookform/resolvers/yup";
import { AiOutlineClose } from "react-icons/ai";
import { Container, Box, Typography, Button, TextField } from "@mui/material";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { formstyles, loginValidationSchema } from "./formstyles";
import { useUserAuth } from "../helpers/Hooks/Api";

function Signin() {
  const { authWithGoogle, login, errorMsg } = useUserAuth();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
  });

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Onecap | Sign in</title>
        </Helmet>
      </HelmetProvider>
      <Box
        sx={{
          background: "linear-gradient(180deg, #290349 0%, #592E6D 100%)",

          margin: "0px",
          boxSizing: "border-box",
          minHeight: "100vh",
          padding: "60px 0",
        }}
      >
        <Link to="/">
          <AiOutlineClose
            color="#fff"
            fontSize={"24px"}
            style={{ position: "fixed", right: 20, top: 20, cursor: "pointer" }}
          />
        </Link>
        <Box
          sx={{
            maxWidth: "600px",
            margin: "0 auto",
            background: "transparent",
            display: "flex",
          }}
        >
          <Container sx={{ maxWidth: "600px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",

                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: "600",
                  fontFamily: "SF Pro Text",
                  fontSize: "1.5rem",
                }}
              >
                Sign in
              </Typography>
              <Box sx={{ display: "flex", gap: "6px" }}>
                <Typography
                  sx={{
                    fontFamily: "SF Pro Text",
                    fontSize: "0.875rem",
                    fontWeight: "300",
                    color: "#fff",
                  }}
                >
                  New User?
                </Typography>
                <Link
                  to="/signup"
                  style={{
                    color: "#759EF0",
                    fontFamily: "SF Pro Text",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  }}
                >
                  Sign up
                </Link>
              </Box>
            </Box>

            <Box
              mt={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
                width: "100%",
              }}
            >
              <button
                style={{
                  width: "100%",
                  height: "56px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                  color: "#16181D",
                  borderRadius: "5px",
                  border: "none",
                  columnGap: "6px",
                  fontFamily: "SF Pro Text",
                  fontSize: "0.875rem",
                  fontWeight: "600",
                  letterSpacing: "-0.408px",
                  cursor: "pointer",
                }}
                onClick={() => authWithGoogle()}
              >
                <img src="/assets/images/google.png" alt="" />
                Sign in with Google
              </button>
            </Box>

            <Box mt={"40px"} mb={"40px"} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: "1.063rem",
                  fontFamily: "SF Pro Text",
                  fontWeight: "300",
                  color: "#B9B9B9",
                  letterSpacing: "-0.408px",
                }}
              >
                or
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(login)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  color: "#fff",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "5px",
                    border: "0.5px solid #fff",
                    padding: "3px 0 3px 3px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="EMAIL"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    sx={formstyles}
                    name="email"
                    {...register("email")}
                    error={errors.email?.message}
                  />

                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", paddingRight: "5px" }}
                    color="red"
                  >
                    {errors.email?.message}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderRadius: "5px",
                    border: "0.5px solid #fff",
                    padding: "3px 0 3px 3px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="PASSWORD"
                    type={"password"}
                    name="password"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    sx={formstyles}
                    {...register("password")}
                    error={errors.password?.message}
                  />
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", paddingRight: "5px" }}
                    color="red"
                  >
                    {errors.password?.message}
                  </Typography>
                </Box>
                <Box>{errorMsg}</Box>
                <Link to="/reset-password">
                  <Typography
                    style={{
                      color: "#00F0FF",
                      textAlign: "right",
                      cursor: "pointer",
                      fontSize: "0.75rem",
                      fontFamily: "SF Pro Text",
                      fontWeight: "600",
                    }}
                  >
                    Forgot password?
                  </Typography>
                </Link>
              </Box>
              <Box
                mt={"40px"}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  type="submit"
                  sx={{
                    background: "#a852d2",
                    ":hover": {
                      bgcolor: "#a852dd",
                    },
                    color: "#fff",
                    height: "56px",
                    textTransform: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "SF Pro Text",
                      fontSize: "0.875rem",
                      letterSpacing: "-0.32px",
                    }}
                  >
                    Sign In
                  </Typography>
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default Signin;
