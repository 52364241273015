import { useContext, useEffect } from "react";
import { Context } from "../Context";
import { useLocation } from "react-router-dom";

export const useScrollToSection = (gRef, jRef, fRef) => {
  const { state } = useContext(Context);
  const { nodeRef, nodeRefTime } = state;
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (nodeRef === "gRef") {
      scrollToSection(gRef);
    }
    if (nodeRef === "jRef") {
      scrollToSection(jRef);
    }
    if (nodeRef === "fRef") {
      scrollToSection(fRef);
    }
  }, [nodeRef, nodeRefTime]);
};

export const useScrollToTop = (ref) => {
  const location = useLocation();
  const { dispatch } = useContext(Context);
  const scrollToSection = () => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (location.pathname !== "/") {
      dispatch({ type: "SET_NODE_REF", payload: { nodeName: "", time: "" } });
    }
    scrollToSection();
  }, []);
};
