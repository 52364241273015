const UserDo = () => {
  return (
    <div className=" bg-white w-full pt-[250px] md:pt-[50px] pb-[50px] flex flex-col md:flex-row px-[7%] md:px-[13.8%] md:gap-x-[2rem] gap-x-0 gap-y-[44px] md:gap-y-0">
      <div className=" flex flex-col items-center md:items-start text-center md:text-left ">
        <img src="/assets/images/saveIcon.svg" alt="" />
        <p className="text-[1.25rem] text-black font-SF font-[700] mt-[26px] mb-[24px]">
          Save monthly
        </p>
        <p className="text-[1rem] md:text-[0.8rem] text-[rgba(0,0,0,0.75)] leading-[24px] font-SF font-[300]">
          Your savings is invested in secure low-risk financial market
          instruments. You have a chance to win a prize monthly, big or small.
        </p>
      </div>
      <div className=" flex flex-col items-center md:items-start text-center md:text-left ">
        <img src="/assets/images/winIcon.svg" alt="" />
        <p className="text-[1.25rem] text-black font-SF font-[700] mt-[26px] mb-[24px]">
          Win prizes
        </p>
        <p className="text-[1rem] md:text-[0.8rem] text-[rgba(0,0,0,0.75)] leading-[24px] font-SF font-[300]">
          Once you save with OneCap, you're entered into a savings pool where
          you'll have a chance to win monthly.
        </p>
      </div>
      <div className=" flex flex-col items-center md:items-start text-center md:text-left ">
        <img src="/assets/images/noLossIcon.svg" alt="" />
        <p className="text-[1.25rem] text-black font-SF font-[700] mt-[26px] mb-[24px]">
          No loss platform
        </p>
        <p className="text-[1rem] md:text-[0.8rem] text-[rgba(0,0,0,0.75)] leading-[24px] font-SF font-[300]">
          Prizes are derived from the interest that accrues on deposits and
          therefore is “no loss”. Better than wealth destroying options.
        </p>
      </div>
    </div>
  );
};

export default UserDo;
