import React from "react";
import { Box, Typography } from "@mui/material";
import NavLayout from "../NavLayout";

const Bill = () => {
  return (
    <NavLayout
      title="Coming Soon"
      content={
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            minHeight: "100vh",
            maxWidth: "500px",
            margin: "0 auto",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <Typography variant="h3">Coming Soon</Typography>
        </Box>
      }
    />
  );
};

export default Bill;
