import React from "react";
import NavLayout from "../NavLayout";
import InvestmentsPage from "./Investments";
import PortfolioPage from "./Portfolio";

export function Investments() {
  return <NavLayout title="Investments" content={<InvestmentsPage />} />;
}

export function Portfolio() {
  return <NavLayout title="Portfolio" content={<PortfolioPage />} />;
}
