const Accord = ({ heading, text, click, id, idArray }) => {
  return (
    <div
      onClick={click}
      className={` w-full p-[14.5px_24px] border-[0.2px] border-black rounded-[5px]  overflow-hidden transition-all duration-500 ease-in-out ${
        idArray?.includes(id)
          ? "max-h-[550px]"
          : "max-h-[60px] flex md:block flex-col justify-center "
      }`}
    >
      <div
        className={` flex gap-x-[22px] items-center leading-[24px] text-[1.125rem] text-black font-SF font-[500] cursor-pointer`}
      >
        <img
          src="/assets/images/accordArrow.png"
          alt=""
          className={`transition-all duration-500 ease-in-out ${
            idArray?.includes(id) && "rotate-90"
          }`}
        />
        {heading}
      </div>

      <div
        className={`${
          idArray?.includes(id) ? "block" : "hidden md:block"
        } pl-[23px] mt-[24.5px] leading-[24px] text-[1.125rem] text-black font-SF font-[300]`}
      >
        {text}
      </div>
    </div>
  );
};

export default Accord;
