import React, { useRef } from "react";
import Navigation from "./Navigation";
import Banner from "./Banner";
import UserDo from "./UserDo";
import OneCap from "./OneCap";
import Gamified from "./Gamified";
import Jackport from "./Jackport";
import Save from "./Save";
import PriceLinked from "./PriceLinked";
import MoreFun from "./MoreFun";
import StartSaving from "./StartSaving";
import Footer from "./Footer";
import {
  useJackpotPoolInfo,
  useJackpotWinningInfo,
  usePoolInfo,
  useValidateReferralCode,
  useWinningInfo,
} from "../helpers/Hooks/Api";
import { useScrollToSection } from "../helpers/Hooks/ScrollTo";

const Landing = () => {
  useValidateReferralCode();
  useWinningInfo();
  useJackpotWinningInfo();
  usePoolInfo();
  useJackpotPoolInfo();
  const gRef = useRef();
  const jRef = useRef();
  const fRef = useRef();

  useScrollToSection(gRef, jRef, fRef);

  return (
    <div>
      <Navigation />
      <Banner />
      <UserDo />
      <OneCap />
      <Gamified gamifiedRef={gRef} />
      <Jackport jackpotRef={jRef} />
      <Save />
      <PriceLinked />
      <MoreFun />
      <StartSaving />
      <Footer footerRef={fRef} />
    </div>
  );
};

export default Landing;
