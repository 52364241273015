import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "../../helpers/axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";
import { useState } from "react";
import { Audio } from "react-loader-spinner";
import { Oval } from "react-loader-spinner";

import { useEffect } from "react";
import { formstyles } from "../../helpers/Styles";
const UploadID = () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [serverError, setServerError] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [frontImage, setFrontImage] = useState();
  const [backImage, setBackImage] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [id, setID] = useState();
  const [showForm, setShowForm] = useState(true);

  const fetchData = async () => {
    setIsloading(true);
    await axios
      .get("/profile", config)
      .then(function (response) {
        setIsloading(false);

        if (response?.data?.identification) {
          if (
            response.data.identification.status === "Processing" ||
            response.data.identification.status === "Approved"
          ) {
            setShowForm(false);
            setID(response.data.identification.status);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        navigate("/signin");
      });
  };
  const handleChange = (event) => {
    setID(event.target.value);
  };
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("front_image", data.front_image[0]);
    formData.append("back_image", data.back_image[0]);
    formData.append("identification_number", data.identification_number);
    formData.append("mode_of_identity", data.id_type);

    setServerError(
      <Audio
        height="20"
        width="20"
        radius="9"
        color="#592E6D"
        ariaLabel="three-dots-loading"
      />
    );
    await axios
      .put("/identification/update", formData, config)
      .then(function (response) {
        setServerError(response.data.message);
        setTimeout(() => {
          navigate("/settings/personalinfo");
        }, "3000");
      })
      .catch(function (error) {
        console.log(error);
        if (error?.response?.data) {
          setServerError(error.response.data[0]);
        }
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <NavLayout
      title="Update Identification"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#F5F5F5",
            minHeight: "100vh",
            margin: "0 auto",
            paddingTop: "1px",
          }}
        >
          <Box className="flex justify-center relative mt-[20px]">
            <img
              src={"/assets/images/close.png"}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
              className=" absolute left-[20px]"
            />

            <Typography className="text-[#592E6D]">
              Update Identification
            </Typography>
          </Box>

          <Box pb={"10px"} width={"95%"} margin={"0px auto"}>
            <Box
              sx={{
                marginTop: "40px",
              }}
            ></Box>
            {isLoading ? (
              <Box
                h={"100%"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Oval
                  height={80}
                  width={80}
                  color="#290349"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#592e6d"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </Box>
            ) : showForm ? (
              <form encType="multipart/form" onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    borderRadius: "5px",
                    border: "0.5px solid #592E6D",
                    padding: "20px 0 20px 3px",
                    marginBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography my="10px">Choose Document</Typography>
                  <Box
                    width={"80%"}
                    sx={{
                      borderRadius: "5px",
                      border: "0.5px solid #592E6D",
                      padding: "3px 0 3px 3px",
                    }}
                  >
                    <FormControl fullWidth>
                      <Select
                        disableUnderline
                        fullWidth
                        variant="standard"
                        name="id_type"
                        {...register("id_type", {
                          required: "Choose document type",
                        })}
                        id="demo-simple-select"
                        value={id}
                        placeholder="ID type"
                        error={errors.id_type?.message}
                        sx={{
                          color: "#592E6D",
                          marginTop: 0,
                          border: "none",
                          "& .MuiFormLabel-root": {
                            color: "#592E6D",
                          },
                          "& .MuiInputBase-root.MuiInput-root.MuiSelect-root": {
                            marginTop: "0px !important",
                            background: "blue !important",
                          },
                          "& .css-21r7d85-MuiInputBase-root-MuiInput-root-MuiSelect-root":
                            {
                              marginTop: "0px !important",
                              background: "blue !important",
                            },
                        }}
                        onChange={handleChange}
                      >
                        <MenuItem value={"National ID"}>
                          <Typography>National ID</Typography>
                        </MenuItem>
                        <MenuItem value={"Driver's License"}>
                          <Typography>Driver's License</Typography>
                        </MenuItem>
                        <MenuItem value={"International Passport"}>
                          <Typography>International Passport</Typography>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Typography my="10px">
                    Document Identification Number
                  </Typography>
                  <Box
                    sx={{
                      borderRadius: "5px",
                      border: "0.5px solid #592E6D",
                      padding: "0px 0 0px 3px",
                      marginBottom: "20px",
                      width: "80%",
                    }}
                  >
                    <TextField
                      required
                      fullWidth
                      labelfor="Identification Number"
                      type={"text"}
                      name="identification_number"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      sx={formstyles}
                      {...register("identification_number", {})}
                    />
                    <Typography
                      variant="body2"
                      sx={{ textAlign: "right", paddingRight: "5px" }}
                      color="red"
                    >
                      {errors.identification_number?.message}
                    </Typography>
                  </Box>
                  <Typography my="10px">
                    Upload Front and Back of ID card
                  </Typography>
                  <Box>
                    <Box
                      display={"flex"}
                      flexDirection="column"
                      alignItems="center"
                      gap="10px"
                    >
                      <img
                        src={
                          frontImage
                            ? URL.createObjectURL(frontImage)
                            : "/assets/images/idcard.png"
                        }
                        style={{
                          width: "300px",
                          objectFit: "contain",
                        }}
                        alt="idcard"
                      />
                      <button className="p-[13px_37px_12px_37px] rounded-[5px] bg-[#592E6D] text-white text-[1.125rem] font-AR mb-[28px]">
                        <label htmlFor="front">{"UPLOAD FRONT"}</label>
                      </button>
                      <Typography variant="caption" color={"red"}>
                        {errors.front_image?.message}
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection="column"
                      alignItems="center"
                      gap="10px"
                      mt="20px"
                    >
                      <img
                        src={
                          backImage
                            ? URL.createObjectURL(backImage)
                            : "/assets/images/idcard.png"
                        }
                        style={{
                          width: "300px",
                          objectFit: "contain",
                        }}
                        alt="idcard"
                      />
                      <button className="p-[13px_37px_12px_37px] rounded-[5px] bg-[#592E6D] text-white text-[1.125rem] font-AR mb-[28px]">
                        <label htmlFor="back">{"UPLOAD BACK"}</label>
                      </button>
                      <Typography variant="caption" color="red">
                        {errors.front_image?.message}
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <ol>
                      <li>Take a picture of your valid ID</li>
                      <li>Ensure the picture is clear and readable</li>
                      <li>Ensure your picture on the ID is clear</li>
                    </ol>
                  </Box>
                  <Box>
                    <input
                      id="front"
                      type={"file"}
                      name={"front_image"}
                      accept="image/*"
                      style={{
                        background: "blue",
                        color: "white",
                        zIndex: -1,
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      {...register("front_image", {
                        required: {
                          value: true,
                          message: "Field is required",
                        },
                      })}
                      onChange={(e) => {
                        setButtonText(e.target.value);
                        setFrontImage(e.target.files[0]);
                      }}
                    />
                    <input
                      id="back"
                      type={"file"}
                      name={"back_image"}
                      accept="image/*"
                      style={{
                        background: "blue",
                        color: "white",
                        zIndex: -1,
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      {...register("back_image", {
                        required: { value: true, message: "Field is required" },
                      })}
                      onChange={(e) => {
                        setButtonText(e.target.value);
                        setBackImage(e.target.files[0]);
                      }}
                    />
                  </Box>
                </Box>
                <Typography variant="caption">{serverError}</Typography>

                <Box margin="10px auto 60px auto" width="95%">
                  <Button
                    type="submit"
                    sx={{
                      border: "0.5px solid #592E6D",
                      backgroundColor: "#592E6D",
                      borderRadius: "5px",
                      width: "100%",
                      ":hover": {
                        bgcolor: "#a852dd",
                      },
                      height: "45px",

                      color: "#FFFFFF",
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </form>
            ) : (
              <Box width={"full"} textAlign="center">
                <Typography variant={"h6"}>
                  Your identification document{" "}
                  {id === "Verified" && " has been verified"}{" "}
                  {id === "Processing" && "is being processed"}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      }
    />
  );
};

export default UploadID;
