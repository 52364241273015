import { useState, useRef, useContext } from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ClickOut from "../../helpers/Hooks/ClickOut";
import MyReferrals from "./MyReferrals";
import MyRewards from "./MyRewards";

import { Context } from "../../helpers/Context";
import {
  WhatsappShareButton,
  TwitterShareButton,
  TelegramShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import ReferralLeaderBoard from "./ReferralLeaderBoard";
import { useCopyToClipboard } from "../../helpers/Hooks/Copy";

const Referrals = () => {
  const navigate = useNavigate();
  const [pageNum, setPageNum] = useState(2);

  const [shareBox, setShareBox] = useState(false);
  const { state } = useContext(Context);
  const { referral_details } = state;
  const shareBoxRef = useRef();
  const { handleCopyClick, isCopied } = useCopyToClipboard();

  ClickOut(setShareBox, shareBox, shareBoxRef);

  const ShareIcons = ({ imgUrl, bg, caption }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            height: "70px",
            width: "70px",
            borderRadius: "50%",
            background: bg,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "all 0.4s ease",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
          onClick={() => handleCopyClick(referral_details?.referral_link)}
        >
          <img alt="copy" src={imgUrl} />
        </Box>
        <Typography
          variant="caption"
          sx={{
            color: "#290349",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {caption}
        </Typography>
      </Box>
    );
  };

  return (
    <Box maxWidth={"500px"} sx={{ margin: "0 auto", paddingBottom: "3rem" }}>
      <Box>
        <Box
          sx={{
            padding: "24px 20px",
            borderBottom: "0.1px solid #FFFFFF80",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              marginBottom: "10px",
              position: "relative",
            }}
          >
            <ArrowBackIos
              sx={{
                fontSize: "16px",
                cursor: "pointer",
                position: "absolute",
                left: "0",
              }}
              onClick={() => {
                navigate(-1);
              }}
            />
            <Typography variant="body1">Referrals</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: "0 20px", color: "#fff", position: "relative" }}>
        <Box sx={{ margin: "10px 0" }}>
          <Typography variant="caption">
            Refer a friend to get 10 OC Tokens
          </Typography>
        </Box>
        {shareBox && (
          <Box
            ref={shareBoxRef}
            sx={{
              position: "absolute",
              top: "30px",
              left: "20px",
              right: "20px",
              height: "569px",
              padding: "20px",
              background: "#fff",
              backgroundBlendMode: "overlay",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              zIndex: "1",
            }}
          >
            <Typography
              variant="body2"
              sx={{ textAlign: "center", color: "#592E6D", fontWeight: "800" }}
            >
              Share with friends
            </Typography>

            <Box sx={{ marginTop: "36px" }}>
              <Typography
                variant="caption"
                sx={{ color: "#592E6D", fontWeight: "200" }}
              >
                Share referral link via
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridTemplateRows: "1fr 1fr",
                justifyItems: "center",
                rowGap: "2rem",
                marginTop: "15px",
              }}
            >
              <ShareIcons
                imgUrl="/assets/images/copy.png"
                bg="#B9B9B9"
                caption={isCopied ? "Copied" : "Copy"}
                onClick={handleCopyClick}
              />
              <FacebookShareButton
                url={referral_details?.referral_link}
                quote={
                  "Hey, I would like to introduce you to Onecap. This is where I make smart monie moves. Sign up now and let's make monie together"
                }
                hashtag={"#Onecap"}
              >
                <ShareIcons
                  imgUrl="/assets/images/facebook.png"
                  bg="#3B5998"
                  caption="Facebook"
                />
              </FacebookShareButton>
              <WhatsappShareButton
                url={referral_details?.referral_link}
                title={
                  "Hey, I would like to introduce you to Onecap. This is where I make smart monie moves. Sign up now and let's make monie together"
                }
              >
                <ShareIcons
                  imgUrl="/assets/images/whatsapp.png"
                  bg="#4CAF50"
                  caption="Whatsapp"
                />
              </WhatsappShareButton>
              <TwitterShareButton
                url={referral_details?.referral_link}
                title={
                  "Hey, I would like to introduce you to Onecap. This is where I make smart monie moves. Sign up now and let's make monie together"
                }
                hashtags="#Onecap"
              >
                <ShareIcons
                  imgUrl="/assets/images/twitter.png"
                  bg="#1DA1F2"
                  caption="Twitter"
                />
              </TwitterShareButton>
              <LinkedinShareButton
                title={"Onecap"}
                summary={
                  "Hey, I would like to introduce you to Onecap. This is where I make smart monie moves. Sign up now and let's make monie together"
                }
                source={"https://onecap.africa"}
              >
                <ShareIcons
                  imgUrl="/assets/images/linkedin.png"
                  bg="#0072B1"
                  caption="LinkedIn"
                />
              </LinkedinShareButton>
              <TelegramShareButton
                url={referral_details?.referral_link}
                title={
                  "Hey, I would like to introduce you to Onecap. This is where I make smart monie moves. Sign up now and let's make monie together"
                }
              >
                <ShareIcons
                  imgUrl="/assets/images/telegram.png"
                  bg="#229ED9"
                  caption="Telegram"
                />
              </TelegramShareButton>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            border: "0.01px solid rgba(255,28,247,0.4)",
            padding: "20px",
            color: "#fff",
            borderRadius: "10px",
          }}
        >
          <Typography variant="caption">REFERRAL LINK</Typography>
          <Box
            sx={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px dashed #fff",
              borderRadius: "5px",
            }}
          >
            <Typography variant="caption" sx={{ color: "#fff" }}>
              {referral_details?.referral_link?.substring(0, 32)}...
            </Typography>
            <Button
              color="primary"
              sx={{ border: "none", background: "transparent" }}
              onClick={() => setShareBox(true)}
            >
              <Typography variant="caption">SHARE</Typography>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            background: "#fff",
            padding: "20px",
            color: "#fff",
            marginTop: "15px",
            border: "0.5px solid #FF1CF7",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "10px 10px 0px 0px",
            overflow: "auto",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <div
              className="Tab"
              style={{
                flex: 1,
                padding: " 8px 12px",
                color: "#290349",
                borderBottom: pageNum === 1 ? "2px solid #FF1CF7" : "none",
                fontWeight: "700",
              }}
            >
              <Typography
                variant="body2"
                textAlign={"center"}
                sx={{ fontWeight: "600", cursor: "pointer" }}
                onClick={() => setPageNum(1)}
              >
                My Rewards
              </Typography>
            </div>
            <div
              className="Tab"
              style={{
                padding: "8px 12px",
                color: "#290349",
                flex: 1,
                borderBottom: pageNum === 2 ? "2px solid #FF1CF7" : "none",
              }}
            >
              <Typography
                variant="body2"
                textAlign={"center"}
                onClick={() => setPageNum(2)}
                sx={{ fontWeight: "600", cursor: "pointer" }}
              >
                My Referrals
              </Typography>
            </div>
            <div
              className="Tab"
              style={{
                flex: 1,
                padding: " 8px 12px",
                color: "#290349",
                borderBottom: pageNum === 3 ? "2px solid #FF1CF7" : "none",
                fontWeight: "700",
              }}
            >
              <Typography
                variant="body2"
                textAlign={"center"}
                sx={{ fontWeight: "600", cursor: "pointer" }}
                onClick={() => setPageNum(3)}
              >
                League
              </Typography>
            </div>
          </Box>
          <Box sx={{ marginTop: "30px", paddingBottom: "2rem" }}>
            {pageNum === 1 && <MyRewards />}
            {pageNum === 2 && <MyReferrals />}
            {pageNum === 3 && <ReferralLeaderBoard />}

            {referral_details?.referrals?.length >= 5 && pageNum === 2 && (
              <Button
                sx={{
                  color: "#592E6D",
                  float: "right",
                  margin: "15px 0",
                  textTransform: "none",
                  "&:hover": {
                    color: "#290349b7",
                  },
                }}
                onClick={() => navigate("/referral-details")}
              >
                <Typography variant="caption" sx={{ fontWeight: "600" }}>
                  See all...
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Referrals;
