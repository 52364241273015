import { useState, useContext } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Avatar,
  Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";
import { useForm } from "react-hook-form";
import { Oval } from "react-loader-spinner";
import { formstyles } from "../../helpers/Styles";
import { useAddBank, useBanks, useUserBanks } from "../../helpers/Hooks/Api";
import { Context } from "../../helpers/Context";

const Bank = () => {
  const navigate = useNavigate();
  const { isLoading } = useUserBanks();
  const [openForm, setOpenForm] = useState(false);
  useBanks();
  const { serverError, addBank } = useAddBank(setOpenForm);

  const { state } = useContext(Context);
  const { userBanks, banks } = state;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});

  return (
    <NavLayout
      title="Bank Accounts"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#FFF",
            minHeight: "100vh",
            margin: "0 auto",
            paddingTop: "1px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 20px 0",
              position: "relative",
            }}
          >
            <img
              src={"/assets/images/closePop.png"}
              alt=""
              className=" cursor-pointer absolute left-0"
              onClick={() => navigate(-1)}
            />

            <Typography className=" text-center text-[0.875rem] text-[#2E084D] font-AR">
              Bank Accounts
            </Typography>
          </Box>

          <Box width={"95%"} margin={"0px auto"}>
            <Box
              sx={{
                marginTop: "26px",
              }}
            ></Box>

            {!openForm && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                mt={"37px"}
                mb={"4rem"}
                gap={"20px"}
              >
                {isLoading ? (
                  <div className=" h-full flex flex-col items-center m-[15px_0]">
                    <Oval
                      height={80}
                      width={80}
                      color="#290349"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#592e6d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                ) : userBanks.length > 0 ? (
                  userBanks.map((bank, index) => {
                    return (
                      <BankCard
                        key={bank.id}
                        name={`${bank.user.first_name} ${bank.user.last_name}`}
                        account_number={bank.account_number}
                        bank={bank.bank}
                        index={index}
                      />
                    );
                  })
                ) : (
                  "No bank record. Add a bank "
                )}
                <Box margin="100px auto" width="95%">
                  <button
                    onClick={() => setOpenForm(true)}
                    className=" bg-brandPurp rounded-[10px] text-white h-[45px] w-full text-[0.75rem] font-AR font-[700] border-none shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
                  >
                    <Typography variant="body2">ADD BANK</Typography>
                  </button>
                </Box>
              </Box>
            )}
            {openForm && (
              <form onSubmit={handleSubmit(addBank)}>
                <Typography className=" text-center text-brandPurp text-[0.75rem] font-AR">
                  Add a bank account
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    color: "#fff",
                    my: "20px",
                  }}
                >
                  <Box>
                    <Typography color="#592E6D" variant="caption">
                      Account Number
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: "5px",
                        border: "0.5px solid #592E6D",
                        padding: "3px 0 3px 3px",
                      }}
                    >
                      <TextField
                        fullWidth
                        type="text"
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        sx={formstyles}
                        name="account_number"
                        {...register("account_number", {
                          minLength: {
                            value: 10,
                            message: "Invalid Account Number",
                          },
                          maxLength: {
                            value: 10,
                            message: "Invalid Account Number",
                          },
                          required: "Account Number is required",
                        })}
                        error={errors.account_number?.message}
                      />

                      <Typography
                        variant="body2"
                        sx={{ textAlign: "right", paddingRight: "5px" }}
                        color="red"
                      >
                        {errors.account_number?.message}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography color="#592E6D" variant="caption">
                      Choose a bank
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: "5px",
                        border: "0.5px solid #592E6D",
                        padding: "3px 0 3px 3px",
                      }}
                    >
                      <Autocomplete
                        variant="standard"
                        disablePortal
                        id="combo-box-demo"
                        options={banks}
                        getOptionLabel={(option) => option}
                        renderOption={(props, option) => (
                          <MenuItem key={option} value={option} {...props}>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              gap={"10px"}
                            >
                              {/* <Avatar src={option.logo} /> */}

                              <Typography>{option}</Typography>
                            </Box>
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            sx={formstyles}
                            {...params}
                            {...register("bank", {
                              required: "Bank name is required",
                            })}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      <Typography
                        variant="body2"
                        sx={{ textAlign: "right", paddingRight: "5px" }}
                        color="red"
                      >
                        {errors.bank?.message}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography variant="caption">{serverError}</Typography>
                <Box
                  mt={"40px"}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <button
                    type="submit"
                    className=" bg-brandPurp  rounded-[10px] text-white h-[45px] w-full text-[0.75rem] font-AR font-[700] border-none shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
                  >
                    <Typography variant="body2">ADD BANK</Typography>
                  </button>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      }
    />
  );
};

const BankCard = (props) => {
  return (
    <Box
      border={"0.5px solid #FF1CF7 "}
      px={"10px"}
      py="20px"
      borderRadius={"10px"}
      className={`${
        (props.index + 1) % 2 === 0 ? " bg-white" : " bg-brandPurp"
      }`}
    >
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Typography
          className={` text-[0.75rem] font-AR font-[700] ${
            (props.index + 1) % 2 === 0 ? " text-brandPurp" : " text-white"
          }`}
        >
          Name
        </Typography>
        <Typography
          className={` text-[0.75rem] font-AR ${
            (props.index + 1) % 2 === 0 ? " text-brandPurp" : " text-white"
          }`}
        >
          {props.name}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Typography
          className={` text-[0.75rem] font-AR font-[700] ${
            (props.index + 1) % 2 === 0 ? " text-brandPurp" : " text-white"
          }`}
        >
          Account Number
        </Typography>
        <Typography
          className={` text-[0.75rem] font-AR ${
            (props.index + 1) % 2 === 0 ? " text-brandPurp" : " text-white"
          }`}
        >
          {props.account_number}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Typography
          className={` text-[0.75rem] font-AR font-[700] ${
            (props.index + 1) % 2 === 0 ? " text-brandPurp" : " text-white"
          }`}
        >
          Bank Name
        </Typography>
        <Typography
          className={` text-[0.75rem] font-AR ${
            (props.index + 1) % 2 === 0 ? " text-brandPurp" : " text-white"
          }`}
        >
          {props.bank}
        </Typography>
      </Box>
    </Box>
  );
};

export default Bank;
