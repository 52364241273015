import NavLayout from "../NavLayout";
import { useNavigate } from "react-router-dom";
import axios from "../../helpers/axios";
import { useEffect, useState } from "react";
import { Box, Typography, Snackbar } from "@mui/material";
import { Oval } from "react-loader-spinner";
import { onMessageListener } from "../../Auth/firebase";
import { ArrowBackIos } from "@mui/icons-material/";

const TransactionHistory = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const [notification, setNotification] = useState({ title: "", body: "" });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchTransactions = async () => {
    await axios
      .get("/transactions", config)
      .then(function (response) {
        setIsloading(false);
        setTransactions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchTransactions();
  }, []);
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <>
      <NavLayout
        title="Dashboard"
        content={
          <Box sx={{ paddingTop: "20px", margin: "0 auto" }} maxWidth={"500px"}>
            <Box>
              <Box
                sx={{
                  padding: "24px 20px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#fff",
                    marginBottom: "10px",
                  }}
                >
                  <ArrowBackIos
                    sx={{ fontSize: "16px", cursor: "pointer" }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <Box textAlign={"center"} width="100%">
                    <Typography className=" text-white text-[1rem] font-AR leading-[24px] tracking-[0.15px]">
                      Transaction History
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "0px 15px 35px",
                margin: "20px auto",
                marginBottom: "20px",
                border: " 0.5px solid #ff1cf7",
                borderRadius: "10px",
                boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
                boxSizing: "border-box",
                color: "#592E6D",
                maxWidth: "500px",
                height: "80vh",
                background: "#fff",
              }}
              className="overflow-auto scrollbar-none md:scrollbar scrollbar-w-[8px] scrollbar-track-[rgba(227,196,253,0.50)] hover:scrollbar-thumb-[#484747] scrollbar-thumb-[transparent] scrollbar-track-rounded-[10px] scrollbar-thumb-rounded-[10px]"
            >
              {isLoading ? (
                <Box
                  h={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Oval
                    height={80}
                    width={80}
                    color="#290349"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#592e6d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </Box>
              ) : transactions.length > 0 ? (
                transactions.map((transaction) => {
                  return (
                    <Box
                      key={transaction.id}
                      sx={{
                        borderBottom: "0.01px solid rgba(255,28,247,0.4)",
                        py: "10px",

                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/dashboard/transactions/${transaction.id}`);
                      }}
                    >
                      <img
                        src="/assets/images/transactIcon.png"
                        alt="transaction"
                      />
                      <Box sx={{}}>
                        <Typography
                          textTransform={"uppercase"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                        >
                          {transaction.description.slice(0, 30)}...
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "300",
                            marginTop: "6px",
                          }}
                        >
                          {transaction.time_stamp.slice(0, 10)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          flex: "1",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "auto",
                        }}
                      >
                        <Box sx={{ marginLeft: "auto" }}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            &#8358;
                            {parseFloat(transaction.amount)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </Typography>
                          <Typography
                            sx={{
                              // textAlign: "right",
                              marginTop: "6px",
                              fontSize: "12px",
                              fontWeight: "300",
                            }}
                          >
                            <br></br>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Typography>No Transaction history</Typography>
              )}
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default TransactionHistory;
