import axios from "axios";

const axiosInst = axios.create({
  baseURL: "https://api.onecap.africa/api",
});

export const axiosNoApi = axios.create({
  baseURL: "https://api.onecap.africa",
});

export const axiosNoBase = axios.create({
  baseURL: "",
});

export default axiosInst;
