import NavLayout from "../NavLayout";
import Rewards from "./Rewards";
import { useRewardLeague, useUserInfo } from "../../helpers/Hooks/Api";

const RewardsIndex = () => {
  useUserInfo();
  useRewardLeague();
  return <NavLayout title="Rewards" content={<Rewards />} />;
};

export default RewardsIndex;
