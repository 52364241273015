import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import NavLayout from "../NavLayout";
import {
  useQuizBoardData,
  useUserInfo,
  useUserWalletData,
} from "../../helpers/Hooks/Api";
import RankCard from "./RankCard";
import { useContext } from "react";
import { Context } from "../../helpers/Context";
import { Avatar } from "@mui/material";
import Moment from "react-moment";
import { Oval } from "react-loader-spinner";

const QuizBoard = () => {
  useUserInfo();
  useUserWalletData();
  const quizBoardLoading = useQuizBoardData();
  const navigate = useNavigate();

  const { state } = useContext(Context);
  const { userWalletData, quizBoardData } = state;

  return (
    <NavLayout
      title="Quiz"
      content={
        <section className=" max-w-[500px] sm:m-[0_auto] pb-[5rem]">
          <div className="p-[24px_20px] border-b-[0.1px_solid_#ffffff80]">
            <div className=" w-[52%] flex items-center justify-between text-[rgba(255,255,255,0.87)] text-[1rem] font-AR">
              <ArrowBackIos
                sx={{ fontSize: "16px", cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              />
              Quiz Board
            </div>
          </div>

          <div className=" rounded-[10px] p-[21px_19px] w-full bg-white border-[0.5px] border-[#ff1cf7] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] flex flex-col items-center min-h-[692px]">
            <div className=" flex flex-col gap-y-[19.53px]">
              {quizBoardData?.slice(0, 3)?.map((winner, index) => (
                <div key={index} className="flex items-center gap-x-[18.47px]">
                  <Avatar
                    src={winner?.user_image}
                    alt=""
                    className=" w-[50px] h-[50px] "
                  />
                  <div className="flex items-center justify-between w-[5.3rem]">
                    <span className=" text-black text-[0.65rem] font-AR">
                      {winner?.name?.split(" ")[0]}
                    </span>{" "}
                    <span className=" text-black text-[2.5rem] font-AR">
                      {index + 1}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full flex items-center justify-between mt-[28px]">
              <span className=" text-[0.875rem] text-brandPurp font-SF">
                Quiz Ranking
              </span>
              <img src="/assets/images/filter.png" alt="" />
            </div>
            {quizBoardLoading && (
              <div className=" h-full flex flex-col items-center m-[15px_0]">
                <Oval
                  height={80}
                  width={80}
                  color="#290349"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#592e6d"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
            <div className=" w-full mt-[15px]">
              {quizBoardData?.map((person, index) => (
                <RankCard
                  key={index + 1}
                  sn={index + 1}
                  rankId={person?.wallet_id}
                  userId={userWalletData?.wallet_id}
                  name={person?.name?.split(" ")[0]}
                  date={
                    <Moment format="DD/MM/YYYY">{person?.date_taken}</Moment>
                  }
                  time={
                    <Moment format="HH.MM.SS">{person?.time_started}</Moment>
                  }
                  timeUsed={person?.time_used}
                  point={person?.point_token_earned}
                />
              ))}
            </div>
          </div>
        </section>
      }
    />
  );
};

export default QuizBoard;
