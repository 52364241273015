import React, { useContext } from "react";
import { Box, Typography, Button, Avatar } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";
import { useState } from "react";
import { useUpdateDetails, useUserProfile } from "../../helpers/Hooks/Api";
import { Context } from "../../helpers/Context";

const SetAvatar = () => {
  useUserProfile();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const { state } = useContext(Context);
  const { userProfile } = state;
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();
  const { uploadImage, serverError } = useUpdateDetails();

  return (
    <NavLayout
      title="Upload Picture"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#F5F5F5",
            minHeight: "100vh",
            margin: "0 auto",
            paddingTop: "1px",
            paddingBottom: "4rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginLeft: "20px",
              marginTop: "20px",
              pb: "10px",
            }}
          >
            <img
              src={"/assets/images/close.png"}
              width="18px"
              height="8px"
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />

            <Typography sx={{ marginLeft: "150px", color: "#592E6D" }}>
              Upload Picture
            </Typography>
          </Box>

          <Box width={"95%"} margin={"0px auto"}>
            <Box
              sx={{
                marginTop: "40px",
              }}
            ></Box>
            <form encType="multipart/form" onSubmit={handleSubmit(uploadImage)}>
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "20px 0 0px 3px",
                  marginBottom: "20px",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{
                    width: "300px",
                    height: "300px",
                    mb: "30px",
                  }}
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : userProfile?.user_image
                  }
                />

                <Box>
                  <Box
                    sx={{
                      border: "0.5px solid #592E6D",
                      backgroundColor: " #a852dd",
                      mb: ".5rem",
                      padding: "0.5rem",
                      textAlign: "center",
                      borderRadius: "5px",
                      width: "100%",
                      ":hover": {
                        bgcolor: "#592E6D",
                      },

                      color: "#FFFFFF",
                    }}
                  >
                    <label htmlFor="inputTag" className=" cursor-pointer">
                      {"Upload image"}
                    </label>
                  </Box>

                  <input
                    id="inputTag"
                    type={"file"}
                    name={"file"}
                    accept="image/*"
                    style={{
                      background: "blue",
                      color: "white",
                      zIndex: -1,
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                    {...register("file", {
                      required: {
                        value: true,
                        message: "Field is required",
                      },
                    })}
                    onChange={(e) => {
                      setSelectedImage(e.target.files[0]);
                    }}
                  />
                </Box>
                <Box width="100%" textAlign="center">
                  <Typography variant="caption" color="red">
                    {errors.file?.message}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="caption">{serverError}</Typography>

              <Box margin="50px auto" width="95%">
                <Button
                  type="submit"
                  sx={{
                    border: "0.5px solid #592E6D",
                    backgroundColor: "#592E6D",
                    borderRadius: "5px",
                    width: "100%",
                    ":hover": {
                      bgcolor: "#a852dd",
                    },
                    height: "45px",

                    color: "#FFFFFF",
                  }}
                >
                  Save
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      }
    />
  );
};

export default SetAvatar;
