import { Box, Card, Typography } from "@mui/material";
import { ArrowBackIos, MoreHoriz } from "@mui/icons-material/";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Oval } from "react-loader-spinner";

import axios from "../../helpers/axios";
import { useState, useEffect } from "react";

const PortfolioPage = () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [isLoading, setIsloading] = useState(true);
  const [investments, setInvestments] = useState([]);
  const [totalInvestments, setTotalInvestments] = useState(0);
  const [profitPercent, setProfitPercent] = useState(0);
  const [earning, setEarning] = useState(0);

  const fetchData = async () => {
    await axios
      .get("/wallet/detail", config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
        navigate("/signin");
      });
  };
  const fetchInvestments = async () => {
    let investment = 0;
    let totalEstimated = 0;
    let profit;
    await axios
      .get("/current-investments", config)
      .then(function (response) {
        setIsloading(false);
        const investmentData = response?.data;
        setInvestments(investmentData);

        for (let index = 0; index < investmentData.length; index++) {
          investment += investmentData[index].amount_invested;
          totalEstimated += investmentData[index].estimated_earnings;
        }
        setTotalInvestments(investment);
        setEarning(totalEstimated - investment);
        if (earning === 0) {
          setProfitPercent(0);
        } else {
          setProfitPercent(
            (((totalEstimated - investment) / investment) * 100).toPrecision(4)
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
    fetchInvestments();
  }, []);

  const navigate = useNavigate();
  return (
    <Box maxWidth={"500px"} sx={{ margin: "0 auto" }}>
      <Box
        sx={{
          paddingBottom: "20px",
        }}
      >
        <Box
          sx={{
            padding: "24px 20px",
            borderBottom: "0.1px solid #FFFFFF80",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#fff",
              marginBottom: "10px",
            }}
          >
            <ArrowBackIos
              sx={{ fontSize: "16px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
            <Box textAlign={"center"} width="100%">
              <Typography variant="body1">Investment Portfolio</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: "24px 20px" }}>
          <Card
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              border: "0.01px solid rgba(255,28,247,0.4)",
              padding: "20px",
              color: "#fff",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="caption"
                sx={{ fontWeight: "200", fontSize: "14px", lineHeight: "20px" }}
              >
                Portfolio Value
              </Typography>
            </Box>
            <Box
              mt={""}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="caption"
                sx={{ fontWeight: "500", fontSize: "28px", lineHeight: "20px" }}
              >
                &#8358;
                {parseFloat(totalInvestments)
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Typography>
              <Box>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#75F0A6",
                    marginBottom: "16px",
                  }}
                >
                  + &#8358;
                  {parseFloat(earning)
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  ({profitPercent})%
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: "300" }}>
                  As of {new Date().toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
            <Box mt={"24px"}>
              <Box
                mt={"8px"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {""}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#759EF0",
                  }}
                ></Box>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>

      <Box sx={{ padding: "24px 20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <Typography
            sx={{ color: "#fff", fontWeight: "400", fontSize: "14px" }}
          >
            Portfolio Details
          </Typography>
          <FilterListIcon sx={{ color: "#fff" }} />
        </Box>
        <Box
          sx={{
            background: "#fff",
            height: "400px",
            overflow: "scroll",
            border: "0.01px solid rgba(255,28,247,0.4)",
            borderRadius: "10px",
            color: "#592E6D",
            mb: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "16px",
            }}
          >
            <Typography>Investments ({investments.length})</Typography>
            <Typography>
              &#8358;
              {parseFloat(totalInvestments)
                .toFixed(1)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
          </Box>
          {isLoading ? (
            <Box
              h={"100%"}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Oval
                height={80}
                width={80}
                color="#290349"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#592e6d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </Box>
          ) : investments.length > 0 ? (
            investments
              .slice(0)
              .reverse()
              .map((investment) => {
                return (
                  <Box
                    sx={{
                      background: "#fff",
                      border: "0.01px solid rgba(255,28,247,0.4)",
                      borderLeft: "0",
                      borderRight: "0",
                      color: "#592E6D",
                      padding: "16px 16px 0",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/investments/${investment.ref}`)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                        NAIRA INVESTMENT POOL
                      </Typography>
                      <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                        &#8358;
                        {investment.amount_invested
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        my: "4px",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>
                        ID - {investment.ref}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#50a773",
                        }}
                      >
                        +
                        {parseFloat(
                          investment.estimated_earnings -
                            investment.amount_invested
                        )
                          .toFixed(1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        / +2.99%
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>
                        {investment.date_invested.slice(0, 10)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>
                        <MoreHoriz />
                      </Typography>
                    </Box>
                  </Box>
                );
              })
          ) : (
            <Box marginLeft={"15px"}>No active investments</Box>
          )}
          {}
        </Box>
      </Box>
    </Box>
  );
};

export default PortfolioPage;
