import { useState, useEffect } from "react";
import Countdown from "react-countdown";

const Timer = ({ time, location }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (time) {
      setIsReady(true);
    }
  }, [time]);

  const renderer = ({ days, hours, minutes, seconds }) => {
    // Render a countdown
    return (
      <>
        <div className=" p-[10px] flex gap-x-[10px]">
          {/* Days */}

          <div className="flex">
            <div
              className={`flex flex-col ${
                location === "landing" ? " gap-y-[12px]" : ""
              }`}
            >
              <div
                className={` p-[0_13px] md:p-[0_27px] ${
                  location === "landing"
                    ? " bg-[#E3C4FD] md:w-[6.125rem] w-[4.125rem] text-[2.25rem] text-[#000]"
                    : location === "gamfi"
                    ? " bg-[#9563AF] text-[#fff]"
                    : location === "jackpot"
                    ? " bg-[#962C81] text-[#fff]"
                    : ""
                } rounded-[4px] border-[1px] border-[#E3C4FD] flex items-center justify-center font-[400] font-RM`}
              >
                {days}
              </div>
              <small
                className={`text-[0.75rem] ${
                  location === "landing"
                    ? " text-[#fff]"
                    : location === "gamfi"
                    ? "text-[#592E6D]"
                    : location === "jackpot"
                    ? "text-[#592E6D]"
                    : ""
                } font-[400] text-center font-SC`}
              >
                Days
              </small>
            </div>
          </div>

          {/* Hours */}

          <div className="flex">
            <div
              className={`flex flex-col ${
                location === "landing" ? " gap-y-[12px]" : ""
              }`}
            >
              <div
                className={` p-[0_13px] md:p-[0_27px] ${
                  location === "landing"
                    ? " bg-[#E3C4FD] md:w-[6.125rem] w-[4.125rem] text-[2.25rem] text-[#000]"
                    : location === "gamfi"
                    ? " bg-[#9563AF] text-[#fff]"
                    : location === "jackpot"
                    ? " bg-[#962C81] text-[#fff]"
                    : ""
                } rounded-[4px] border-[1px] border-[#E3C4FD] flex items-center justify-center font-[400] font-RM`}
              >
                {hours}
              </div>
              <small
                className={`text-[0.75rem] ${
                  location === "landing"
                    ? " text-[#fff]"
                    : location === "gamfi"
                    ? "text-[#592E6D]"
                    : location === "jackpot"
                    ? "text-[#592E6D]"
                    : ""
                } font-[400] text-center font-SC`}
              >
                Hours
              </small>
            </div>{" "}
          </div>

          {/* Minutes */}
          <div className="flex">
            <div
              className={`flex flex-col ${
                location === "landing" ? " gap-y-[12px]" : ""
              }`}
            >
              <div
                className={` p-[0_13px] md:p-[0_27px] ${
                  location === "landing"
                    ? " bg-[#E3C4FD] md:w-[6.125rem] w-[4.125rem] text-[2.25rem] text-[#000]"
                    : location === "gamfi"
                    ? " bg-[#9563AF] text-[#fff]"
                    : location === "jackpot"
                    ? " bg-[#962C81] text-[#fff]"
                    : ""
                } rounded-[4px] border-[1px] border-[#E3C4FD] flex items-center justify-center font-[400] font-RM`}
              >
                {minutes}
              </div>
              <small
                className={`text-[0.75rem] ${
                  location === "landing"
                    ? " text-[#fff]"
                    : location === "gamfi"
                    ? "text-[#592E6D]"
                    : location === "jackpot"
                    ? "text-[#592E6D]"
                    : ""
                } font-[400] text-center font-SC`}
              >
                Minutes
              </small>
            </div>{" "}
          </div>

          {/* Seconds */}
          <div className="flex">
            <div
              className={`flex flex-col ${
                location === "landing" ? " gap-y-[12px]" : ""
              }`}
            >
              <div
                className={` p-[0_13px] md:p-[0_27px] ${
                  location === "landing"
                    ? " bg-[#E3C4FD] md:w-[6.125rem] w-[4.125rem] text-[2.25rem] text-[#000]"
                    : location === "gamfi"
                    ? " bg-[#9563AF] text-[#fff]"
                    : location === "jackpot"
                    ? " bg-[#962C81] text-[#fff]"
                    : ""
                } rounded-[4px] border-[1px] border-[#E3C4FD] flex items-center justify-center font-[400] font-RM`}
              >
                {seconds}
              </div>
              <small
                className={`text-[0.75rem] ${
                  location === "landing"
                    ? " text-[#fff]"
                    : location === "gamfi"
                    ? "text-[#592E6D]"
                    : location === "jackpot"
                    ? "text-[#592E6D]"
                    : ""
                } font-[400] text-center font-SC`}
              >
                Seconds
              </small>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isReady ? (
        <Countdown date={time} renderer={renderer} />
      ) : (
        "Calculating..."
      )}
    </>
  );
};

export const DayLeft = ({ time }) => {
  const renderer = ({ days }) => {
    return (
      <div className=" w-[3.75rem] h-[30px] bg-[#592E6D] rounded-[4px] flex items-center justify-center text-[#fff] text-[2.25] font-AC font-[700]">
        {days}
      </div>
    );
  };

  return <Countdown date={time} renderer={renderer} />;
};

export default Timer;
