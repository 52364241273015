import NavLayout from "../NavLayout";
import Referrals from "./Referrals";
import { useReferralInfo } from "../../helpers/Hooks/Api";

const ReferralsIndex = () => {
  useReferralInfo();
  return <NavLayout title="Referrals" content={<Referrals />} />;
};

export default ReferralsIndex;
