import { useEffect } from "react";

function ClickOut(showComponent, component, componentRef) {
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showComponent &&
        componentRef?.current &&
        !componentRef?.current?.contains(e.target)
      ) {
        showComponent(!component);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [component, componentRef, showComponent]);
}

export function ClickOutCP(
  showComponent,
  component,
  componentRef,
  componentSubRef
) {
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showComponent &&
        componentRef?.current &&
        !componentRef?.current?.contains(e.target) &&
        !componentSubRef?.current?.contains(e.target)
      ) {
        showComponent(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [component, componentRef, showComponent]);
}

export default ClickOut;
