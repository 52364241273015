import { useContext, useEffect, useState } from "react";
import { Context } from "../../../helpers/Context";
import Timer from "../../Timer";
import PoolForm from "./PoolForm";
import {
  useCycleData,
  useGamefiCurrentPrize,
} from "../../../helpers/Hooks/Api";
import Moment from "react-moment";

const JoinPool = () => {
  useGamefiCurrentPrize();
  useCycleData();
  const [openForm, setOpenForm] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { state } = useContext(Context);
  const { poolInfo, cycle, gamefiCurrentPrize } = state;

  const shouldButtonActivate = () => {
    if (poolInfo?.has_pool_started !== false) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  useEffect(() => {
    shouldButtonActivate();
  }, []);

  const closeForm = () => {
    setOpenForm(false);
  };

  return (
    <>
      <div className="w-full flex flex-col items-center gap-y-[10px] mt-[24px]">
        <div className=" text-[0.875rem] text-[#290349] font-AC font-[700]">
          Naira Gamified Savings Pool
        </div>

        <div className=" text-[0.875rem] text-[#290349] font-AC font-[700] mt-[10px]">
          ID: {cycle}
        </div>

        <div className="flex items-baseline">
          <span className=" text-[0.875rem] text-[#290349] font-AC font-[700]">
            One entry <span className="mx-[5px]">=</span>
          </span>
          <span className=" text-[0.875rem] text-[#290349] font-AC font-[700] mr-[2px]">
            N
          </span>
          <span className=" text-[2.25rem] text-[#290349] font-AC font-[700]">
            2,000
          </span>
          <span className=" text-[0.875rem] text-[#290349] font-AC font-[700] ml-[2px]">
            .00
          </span>
        </div>

        <div className=" bg-[rgba(227,196,253,0.50)] w-full shadow-[0px_8px_24px_0px_rgba(0,0,0,0.15)] rounded-[10px] p-[18px] ">
          {poolInfo?.has_pool_started === true ? (
            <div className=" flex flex-col items-center">
              <span className=" text-[rgba(0,0,0,0.30)] text-[0.8rem] font-SF font-[800] space-[-0.078px]">
                CURRENT POOL PRIZE
              </span>
              <span className=" text-[2.25rem] text-[#290349] font-AC font-[700]">
                N
                {parseFloat(gamefiCurrentPrize?.amount_winnable)
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00"}
              </span>
            </div>
          ) : (
            <>
              <div className="flex items-center justify-center">
                <span className=" text-[rgba(0,0,0,0.30)] text-[0.8rem] font-SF font-[800] space-[-0.078px]">
                  <Moment
                    filter={(d) => {
                      return d.toUpperCase();
                    }}
                    format="MMMM"
                  >
                    {poolInfo?.pool_start_date}
                  </Moment>{" "}
                  POOL OPENS:{" "}
                  <span className="text-[0.75rem] text-[rgba(0,0,0,0.80)] font-SF font-[500]">
                    <Moment format="MMMM DD, YYYY">
                      {poolInfo?.pool_start_date}
                    </Moment>
                  </span>
                </span>
              </div>

              <div className="flex items-center mt-[22px] justify-center">
                <span className=" text-[rgba(0,0,0,0.30)] text-[0.8rem] font-SF font-[800] space-[-0.078px]">
                  <Moment
                    filter={(d) => {
                      return d.toUpperCase();
                    }}
                    format="MMMM"
                  >
                    {poolInfo?.pool_start_date}
                  </Moment>{" "}
                  POOL CLOSES:{" "}
                  <span className="text-[0.75rem] text-[rgba(0,0,0,0.80)] font-SF font-[500]">
                    <Moment format="MMMM DD, YYYY">
                      {poolInfo?.pool_end_date}
                    </Moment>
                  </span>
                </span>
              </div>
            </>
          )}

          <div className="mt-[13px] text-[0.75rem] text-[rgba(0,0,0,0.80)] text-center font-SF font-[500]">
            Countdown to pool{" "}
            {poolInfo?.has_pool_started === false ? "opening" : "closing"}
          </div>

          <div className=" w-full flex justify-center">
            <Timer
              time={
                poolInfo?.has_pool_started === false
                  ? poolInfo?.pool_start_date
                  : poolInfo?.pool_end_date
              }
              location="gamfi"
            />
          </div>

          {poolInfo?.has_pool_started === true && (
            <div className=" text-center text-[1rem] text-[#592E6D]">
              Pool closes:{" "}
              <Moment format="DD MMMM, YYYY">{poolInfo?.pool_end_date}</Moment>
            </div>
          )}
          <button
            className="mt-[8.61px] w-full h-[40px] disabled:bg-[rgba(89,46,109,0.30)] bg-[#592E6D] rounded-[5px] disabled:text-[rgba(255,255,255,0.50)] text-[#fff] text-[0.75rem] font-SF font-[600]"
            onClick={() => setOpenForm(true)}
            disabled={isButtonDisabled}
          >
            Join pool now
          </button>
        </div>
      </div>

      {openForm && (
        <PoolForm open={openForm} onClose={closeForm} cycle={cycle} />
      )}
    </>
  );
};

export default JoinPool;
