import { Box, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";

const Terms = () => {
  const navigate = useNavigate();

  return (
    <NavLayout
      title="Terms and Conditions"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#F5F5F5",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              background: "#FFFFFF",
              px: "20px",
              pb: "100px",
              pt: "20px",
            }}
          >
            <ArrowBackIos
              onClick={() => navigate(-1)}
              sx={{
                fontSize: "16px",
                color: "#592E6D",
                cursor: "pointer",
                pl: "20px",
                mt: "20px",
              }}
            />
            <Typography
              sx={{
                color: "#592E6D",
                fontSize: "24px",
                fontWeight: "600",
                marginTop: "30px",
                marginLeft: "20px",
              }}
            >
              Terms and Conditions
            </Typography>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                One Cap Terms and Conditions
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
                nulla condimentum ipsum quis habitant bibendum. Sit vestibulum
                nunc quam aenean in. Diam mauris enim at nec euismod sagittis et
                ornare. Porta adipiscing lorem diam ligula ut consectetur leo
                nulla. Volutpat cursus scelerisque pharetra dictum faucibus
                parturient vitae suspendisse.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                About Us
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
                nulla condimentum ipsum quis habitant bibendum. Sit vestibulum
                nunc quam aenean in.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Objective of relationship
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
                nulla condimentum ipsum quis habitant bibendum. Sit vestibulum
                nunc quam aenean in. Diam mauris enim at nec euismod sagittis et
                ornare.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                When are these terms applicable?
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
                nulla condimentum ipsum quis habitant bibendum. Sit vestibulum
                nunc quam aenean in. Diam mauris enim at nec euismod sagittis et
                ornare.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Opening an account
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
                nulla condimentum ipsum quis habitant bibendum. Sit vestibulum
                nunc quam aenean in. Diam mauris enim at nec euismod sagittis et
                ornare.
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Verifying your identity
              </Typography>
              <Typography
                sx={{
                  color: "#592E6D",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginTop: "5px",
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac{" "}
              </Typography>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default Terms;
