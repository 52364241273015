import {
  Box,
  Card,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  Modal,
} from "@mui/material";
import { ArrowBackIos, CancelOutlined } from "@mui/icons-material/";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import axios from "../../helpers/axios";
import { Audio } from "react-loader-spinner";
import { NumericFormat } from "react-number-format";

import React from "react";
const formstyles = {
  "& .MuiInputLabel-root": {
    color: "#592E6D",
    fontSize: "14px",
    paddingLeft: "5px",
  },
  "& .MuiInputBase-root": {
    color: "#592E6D",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#592E6D",
    paddingTop: "5px",
  },
  "&.MuiFormLabel-asterisk": {
    color: "red !important",
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "350px",
  bgcolor: "#fff",
  boxShadow: 24,
  padding: "10px",
  textAlign: "center",
  borderRadius: "5px",
};

const InvestmentsPage = () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const navigate = useNavigate();
  const [totalInvestments, setTotalInvestments] = useState(0);
  const [profitPercent, setProfitPercent] = useState(0);
  const [earning, setEarning] = useState(0);
  const [availablePoint, setAvailablePoints] = useState("");

  const fetchData = async () => {
    await axios
      .get("/wallet/detail", config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
        navigate("/signin");
      });
  };
  const rewardDetails = async () => {
    await axios
      .get("/investor/points/detail", config)
      .then(function (response) {
        setAvailablePoints(response?.data?.available_point);
      })
      .catch(function (error) {
        console.log(error);
        navigate("/signin");
      });
  };
  const fetchInvestments = async () => {
    let investment = 0;
    let totalEstimated = 0;
    await axios
      .get("/current-investments", config)
      .then(function (response) {
        const investmentData = response?.data;
        for (let index = 0; index < investmentData.length; index++) {
          investment += investmentData[index].amount_invested;
          totalEstimated += investmentData[index].estimated_earnings;
        }
        setTotalInvestments(investment);
        setEarning(totalEstimated - investment);
        if (earning === 0) {
          setProfitPercent(0);
        } else {
          setProfitPercent(
            (((totalEstimated - investment) / investment) * 100).toPrecision(4)
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchInvestments();
    rewardDetails();
  }, []);
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <Box maxWidth={"500px"} sx={{ margin: "0 auto" }}>
      <Box
        sx={{
          paddingBottom: "20px",
        }}
      >
        <Box
          sx={{
            padding: "24px 20px",
            borderBottom: "0.1px solid #FFFFFF80",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#fff",
              marginBottom: "10px",
            }}
          >
            <ArrowBackIos
              onClick={() => navigate(-1)}
              sx={{ fontSize: "16px", cursor: "pointer" }}
            />
            <Box width={"100%"} textAlign="center">
              <Typography variant="body1">Investments</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ padding: "24px 20px" }}>
          <Card
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              border: "0.01px solid rgba(255,28,247,0.4)",
              padding: "20px",
              color: "#fff",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="caption"
                sx={{ fontWeight: "200", fontSize: "14px", lineHeight: "20px" }}
              >
                Portfolio Value
              </Typography>
            </Box>

            <Box
              mt={""}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ marginTop: "5px" }}>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: "500",
                    fontSize: "28px",
                    lineHeight: "20px",
                  }}
                >
                  &#8358;
                  {parseFloat(totalInvestments)
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#759EF0",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "400",
                    }}
                  >
                    Details
                  </Typography>
                  <KeyboardArrowRightIcon
                    sx={{ cursor: "pointer", zIndex: 4 }}
                    onClick={() => {
                      navigate("/investments/portfolio");
                    }}
                  />
                </Box>
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "400",
                    color: "#75F0A6",
                    marginBottom: "16px",
                  }}
                >
                  + &#8358;
                  {parseFloat(earning)
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  {profitPercent}%
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: "300" }}>
                  As of {new Date().toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
            <Box mt={"40px"}>
              <Box
                mt={"8px"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
                  <Typography fontWeight={"200"}>Your Reward</Typography>
                  <Box display={"flex"} alignItems={"flex-end"} gap={"5px"}>
                    <Typography fontSize={"28px"} lineHeight={"1"}>
                      {availablePoint}
                    </Typography>
                    <Typography>OC Tokens</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>

      <Box sx={{ padding: "24px 20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ color: "#fff", fontWeight: "400", fontSize: "14px" }}
          >
            Investment Offers
          </Typography>
          <FilterListIcon sx={{ color: "#fff" }} />
        </Box>
        <Card
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            border: "0.01px solid rgba(255,28,247,0.4)",
            padding: "16px",
            color: "#fff",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <img
            src="/assets/images/investment.jpeg"
            alt=""
            style={{ height: "60px", weight: "60px", borderRadius: "50%" }}
          />

          <Box sx={{ flex: "1", marginLeft: "20px" }}>
            <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
              MONI INVESTMENT POOL
            </Typography>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "300", marginTop: "6px" }}
            >
              Earn up to 20% PA ROI
            </Typography>
          </Box>
          <Box
            sx={{
              flex: "1",
              display: "flex",
              alignItems: "center",
              paddingLeft: "auto",
            }}
          >
            <Box sx={{ marginLeft: "auto" }} width={"100px"}></Box>
            <KeyboardArrowRightIcon
              sx={{
                cursor: "pointer",
              }}
              fontSize="large"
            />
          </Box>
        </Card>
      </Box>
      {openModal && <ModalScreen action={handleClose} />}
    </Box>
  );
};
const ModalScreen = (props) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const navigate = useNavigate();
  const today = new Date().getDate();
  const [open, setOpen] = useState(true);
  const [modalPage, setModalPage] = useState(1);
  const [duration, setDuration] = useState("12 months");
  const [date, setDate] = useState({});
  const [investment, setInvestment] = useState(0);
  const [percent, setPercent] = useState(18);
  const [_yeild, set_Yield] = useState(0);
  const [amount, setAmount] = useState("");
  const [serverError, setServerError] = useState("");
  const [expiryDate, setExpiryDate] = useState(
    new Date(
      new Date().setDate(new Date().getDate() + 365)
    ).toLocaleDateString()
  );
  const [error, setError] = useState([]);
  const [floatAmount, setFloatAmount] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});

  const handleNumeric = (e) => {
    setServerError("");
    setAmount(e.target.value);
    let floatAmount = parseFloat(amount.replaceAll(",", ""));
    set_Yield((percent / 100) * floatAmount);
    let inputErrors = [];
    if (floatAmount < 10000) {
      inputErrors.push("Minimum investment is N10,000 ");
      setError(inputErrors);
    } else {
      setError([]);
      setFloatAmount(floatAmount.toString());
    }
  };
  useEffect(() => {
    const trackAmount = () => {
      let floatAmount = parseFloat(amount.replaceAll(",", ""));
      set_Yield((percent / 100) * floatAmount);
      let inputErrors = [];
      if (floatAmount < 10000) {
        inputErrors.push("Minimum investment is N10,000 ");
        setError(inputErrors);
      } else {
        setError([]);
        setFloatAmount(floatAmount.toString());
      }
    };
    trackAmount();
  }, [amount, duration]);
  useEffect(() => {
    const fetchDate = async () => {
      try {
        const { data } = await axios.get(
          `/investment-date/${duration}`,
          config
        );
        setDate(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDate();
  }, [duration]);
  const handleChange = (event) => {
    setDuration(event.target.value);
    if (event.target.value === "1 month") {
      setExpiryDate(
        new Date(
          new Date().setDate(new Date().getDate() + 30)
        ).toLocaleDateString()
      );
      setPercent(0.625);
      set_Yield((0.625 / 100) * parseInt(investment));
    } else if (event.target.value === "3 months") {
      setExpiryDate(
        new Date(
          new Date().setDate(new Date().getDate() + 90)
        ).toLocaleDateString()
      );
      setPercent(3.25);
      set_Yield((3.25 / 100) * parseInt(investment));
    } else if (event.target.value === "6 months") {
      setExpiryDate(
        new Date(
          new Date().setDate(new Date().getDate() + 183)
        ).toLocaleDateString()
      );
      setPercent(7.5);
      set_Yield((7.5 / 100) * parseInt(investment));
    } else if (event.target.value === "9 months") {
      setExpiryDate(
        new Date(
          new Date().setDate(new Date().getDate() + 273)
        ).toLocaleDateString()
      );
      setPercent(12.4);
      set_Yield((12.4 / 100) * parseInt(investment));
    } else {
      setExpiryDate(
        new Date(
          new Date().setDate(new Date().getDate() + 365)
        ).toLocaleDateString()
      );
      setPercent(18);
      set_Yield((18 / 100) * parseInt(investment));
    }
  };

  useEffect(() => {
    const fetchDate = async () => {
      try {
        const { data } = await axios.get(
          `/investment-date/${duration}`,
          config
        );
        setDate(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDate();
  }, [duration]);

  const onSubmit = async (data) => {
    if (error.length < 1) {
      setServerError(
        <Audio
          height="20"
          width="20"
          radius="9"
          color="#592E6D"
          ariaLabel="three-dots-loading"
        />
      );
      data.amount_invested = floatAmount;
      await axios
        .post("/make-investment", data, config)
        .then(function (response) {
          setModalPage(2);
        })
        .catch(function (error) {
          if (error?.response?.status === 406) {
            setServerError("insufficient funds");
          } else if (error?.response?.status === 400) {
            setServerError(error?.response?.data?.amount_invested);
          } else if (error?.response?.status === 401) {
            setServerError("Given token not valid for any token type");
          } else if (error?.response?.status === 403) {
            setServerError(
              "oops! you can only join the investment pool at specific date of the month"
            );
          }
        });
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={props.action}
        disableEscapeKeyDown
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {modalPage === 1 && (
            <>
              <CancelOutlined
                sx={{ position: "fixed", right: "10px", top: "10px" }}
                onClick={props.action}
              />
              <Typography
                variant="h6"
                mt="20px"
                mb="20px"
                sx={{
                  width: "300px",
                  marginRight: "auto",
                  marginLeft: "auto",
                  fontWeight: "600",
                  color: "#592E6D",
                }}
              >
                Moni Investment Pool
              </Typography>

              <img
                src="/assets/images/nip.png"
                width={"48px"}
                height="42px"
                alt="NIP"
              ></img>
              <Box sx={{ color: "#592E6D" }}>
                <Typography
                  textTransform={"capitalize"}
                  variant="subtitle2"
                  fontWeight={380}
                  mt={"5px"}
                >
                  {duration ? duration : "Annual"} Yield
                  <span
                    style={{
                      fontWeight: "700",
                      marginLeft: "5px",
                      color: "#2DA771",
                    }}
                  >
                    {percent}%
                  </span>
                </Typography>
                {today < 21 && today > 28 ? (
                  <Box
                    height={"200px"}
                    gap="40px"
                    display={"flex"}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Typography variant="h4">Pool Closed</Typography>
                    <Typography>
                      New pool will be opened on 28/{new Date().getMonth() + 1}/
                      {new Date().getFullYear()}
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="subtitle2" fontWeight={380} mt={"5px"}>
                      as of {new Date().toLocaleDateString()}.
                      <span
                        style={{
                          marginLeft: "5px",
                        }}
                      >
                        12.24PM
                      </span>
                    </Typography>
                    <Typography variant="subtitle2" fontWeight={380} mt={"5px"}>
                      Start date:
                      <span
                        style={{
                          marginLeft: "5px",
                          fontWeight: "700",
                        }}
                      >
                        {date?.start_date && date?.start_date}
                      </span>
                    </Typography>
                    <Typography variant="subtitle2" fontWeight={380} mt={"5px"}>
                      End date:
                      <span
                        style={{
                          marginLeft: "5px",
                          fontWeight: "700",
                        }}
                      >
                        {date?.end_date && date?.end_date}
                      </span>
                    </Typography>
                    <Box
                      my={"20px"}
                      display="flex"
                      alignItems={"center"}
                      gap="5px"
                      justifyContent="center"
                    >
                      <WarningAmberRoundedIcon />

                      <Typography variant="subtitle2" fontWeight={380}>
                        Minimum Investment
                        <span
                          style={{
                            marginLeft: "5px",
                            fontWeight: "700",
                          }}
                        >
                          N10,000.00
                        </span>
                      </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          color: "#fff",
                        }}
                      >
                        <Box>
                          <Typography
                            textAlign={"left"}
                            variant="body2"
                            color={"rgb(89, 46, 109)"}
                            my={"5px"}
                          >
                            Amount
                          </Typography>
                          <Box
                            width={"99%"}
                            sx={{
                              borderRadius: "5px",
                              border: "0.5px solid #592E6D",
                              padding: "3px 0 3px 3px",
                              textAlign: "left",
                            }}
                          >
                            <NumericFormat
                              value={investment}
                              placeholder="Amount"
                              min={10000}
                              required
                              allowLeadingZeros
                              thousandSeparator=","
                              style={{
                                width: "full",
                                outline: "2px solid transparent",
                                border: "0",
                                padding: "6px 6px",
                                borderRadius: "5px",
                                color: "#592E6D",
                                fontSize: "16px",
                              }}
                              onChange={handleNumeric}
                            />
                          </Box>

                          <Box textAlign={"right"}>
                            {error.length > 0 && (
                              <Typography
                                my={"2"}
                                color={"red"}
                                variant={"caption"}
                              >
                                {error[0]}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        <Typography
                          textAlign={"left"}
                          variant="body2"
                          color={"rgb(89, 46, 109)"}
                          mt={"5px"}
                        >
                          Tenor
                        </Typography>
                        <Box
                          sx={{
                            borderRadius: "5px",
                            border: "0.5px solid #592E6D",
                            padding: "3px 0 3px 3px",
                          }}
                        >
                          <FormControl fullWidth>
                            <Select
                              disableUnderline
                              fullWidth
                              variant="standard"
                              name="duration"
                              {...register("duration", {
                                required: "Please Select Duration",
                                onChange: handleChange,
                              })}
                              id="demo-simple-select"
                              defaultValue={"3 months"}
                              value={duration}
                              placeholder="Choose Investment Duration"
                              label="Bank"
                              error={errors.duration?.message}
                              sx={{
                                color: "#592E6D",
                                textAlign: "left",
                                marginTop: 0,
                                border: "none",
                                "& .MuiFormLabel-root": {
                                  color: "#592E6D",
                                },
                                "& .MuiInputBase-root.MuiInput-root.MuiSelect-root":
                                  {
                                    marginTop: "0px !important",
                                    paddingTop: "5px",
                                  },
                                "& .css-21r7d85-MuiInputBase-root-MuiInput-root-MuiSelect-root":
                                  { marginTop: "0px !important" },
                                "& .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input":
                                  {
                                    paddingLeft: "6px",
                                  },
                              }}
                            >
                              <MenuItem value={"1 month"}>1 Month</MenuItem>
                              <MenuItem value={"3 months"}>3 Months</MenuItem>
                              <MenuItem value={"6 months"}>6 Months</MenuItem>
                              <MenuItem value={"9 months"}>9 Months</MenuItem>
                              <MenuItem value={"12 months"}>12 Months</MenuItem>
                            </Select>
                          </FormControl>
                          <Typography
                            variant="body2"
                            sx={{ textAlign: "right", paddingRight: "5px" }}
                            color="red"
                          >
                            {errors.account?.message}
                          </Typography>
                        </Box>
                      </Box>

                      <Typography color="#592E6D" mt="10px">
                        You earn{" "}
                        {isNaN(_yeild)
                          ? 0
                          : _yeild
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        {} after {duration}
                      </Typography>

                      <Typography
                        variant="caption"
                        textTransform={"capitalize"}
                      >
                        {serverError}
                      </Typography>

                      <Box
                        mt={"40px"}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Button
                          type="submit"
                          sx={{
                            background: "#592E6D",
                            ":hover": {
                              bgcolor: "#a852dd",
                            },
                            color: "#fff",
                            height: "45px",
                            textTransform: "none",
                          }}
                        >
                          <Typography variant="body2">Continue</Typography>
                        </Button>
                      </Box>
                    </form>
                  </Box>
                )}
              </Box>
            </>
          )}

          {/* secondmodal */}
          {modalPage === 2 && (
            <>
              <img src="/assets/images/success.gif" width={"100px"} alt="" />
              <Typography
                variant="body2"
                mt="10px"
                sx={{
                  width: "300px",
                  marginRight: "auto",
                  marginLeft: "auto",
                  fontWeight: "600",
                  color: "#592E6D",
                }}
              >
                Success!
              </Typography>
              <Typography
                mt={"12px"}
                sx={{ fontSize: "13px", color: "#592E6D" }}
              >
                You successsfully invested NGN {investment} in Oncecap’s Moni
                Investment Pool. We sent an Investment advice document to your
                email.
              </Typography>

              <Box
                mt={"40px"}
                sx={{
                  color: "#592E6D",
                  padding: "0 10px",
                }}
              >
                <Button
                  onClick={() => {
                    fetch();
                    navigate("/investments/portfolio");
                  }}
                  sx={{
                    background: "#592E6D",
                    ":hover": {
                      bgcolor: "#a852dd",
                    },
                    padding: "10px 30px",
                    color: "#fff",
                    textTransform: "none",
                    fontSize: "12px",
                  }}
                >
                  Close
                </Button>
              </Box>
            </>
          )}
        </Box>

        {/* second modal */}
      </Modal>
    </div>
  );
};

export default InvestmentsPage;
