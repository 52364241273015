import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Oval } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "../helpers/axios";
function VerifyResetToken() {
  const location = useLocation();
  const navigate = useNavigate();
  const uidb64 = location.pathname.split("/")[2];
  const token = location.pathname.split("/")[3];
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  useEffect(() => {
    const verifyParams = async () => {
      await axios
        .get(`/reset-token/check/${uidb64}/${token}`)
        .then(function (response) {
          console.log(response);
          localStorage.setItem("uidb64", uidb64);
          localStorage.setItem("resetToken", token);
          setIsLoading(false);
          setMessage("Link verified. Redirecting...");
          setTimeout(() => navigate("/change-password"), 3000);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response?.status === 400) {
            setIsLoading(false);
            setMessage("The link is expired or invalid");
            setTimeout(() => navigate("/reset-password"), 3000);
          }
          if (error.response?.status === 500) {
            setIsLoading(false);
            setMessage("An error occured");
            setTimeout(() => navigate("/signin"), 3000);
          }
        });
    };
    verifyParams();
  });

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Onecap | Sign in</title>
        </Helmet>
      </HelmetProvider>
      <Box
        sx={{
          background: "linear-gradient(180deg, #290349 0%, #592E6D 100%)",

          margin: "0px",
          boxSizing: "border-box",
          height: "100vh",
          paddingTop: "60px",
        }}
      >
        <Box
          sx={{
            maxWidth: "600px",
            height: "100%",
            margin: "0 auto",
            background: "transparent",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Container sx={{ maxWidth: "600px" }}>
            <Box
              sx={{
                width: "100%",
                background: "#fff",
                height: "200px",
                borderRadius: "10px",
              }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {" "}
              {isLoading ? (
                <>
                  {" "}
                  <Oval
                    height={80}
                    width={80}
                    color="#290349"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#592e6d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                  <Typography>Verifying reset link...</Typography>
                </>
              ) : (
                <Typography>{message}</Typography>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default VerifyResetToken;
