import { useState, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Container,
  Checkbox,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Modal,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AiOutlineClose, AiFillWarning } from "react-icons/ai";

import { formstyles, validationSchema } from "./formstyles";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Context } from "../helpers/Context";
import { useUserAuth } from "../helpers/Hooks/Api";
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "350px",
  bgcolor: "#fff",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  padding: "30px 10px",
  textAlign: "center",
  borderRadius: "5px",
};

function Signup() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { state } = useContext(Context);
  const { referral_code } = state;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [userData, setUserData] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { authWithGoogle, signup, errorMsg, setErrorMsg } = useUserAuth();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Onecap | Sign up</title>
        </Helmet>
      </HelmetProvider>
      <Box
        sx={{
          background: "linear-gradient(180deg, #290349 0%, #592E6D 100%)",

          margin: "0px",
          boxSizing: "border-box",
          minHeight: "100vh",
          padding: "60px 0",
        }}
      >
        <Link to="/">
          <AiOutlineClose
            color="#fff"
            fontSize={"24px"}
            style={{ position: "fixed", right: 20, top: 20, cursor: "pointer" }}
          />
        </Link>
        <Box
          sx={{
            maxWidth: "600px",
            height: "100%",
            margin: "0 auto",
            background: "transparent",
            display: "flex",
          }}
        >
          <Container sx={{ maxWidth: "600px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",

                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: "600",
                  fontFamily: "SF Pro Text",
                  fontSize: "1.5rem",
                }}
              >
                Sign up
              </Typography>
              <Box sx={{ display: "flex", gap: "6px" }}>
                <Typography
                  sx={{
                    fontFamily: "SF Pro Text",
                    fontSize: "0.875rem",
                    fontWeight: "300",
                    color: "#fff",
                  }}
                >
                  Existing user?
                </Typography>
                <Link
                  to="/signin"
                  style={{
                    color: "#759EF0",
                    fontFamily: "SF Pro Text",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                  }}
                >
                  Sign in
                </Link>
              </Box>
            </Box>
            <Box
              mt={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
                width: "100%",
              }}
            >
              <button
                style={{
                  width: "100%",
                  height: "56px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                  color: "#16181D",
                  borderRadius: "5px",
                  border: "none",
                  columnGap: "6px",
                  fontFamily: "SF Pro Text",
                  fontSize: "0.875rem",
                  fontWeight: "600",
                  letterSpacing: "-0.408px",
                  cursor: "pointer",
                }}
                onClick={() => authWithGoogle()}
              >
                <img src="/assets/images/google.png" alt="" />
                Sign in with Google
              </button>
            </Box>

            <Box mt={"40px"} mb={"40px"} sx={{ textAlign: "center" }}>
              <Typography color={"#fff"}>or</Typography>
            </Box>
            <form
              onSubmit={handleSubmit((data) => {
                setErrorMsg("");
                setUserData({ ...data, referral_code });
                handleOpen();
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  color: "#fff",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "5px",
                    border: "0.5px solid #fff",
                    padding: "3px 0 3px 3px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="EMAIL"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    sx={formstyles}
                    name="email"
                    {...register("email")}
                    error={errors.email?.message}
                  />

                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", paddingRight: "5px" }}
                    color="red"
                  >
                    {errors.email?.message}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderRadius: "5px",
                    border: "0.5px solid #fff",
                    padding: "3px 0 3px 3px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="PASSWORD"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            sx={{ cursor: "pointer" }}
                            variant="caption"
                            pr={"5px"}
                            color={"#fff"}
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? "HIDE" : "SHOW"}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    sx={formstyles}
                    {...register("password")}
                    error={errors.password?.message}
                  />
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", paddingRight: "5px" }}
                    color="red"
                  >
                    {errors.password?.message}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderRadius: "5px",
                    border: "0.5px solid #fff",
                    padding: "3px 0 3px 3px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="CONFIRM PASSWORD"
                    name="confirmPassword"
                    type={"password"}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={formstyles}
                    {...register("confirmPassword")}
                    error={errors.confirmPassword?.message}
                  />
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", paddingRight: "5px" }}
                    color="red"
                  >
                    {errors.confirmPassword?.message}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderRadius: "5px",
                    border: "0.5px solid #fff",
                    padding: "3px 0 3px 3px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    label="FIRST NAME"
                    name="first_name"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    sx={formstyles}
                    {...register("first_name")}
                    error={errors.first_name?.message}
                  />
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", paddingRight: "5px" }}
                    color="red"
                  >
                    {errors.first_name?.message}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderRadius: "5px",
                    border: "0.5px solid #fff",
                    padding: "3px 0 3px 3px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    name="last_name"
                    label="LASTNAME"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    sx={formstyles}
                    {...register("last_name")}
                    error={errors.last_name?.message}
                  />
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", paddingRight: "5px" }}
                    color="red"
                  >
                    {errors.last_name?.message}
                  </Typography>
                </Box>

                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      sx={{ color: "#fff" }}
                      label="Label"
                      name="t_and_c"
                      {...register("t_and_c")}
                    />
                    <Link to="/terms-and-conditions">
                      <Typography
                        style={{
                          color: "#00F0FF",
                          textAlign: "right",
                          fontSize: "0.75rem",
                          fontFamily: "SF Pro Text",
                          fontWeight: "600",
                        }}
                      >
                        Accept Terms and Conditions
                      </Typography>{" "}
                    </Link>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "right", paddingRight: "5px" }}
                    color="red"
                  >
                    {errors.t_and_c?.message}
                  </Typography>
                </Box>
                <Box>{errorMsg}</Box>
              </Box>
              <Box
                mt={"40px"}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  sx={{
                    background: "#a852d2",
                    ":hover": {
                      bgcolor: "#a852dd",
                    },
                    color: "#fff",
                    height: "56px",
                    textTransform: "none",
                  }}
                  type="submit"
                >
                  <Typography
                    sx={{
                      fontFamily: "SF Pro Text",
                      fontSize: "0.875rem",
                      letterSpacing: "-0.32px",
                    }}
                  >
                    Continue
                  </Typography>
                </Button>
              </Box>
              <Modal open={open} onClose={handleClose}>
                <Box sx={modalStyle}>
                  <Box textAlign={"center"}>
                    <AiFillWarning fontSize={"40px"} color={"#a852d2"} />
                  </Box>

                  <Typography variant="h6">One more step to go!</Typography>
                  <Typography variant="body2">
                    The first name and last name I provided correlates with the
                    name on my bank details.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "60%",
                      margin: "0 auto",
                    }}
                  >
                    <Button
                      onClick={() => signup(userData, handleClose)}
                      sx={{
                        background: "#a852d2",
                        ":hover": {
                          bgcolor: "#a852dd",
                        },
                        color: "#fff",
                        height: "45px",
                        textTransform: "none",
                      }}
                    >
                      Agree
                    </Button>
                    <Button
                      onClick={handleClose}
                      sx={{
                        background: "#ebe6e6",
                        ":hover": {
                          bgcolor: "#ebe6e6",
                        },
                        color: "#a852d2",
                        height: "45px",
                        textTransform: "none",
                      }}
                    >
                      Edit details
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </form>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default Signup;
