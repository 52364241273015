const PriceLinked = () => {
  return (
    <div className="min-h-[811px] w-full flex flex-col justify-center px-[7%] md:px-[13.8%] bg-bg4Mobile md:bg-bg5 bg-[rgba(255,28,247,0.10)] bg-no-repeat bg-cover bg-center">
      <div className=" text-center text-[2.25rem] md:text-[3rem] text-black font-SF font-[500] mb-[47px]">
        Why prize-linked savings?
      </div>
      <div className=" text-center text-[2rem] text-black font-SF font-[200]">
        Our mission is to encourage you to improve your financial wellbeing by
        rewarding{" "}
        <span className=" text-[#520692] font-[800]">
          positive financial habits.
        </span>
      </div>
    </div>
  );
};

export default PriceLinked;
