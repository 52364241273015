import { useNavigate } from "react-router-dom";

const Jackport = ({ jackpotRef }) => {
  const navigate = useNavigate();
  return (
    <div
      ref={jackpotRef}
      id="jackpot"
      className="w-full bg-bg3Mobile md:bg-bg4 bg-[#F6EBFF] pt-[96px] md:pt-[50px] pb-[100px] px-[7%] md:px-[13.8%] flex flex-col lg:flex-row items-center gap-x-[2rem] bg-no-repeat bg-cover bg-center"
    >
      <div className="flex md:block flex-col items-center">
        <p className="text-[1rem] text-black text-center md:text-left font-SF font-[300] mb-[24px]">
          ULTIMATE JACKPOT SAVING POOL
        </p>
        <p className="text-black max-w-[27.25rem] text-[2.25rem] md:text-[2.5rem] text-center md:text-left font-SF font-[400] mb-[24px]">
          Many savers, one ultimate prize winner
        </p>
        <p className="max-w-[39.1875rem] text-black text-[0.8rem] text-center md:text-left font-SF font-[300] leading-[36px] mb-[24px]">
          We heat things up a bit with our ultimate jackpot savings pool where
          one winner takes home a big prize. The prize is the interest of all
          deposits saved by everyone during the month.
        </p>

        <button
          className="w-[9.375rem] h-[3.125rem] rounded-[30px] text-white text-[1.125rem] font-SF font-[700] bg-brandPurp"
          onClick={() => navigate("/signup")}
        >
          Get started
        </button>
      </div>
      <img
        src="/assets/images/oneWinner.svg"
        alt=""
        className="mt-[36px] md:mt-0"
      />
    </div>
  );
};

export default Jackport;
